import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Typography, IconButton, Grid } from "@mui/material";
import { ChevronLeftOutlined, ChevronRightOutlined } from "@mui/icons-material";
import { Colors } from "../../../styles/theme";
import {
  addDays,
  addMonths,
  addWeeks,
  getYear,
  getDate,
  isThisMonth,
  isToday,
  isWeekend,
  startOfMonth,
  startOfWeek,
  isPast,
} from "date-fns";
import { getMonthString } from "../../../utils";
import MiniCalColumn from "./MiniCalColumn";

const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

const dayMap = new Map();

function MiniCalendar({ dayOfAppointment, setDayOfAppointment, onDayClicked, disablePastDates = true }) {
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [date, setDate] = useState(new Date());
  const rows = 6;
  const columns = 7;
  const [dayColumns, setDayColumns] = useState();

  useEffect(() => { 
    
    var monthStart = startOfMonth(date);

    setMonth(date.getMonth());
    setYear(getYear(date));

    const columnData = {};
    // for each column
    for (var day = 0; day < columns; day++) {
      const forDay = [];
      columnData[`${day}`] = [];
      for (var week = 0; week < rows; week++) {
        const weekStart = startOfWeek(addWeeks(monthStart, week));
        const dayOfWeek = addDays(weekStart, day);
        const dt = getDate(dayOfWeek);
        const d = {
          day: dt,
          isWeekend: isWeekend(dayOfWeek),
          isToday: isToday(dayOfWeek),
          isThisMonth: isThisMonth(dayOfWeek),
          month: dayOfWeek.getMonth(),
          isPast: !isToday(dayOfWeek) && isPast(dayOfWeek),
        };

        forDay.push(d);
      }
      columnData[`${day}`] = forDay;
    }
    setDayColumns(columnData);
  }, [date]);

  const handleNext = () => {
    // add month to date
    setDate(addMonths(date, 1));
  };

  const handlePrev = () => {
    // subtract month from date
    setDate(addMonths(date, -1));
  };

  const handleDayClicked = (date) => {
    if (date.isPast && disablePastDates ) return;

    const m = `${+date.month + 1}`.padStart(2, "0");
    const d = `${+date.day}`.padStart(2, "0");

    const dateClicked = {
      day: d,
      year: year,
      month: m,
    };

    setDayOfAppointment(dateClicked);
    onDayClicked(dateClicked);
  };

  return (
    <Box display={"flex"} flexDirection="column">
      {/* header */}
      <Box
        display={"flex"}
        justifyContent="center"
        alignItems={"center"}
        sx={{ background: Colors.dark, p: 1, color: Colors.white }}
      >
        <IconButton
          aria-label="prevous date"
          color="primary"
          onClick={handlePrev}
        >
          <ChevronLeftOutlined />
        </IconButton>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "1.25rem",
            fontWeight: "bold",
            padding: "0px 20px",
          }}
        >
          {getMonthString(month)} {year}
        </Typography>
        <IconButton aria-label="next date" color="primary" onClick={handleNext}>
          <ChevronRightOutlined />
        </IconButton>
      </Box>

      {/* day title */}
      <Grid
        container
        justifyContent="center"
        columnGap={0.125}
      >
        {dayNames.map((day, index) => {
          return (
            <Grid
              sx={{ padding: "10px 0px" }}
              item
              key={index}
              xs={1.6}
            >
              <Typography textAlign={"center"}>{day}</Typography>
            </Grid>
          );
        })}
      </Grid>

      <Grid container flexDirection={"row"}  justifyContent="center" columnGap={0.125}>          
          { dayColumns &&
            [0, 1, 2, 3, 4, 5, 6].map((column, i) => {
                return <MiniCalColumn disablePastDates={disablePastDates} key={i} column={dayColumns[column]} handleDayClicked={handleDayClicked} 
                year={year}
                dayOfAppointment={dayOfAppointment}/>
            })

          }
      </Grid>

      {/* days of month */}
      {/* <Grid
        container
        justifyContent={"center"}
        columnGap={0.125}
        rowGap={0.125}
      >
        {daysInWeek.map((d, i) => {
          return (
            <DayTile
              key={i}
              isSelected={
                dayOfAppointment &&
                !d.isPast &&
                (
                  +dayOfAppointment.day === +d.day &&
                  +dayOfAppointment.month - 1 === +d.month &&
                  dayOfAppointment.year === year
                ).toString()
              }
              onClick={() => handleDayClicked(d)}
              isBeforeToday={d.isPast}
              item
              xs={1.9}
            >
              <DayTileContentContainer isToday={d.isToday}>
                <Typography
                  sx={{
                    fontSize: "1.125rem",
                    background: d.isToday ? Colors.primary : "",
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {d.day}
                </Typography>
              </DayTileContentContainer>
            </DayTile>
          );
        })}
      </Grid> */}

    </Box>
  );
}

MiniCalendar.prototypes = {
  calendarDate: PropTypes.number,
};

export default MiniCalendar;
