import { useEffect, useReducer, useState } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
    Typography,
    IconButton,
    Divider,
    Grid,
} from "@mui/material";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { SelectLocation } from '../../../common';
import CloseIcon from '@mui/icons-material/Close';
import { Colors } from '../../../../styles/theme';
import MiniCalendar from '../../../ui/mini-calendar';
import DoctorOpenSlotList from '../../../ui/doctor-open-slot-list';
import ContactInfo from '../../../ui/contact-info';
import ConfirmAppointmentDetails from './confirm-appointment-details';
import { ScheduleService } from '../../../../service/scheduleService';
import useEffectOnUpdates from '../../../../hooks/useEffectOnUpdates';
import { LocationService } from '../../../../service/locationService';
import { buildAddress, dateFromString, dateSetTime, formatTime, getDateString, getDateStringNonISO, getRandomNumber, getTimeString, timeDiff, timeFromString, toLocalTimeZone, toTitleCase } from '../../../../utils';
import { AppointmentService } from '../../../../service/appointmentService';
import { addMinutes, format } from 'date-fns';
import { useUIContext } from '../../../../state/ui';
import GroupByPractitioners from '../../../calendar/groupByResource/GroupByPractitioners';
import useDialogModal from '../../../../hooks/useDialogModal';
import ChooseTimeDialog from '../../../ui/choose-time-dialog';
import { appSettings } from '../../../../data';
import validLookup from '../../../../utils/validLookup';
import { durationMarks } from '../../../ui/choose-time-dialog';
import { patientModel } from '../../../patientrecord/model/profileModel';
import AppDialogTitle from '../../../ui/appDialogTitle';
import { Calendar } from 'react-calendar';
import { ChevronLeftOutlined, ChevronRightOutlined } from "@mui/icons-material";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
const steps = [
    'Select location', 'Select date', 'Contact info', 'Confirm',
];

export default function BookAppointmentDialog({ open, onClose, onBooked, patient, plocation, pdate }) {
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState([]);
    const [location, setLocation] = useState('');
    const [canGoToNext, setCanGoToNext] = useState(false);
    const [dayOfAppointment, setDayOfAppointment] = useState();
    const [openSlot, setOpenSlot] = useState();
    const [availableSlots, setAvailableSlots] = useState([]);
    const [resourceGroup, setResourceGroup] = useState({ tick: new Date().getTime(), data: [] });
    const [address, setAddress] = useState();
    const [bookedSuccess, setBookedSuccess] = useState(false);
    const [duration, setDuration] = useState(durationMarks.at(1).value)
    const { setToast } = useUIContext();
    const [notes, setNotes] = useState();
    const [TimeSelectDialog, showTimeSelectDialog] = useDialogModal(ChooseTimeDialog);
    const [selectedSlot, setSelectedSlot] = useState();
    const [bookedSlots, setBookedSlots] = useState([]);
    const [canSaveTimeSelectDialog, setCanSaveTimeSelectDialog] = useState(true);
    const [dateValue, setDateValue] = useState(new Date());
    const [collidesWith, setCollidesWith] = useState({
        tick: new Date().getTime(),
        column: 0,
        starttime: '',
        endtime: ''
    });
    const [timeSelectDialogTimes, setTimeSelectDialogTimes] = useState({
        availableStart: appSettings.businessStartHour,
        availableEnd: appSettings.businessClosingHour
    });
    const [columnAndSlotId, setColumnAndSlotId] = useState({
        column: -1,
        slotId: -1
    });
    const [timeOfAppointment, setTimeOfAppointment] = useState({
        start: '',
        end: ''
    });

    const [contactInfo, setContactInfo] = useState({
        ...patientModel
    });

    const [activeTile, setActiveTile] = useState({
        tabindex: -1,
        tileindex: -1
    });

    useEffect(() => {

        setCanGoToNext(false);

        // location
        if (activeStep === 0 && location) {
            setCanGoToNext(true);
        }

        // appointment and slot
        if (activeStep === 1 && dayOfAppointment && openSlot) {
            setCanGoToNext(true);
        }

        // contact info
        if (activeStep === 2 && contactInfo.id > 0) {
            setCanGoToNext(true);
        }

        if (activeStep === steps.length - 1) {
            setCanGoToNext(true);
        }

    }, [location, activeStep, dayOfAppointment, openSlot, contactInfo]);

    useEffect(() => {

        if (patient) {
            setContactInfo(patient.patient);
        }
    }, [patient]);

    useEffect(() => {

        if (plocation) {
            setLocation(plocation);
            handleNext();
        }
    }, [plocation]);

    useEffect(() => {

        if (pdate) {
            const dt = new Date(
                +pdate.split("-")[0],
                +pdate.split("-")[1] - 1,
                +pdate.split("-")[2],
            );
            setDateValue(dt);
            handleDayClicked(dt);
        }
    }, [pdate]);

    useEffectOnUpdates(() => {
        if (location) {
            LocationService.getById(location).then(result => {
                if (result.code === 0) {
                    setAddress(buildAddress(result.data));
                }
            }).catch(error => console.log(error));
        }
    }, [location]);

    // check if a step has been completed
    const handleNext = () => {
        let newCompleted = completed;

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setCompleted(newCompleted);

    };

    const handleFinish = () => {
        handleNext();
        const doa = dayOfAppointment;
        const startDate = dateSetTime(doa, openSlot.time.start);
        const endDate = dateSetTime(doa, openSlot.time.end);

        const param = {
            startdate: toLocalTimeZone(startDate),
            enddate: toLocalTimeZone(endDate),
            scheduleId: openSlot.scheduleId,
            practitioner: openSlot.practitionerId,
            location: location,
            employee: 5,
            customerId: contactInfo.id,
            notes: notes,
            dayOfWeek: openSlot.dayOfWeek
        };

        AppointmentService.bookAppointment(param).then(response => {
            //console.log(response);

            if (response.code === 0) {
                onBooked();
                setBookedSuccess(true);
            } else {
                setToast({ open: true, message: "Failed to booked" });
            }
        }).catch(error => {
            setToast({ open: true, message: error.message });
            console.log(error);
        });

        //console.log(dayOfAppointment, location, contactInfo, openSlot);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setLocation('');
        setAddress('');
        setDayOfAppointment();
        setOpenSlot();
        setResourceGroup({ tick: new Date().getTime(), data: [] });
        setAvailableSlots([]);
        setContactInfo({
            firstname: '',
            lastname: '',
            phone: ''
        });

        setActiveStep(0);
    };

    // fires a call to get open slots for all doctors
    // for this location and date
    const handleDayClicked = (day) => {
        setDayOfAppointment(day);
        const date = getDateStringNonISO(day);

        const data = {
            location: location || plocation,
            dateOfAppointment: date
        }

        ScheduleService.getOpenSlotsV2(data).then(result => {

            if (result.code === 0) {
                setResourceGroup({ tick: new Date().getTime(), data: result.data });
                //setAvailableSlots(result.data);
            }
        }).catch(error => console.log(error));

    }

    /**
     * move the slot to new location and delete the original one
     * @param {*} slot 
     */
    const handleSlotDrop = (slot) => {

        const { index, error, from, to } = { ...slot };
        const requestedStart = format(to.start, 'HH:mm');
        const requestedEnd = format(to.end, 'HH:mm');
        setTimeOfAppointment({
            start: requestedStart,
            end: requestedEnd
        });
        const slotsForPractitioner = resourceGroup.data;

        const { availabletime } = slotsForPractitioner[index];
        const doa = dateFromString(`${dayOfAppointment.year}-${dayOfAppointment.month}-${dayOfAppointment.day}`);
        const availableStart = dateSetTime(doa, availabletime.starttime);
        const availableEnd = dateSetTime(doa, availabletime.endtime);
        const bookedStart = dateSetTime(doa, requestedStart);
        const bookedEnd = dateSetTime(doa, requestedEnd);

        // check if the slot is within the schedule hours
        if (bookedStart >= availableStart && bookedEnd <= availableEnd) {
            // update the booked slot
            // but we need to make sure that source index and target index are equal
            // if not then it means slot is dropped on another column
            const bookedSlotIndex = slotsForPractitioner[index].bookedtime.findIndex(b => b.id === from.id);

            if (bookedSlotIndex >= 0) {
                slotsForPractitioner[index].bookedtime[bookedSlotIndex].starttime = requestedStart;
                slotsForPractitioner[index].bookedtime[bookedSlotIndex].endtime = requestedEnd;

                setResourceGroup({ tick: new Date().getTime(), data: slotsForPractitioner });
                showTimeSelectDialog();
            } else {

                // add it to the target column slots and also delete the original one
                slotsForPractitioner[index].bookedtime = [...slotsForPractitioner[index].bookedtime, {
                    id: from.id,
                    starttime: requestedStart,
                    endtime: requestedEnd,
                    practitioner: slotsForPractitioner[index].practitioner
                }];


                setResourceGroup({ tick: new Date().getTime(), data: slotsForPractitioner });
                showTimeSelectDialog();
            }

        } else {
            setToast({ open: true, message: `Selected time is out of ${toTitleCase(slotsForPractitioner[index].name)}'s availability.` });
        }
    }

    const handleEventClicked = (event) => {
        const slotsForPractitioner = resourceGroup.data;
        const practitionerGroupIndex = slotsForPractitioner.findIndex(p => p.practitionerid === event.practitionerid);

        setTimeOfAppointment({
            start: event.starttime,
            end: event.endtime
        });

        setTimeSelectDialogTimes({
            availableStart: slotsForPractitioner[practitionerGroupIndex].availabletime.starttime,
            availableEnd: slotsForPractitioner[practitionerGroupIndex].availabletime.endtime
        });

        setColumnAndSlotId({ column: event.col - 1, slotId: event.id });
        showTimeSelectDialog();
    }

    /**
     * check to see if a requested time is within the availability of a practitioner
     * @param {*} availableTime 
     * @param {*} requestedTime 
     * @returns 
     */
    const isWithInAvailableTime = (availableTime, requestedTime) => {
        const doa = dateFromString(`${dayOfAppointment.year}-${dayOfAppointment.month}-${dayOfAppointment.day}`);
        const availableStart = dateSetTime(doa, availableTime.starttime);
        const availableEnd = dateSetTime(doa, availableTime.endtime);
        const bookedStart = dateSetTime(doa, requestedTime.starttime);
        const bookedEnd = dateSetTime(doa, requestedTime.endtime);

        return (bookedStart >= availableStart && bookedEnd <= availableEnd);
    }

    const isCollidingWithBookekdSlot = (requestedTime, forPractitioner, self) => {

        if (self === -1) return;

        // get all the booked slots
        const bookedSlots = getBookedSlots(forPractitioner);
        const { starttime, endtime } = requestedTime;

        if (starttime && endtime) {
            for (let b = 0; b < bookedSlots.length; b++) {
                const booked = bookedSlots[b];
                const rst = timeFromString(starttime);
                const ret = timeFromString(endtime);
                const bst = timeFromString(booked.starttime);
                const bet = timeFromString(booked.endtime);

                // avoid self collision
                if (booked.id === self) continue;

                if ((bst >= rst && bst < ret) || (bet > rst && bet <= ret)) {

                    setCollidesWith({ ...booked, column: columnAndSlotId.column, tick: new Date().getTime() });

                    return true;
                }
            }
        }
        return false;
    }

    const getBookedSlots = (practitionerid) => {
        const slotsForPractitioner = resourceGroup.data;
        const practitionerGroupIndex = slotsForPractitioner.findIndex(p => p.practitionerid === practitionerid);
        return slotsForPractitioner[practitionerGroupIndex].bookedtime;
    }

    const handleSlotClicked = (slot) => {
        setTimeOfAppointment({
            start: '',
            end: ''
        });

        const { startTime, endTime, practitionerId, status, col, duration } = { ...slot };

        const slotsForPractitioner = resourceGroup.data;
        const practitionerGroupIndex = slotsForPractitioner.findIndex(p => p.practitionerId === practitionerId);

        if (status === validLookup.validSlotStatus.available) {
            setDuration(duration);
            setBookedSlots(slotsForPractitioner[practitionerGroupIndex].bookedtime);
            setColumnAndSlotId({ column: practitionerGroupIndex, slotId: -1 });

            setTimeSelectDialogTimes({
                availableStart: startTime,
                availableEnd: endTime
            });
            showTimeSelectDialog();
        }

    }

    const handleTimeChange = (newTime, params) => {

        setTimeOfAppointment({
            start: newTime.start,
            end: newTime.end
        });
        const { column, slotId } = { ...params };
        const slotsForPractitioner = resourceGroup.data;

        //const slotIdx = slotsForPractitioner[params].bookedtime.findIndex(s => s.id === params)
        const bookedTimeIdx = slotsForPractitioner[column].bookedtime.findIndex(s => s.id === slotId);
        const randomId = getRandomNumber(0, 100);
        const self = bookedTimeIdx >= 0 ? slotsForPractitioner[column].bookedtime[bookedTimeIdx].id : -1;

        if (bookedTimeIdx >= 0) {
            // existing one
            slotsForPractitioner[column].bookedtime[bookedTimeIdx].starttime = newTime.start;
            slotsForPractitioner[column].bookedtime[bookedTimeIdx].endtime = newTime.end;

        } else {
            // add new
            setColumnAndSlotId({ column: column, slotId: randomId });

            slotsForPractitioner[column].bookedtime = [...slotsForPractitioner[column].bookedtime, {
                id: randomId,
                starttime: newTime.start,
                endtime: newTime.end,
                practitioner: slotsForPractitioner[column].practitioner
            }];
        }

        if (isCollidingWithBookekdSlot({ starttime: newTime.start, endtime: newTime.end },
            slotsForPractitioner[column].practitionerid, self)) {
            setToast({ open: true, message: "Selected time overlaps with an existing appointment. Please choose different time." });
            setCanSaveTimeSelectDialog(false);
        } else {
            setResourceGroup({ tick: new Date().getTime(), data: slotsForPractitioner });
            setCanSaveTimeSelectDialog(true);
        }

    };

    const handleSave = (time, param) => {
        const { column, slotId } = { ...param };

        //setDuration(timeDiff(time.start, time.end));
        // check to make sure it's within the available time
        const slotsForPractitioner = resourceGroup.data;

        setOpenSlot({
            scheduleId: slotsForPractitioner[column].scheduleId,
            dayOfWeek: slotsForPractitioner[column].dayOfWeek,
            time: time,
            practitionerId: slotsForPractitioner[column].practitionerId,
            practitioner: slotsForPractitioner[column].name
        });
    }

    /**
     * do nothing
     * @param {*} param 
     */
    const handleDelete = (param) => {
        setCollidesWith({ starttime: '', endtime: '', column: 0, tick: new Date().getTime() });

        const { column, slotId } = { ...param };
        const slotsForPractitioner = resourceGroup.data;
        slotsForPractitioner[column].bookedtime = slotsForPractitioner[column].bookedtime.filter(b => b.id !== slotId)

        setResourceGroup({ tick: new Date().getTime(), data: slotsForPractitioner });
    }

    return (
        <>
            <Dialog onClose={onClose} open={open} fullScreen >
                <AppDialogTitle onClose={onClose} title="Book appointment" />
                <DialogContent>
                    <Box sx={{ width: '100%', height: '100%' }} display="flex" flexDirection={"column"}>
                        <Stepper activeStep={activeStep}>
                            {steps.map((step, index) => {
                                return (
                                    <Step key={step}>
                                        <StepLabel >{step}</StepLabel>

                                        {/* {step.component && <StepContent ><step.component /></StepContent>  } */}
                                    </Step>
                                );
                            })}
                        </Stepper>
                        {activeStep === steps.length ? (
                            <>
                                {bookedSuccess && <Typography variant='h4' sx={{ textAlign: 'center', mt: 4 }} >
                                    Appointment Booked!
                                </Typography>}
                                <Box display='flex' flexDirection='row' alignItems="center" sx={{ pt: 2 }}>
                                    <Button onClick={handleReset}>Book Another</Button>
                                </Box>
                            </>
                        ) : (
                            <>
                                <Box flexGrow={1} display="flex" flexDirection={"column"} justifyContent="center">
                                    {/* https://stackoverflow.com/questions/46592833/how-to-use-switch-statement-inside-a-react-component */}
                                    {
                                        {
                                            0:
                                                <>
                                                    <SelectLocation
                                                        fullWidth={true}
                                                        location={location}
                                                        setLocation={setLocation} />
                                                    <Typography variant="subtitle1" sx={{ mt: 1, color: Colors.muted }}>{address}</Typography>
                                                </>,
                                            1:
                                                <Grid container columnSpacing={1}>
                                                    <Grid item xs={12} md={4}>
                                                        {/* <MiniCalendar
                                                            dayOfAppointment={dayOfAppointment}
                                                            setDayOfAppointment={setDayOfAppointment}
                                                            onDayClicked={handleDayClicked} /> */}
                                                        <Box sx={{p: 1}}>
                                                            <Calendar
                                                                prev2Label={<KeyboardDoubleArrowLeftIcon />}
                                                                prevLabel={<ChevronLeftOutlined />}
                                                                nextLabel={<ChevronRightOutlined />}
                                                                next2Label={<KeyboardDoubleArrowRightIcon />}
                                                                onClickDay={handleDayClicked}
                                                                onChange={setDateValue} 
                                                                value={dateValue} 
                                                                tileClassName={"dp-custom-tile"} />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} md={8}>
                                                        <GroupByPractitioners
                                                            resources={resourceGroup}
                                                            onAvailableSlotClicked={handleSlotClicked}
                                                            onDrop={handleSlotDrop}
                                                            collidesWith={collidesWith}
                                                            onEventClicked={handleEventClicked}
                                                            forDate={dayOfAppointment
                                                                &&
                                                                dateFromString(`${dayOfAppointment.year}-${dayOfAppointment.month}-${dayOfAppointment.day}`)
                                                            } />
                                                    </Grid>
                                                </Grid>,
                                            2: <ContactInfo
                                                contact={patient && patient.patient}
                                                contactInfo={contactInfo}
                                                setContactInfo={setContactInfo} />,
                                            3: <ConfirmAppointmentDetails
                                                notes={notes}
                                                setNotes={setNotes}
                                                address={address}
                                                dayOfAppointment={dayOfAppointment}
                                                openSlot={openSlot}
                                                contactInfo={contactInfo}
                                                duration={duration}
                                            />
                                        }[activeStep]
                                    }

                                </Box>
                                <Divider />
                                <Box display={"flex"} flexDirection="row"
                                    sx={{ pt: 2 }}>
                                    <Button
                                        color="inherit"
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}

                                    >
                                        Back
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />

                                    {activeStep === steps.length - 1
                                        ?
                                        <Button onClick={handleFinish} disabled={!canGoToNext}>Finish</Button>
                                        : <Button onClick={handleNext} disabled={!canGoToNext}>Next</Button>
                                    }
                                    {/* <Button onClick={handleNext} disabled={!canGoToNext}>
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                </Button> */}
                                </Box>
                            </>
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
            <TimeSelectDialog
                startAt={timeOfAppointment.start}
                params={columnAndSlotId}
                endAt={timeOfAppointment.end}
                availableStart={timeSelectDialogTimes.availableStart}
                availableEnd={timeSelectDialogTimes.availableEnd}
                bookedSlots={bookedSlots}
                canSave={canSaveTimeSelectDialog}
                onSave={handleSave}
                onDelete={handleDelete}
                duration={duration}
                onTimeChange={handleTimeChange} />
        </>

    );
}



