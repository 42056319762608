// eslint-disable-next-line import/no-anonymous-default-export
export default {
  color: {
    primary: "#00adb5",
    secondary: "#95defb",
    success: "#4CAF50",
    info: "#00a2ff",
    danger: "#FF5722",
    warning: "#FFC107",
    dark: "#0e1b20",
    light: "#aaa",
    muted: "#abafb3",
    border: "#DDDFE1",
    inverse: "#2F3D4A",
    shaft: "#333",
    ///////////////
    // Grays
    ///////////////
    dim_grey: "#696969",
    dove_gray: "#d5d5d5",
    body_bg: "#f3f6f9",
    ///////////////
    // Solid Color
    ///////////////
    white: "#fff",
    black: "#000",
    ///////////////
    // Appointment
    ///////////////
    booked: "#E51C23",    // RED
    confirmed: "#03A9F4", // LIGHT BLUE
    completed: "#9E9E9E", // GREY
    noshow: "#FF5722",    // DEEP ORANGE
    cancelled: "#607D8B", // BLUE GREY
    arrived: "#FFEB3B",   // INDIGO
    paid: "#259B24",      // GREEN
    open: "#8BC34A",      // LIGHT GREEN
    close: "#009688",     // TEAL
  },
  dimensions: {
    drawerWidth: 240,
    borderRadius: 4,
  },
  margins: {
    margin: 12,
  },
};
