import { Typography, Box, lighten } from "@mui/material";
import { styled } from "@mui/material/styles";
import Styles from "../";
import validLookup from "../../utils/validLookup";
import { ZIndex } from "../constants/enums";
import { Colors } from "../theme";
import { pulseAnimation } from "../../animation";
/**
 * TODO
 */
export const CurrentTimeIndicator = styled("div", {
  shouldForwardProp: (prop) => prop !== 'topOffset'
})(({ topOffset }) => ({

    display: topOffset >= 0 ? 'block' : 'none',
    left: "0",
    position: "absolute",
    width: "100%",
    height: "1px",
    top: `${topOffset}px`,
    background: Styles.color.danger,
    zIndex: 999,
    "&::after": {
      content: '""',
      width: '12px',
      height: '12px',
      background: Styles.color.danger,
      position: 'absolute',
      left: -4,
      borderRadius: '50%',
      bottom: -6,
    }
}));

const daysContainer = styled("div")(() => ({
  display: "flex",
}));

export const CalendarContainer = styled("div")(() => ({
  position: "relative",
  display: "grid",
  gridTemplateColumns: "80px auto",
  gridTemplateRows: "auto",
  gap: "0px 1px",
  gridTemplateAreas: '". header" "timeslots-container main"',
  marginTop: "8px",
  background: Styles.color.body_bg,
}));

export const CalendarHeader = styled("div")(() => ({
  gridArea: "header",
}));

export const UL = styled("ul")(() => ({
  listStyle: "none",
  padding: 0,
  margin: 0,
}));

export const DaysContainer = styled(UL)(({days}) => ({
  display: "grid",
  gridTemplateColumns: `repeat(${days}, 1fr)`,
  textTransform: "capitalize",
  "&::after": {
    content: '""',
    left: "0",
    position: "absolute",
    width: "100%",
    height: "2px",
    background: Styles.color.primary,
    //background: `linear-gradient(90deg, rgba(235,235,235,0.25) 0%, ${Styles.color.dove_gray} 25%,${Styles.color.dove_gray} 75%, rgba(235,235,235,1) 100%)`,
    zIndex: 1,
  },
}));

export const DayContainer = styled(DaysContainer)(() => ({
  gridTemplateColumns: "repeat(1, 1fr)",
}));

export const DayNumber = styled(Box)(() => ({
  padding: "2px 4px",
  fontSize: "1.5em",
}));
export const DayName = styled(Box)(() => ({
  padding: "2px 4px",
  fontSize: ".85em",
}));

export const DayBox = styled(Box)(({isToday}) => ({
  background: Styles.color.dim_grey,
  borderRight: `1px solid ${Styles.color.border}`,
  color: isToday ? Styles.color.secondary : Styles.color.dove_gray,
  fontWeight: isToday && "bold", 
  maxHeight: '60px',
  "&::after": {
    content: '""',
    position: "absolute",
    width: "1px",
    height: "calc(100% - 56px)",
    background: Styles.color.dove_gray,
    zIndex: 1,
  },
}));

export const TimeslotsContainer = styled("div")(() => ({
  gridArea: "timeslots-container",
  justifyItems: "left",
}));

export const TimeslotsHolder = styled(UL)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const Timeslot = styled(Box)(({ width }) => ({
  minHeight: "60px",
  maxWidth: "80px",
  "&::before": {
    content: '""',
    left: "0",
    position: "absolute",
    width: "100%",
    height: "1px",
    background: Styles.color.dove_gray,
    zIndex: `${ZIndex.NORMAL}`,
  },
}));
//background: `linear-gradient(90deg, rgba(235,235,235,0.25) 0%, ${Styles.color.dove_gray} 25%,${Styles.color.dove_gray} 75%, rgba(235,235,235,1) 100%)`,

  /* 
  4 * 15 min intervals * 12 slots
  4 * 9 = 27 */
export const EventsContainer = styled("div")(() => ({
  position: 'relative',
  display: "grid",
  gridTemplateColumns: "repeat(7, 1fr)",
  gridTemplateRows: `repeat(48, 1fr)`,
  gridArea: "main",
  whiteSpace: 'nowrap'
}));

/***************************************************************
 * Day Column
 ****************************************************************/
export const DayColumnContainer = styled('div')(({columns}) => ({
  position: 'absolute',
  display: 'grid',
  gridTemplateColumns: `repeat(${columns}, 1fr)`,
  gridTemplateRows: 'repeat(48, 1fr)',
  gridTemplateAreas: "'d-0 d-1 d-2 d-3 d-4 d-5 d-6'",
  gridArea: 'main',
  width: '100%',
  height: '100%',
}));

export const DayColumnWrapper = styled('div')(({day}) => ({
  position: 'absolute',
  display: 'grid',
  gridTemplateColumns: 'repeat(1, 1fr)',
  gridTemplateRows: 'repeat(48, 0fr)',
  gridArea: `d-${day}`,
  width: '100%',
  
}));

/***************************************************************
 * Event slot
 ****************************************************************/
export const SlotGroupWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const SlotWrapper = styled('div')(({draggedover}) => ({
  position: 'relative',
  minHeight: '60px',
  outline: draggedover === 'true' && '3px dashed #00adb5aa',
  zIndex: 10,
  '&:hover': {
    //cursor: 'pointer',
    // background: Styles.color.primary,
  },
}));


export const EventSlot = styled(Box)(({background}) => ({
  position: "absolute",
  zIndex: ZIndex.TOP,
  marginTop: "0px",
  outline: "none",
  background: background ? `#${background}` : Styles.color.info,
  overflow: 'hidden',
  border: '1px solid #fff',
  borderRadius: `${Styles.dimensions.borderRadius}px`,    
  "&::after": {
    position: 'absolute',
    top: '-1px',
    left: '-1px',
    width: 'calc(100% + 2px)',
    height: 'calc(100% + 2px)',
    content: '""',
    boxSizing: 'border-box',
    borderRadius: '5px',
    border: '1px solid #fff',
  }
}));

export const ResizeHandle = styled('div')(() => ({
  position: 'absolute',
  left: '50%',
  bottom: 0,
  '&:hover': {
    top: 0,
    background: 'red',
    cursor: 'ns-resize'
  },
  '& span': {
    display: "block",
    width: "20px",
    height: "1px",
    marginBottom: "3px",
    position: "relative",
    background: "#cdcdcd9f",
    borderRadius: "3px",
    ZIndex: `${ZIndex.TOP + 1}`,  
  },
}));

export const OneSlot = styled(EventSlot,{
  shouldForwardProp: (props) => props !== 'hover'
})(({ width, height, left, row, column,status, hover, isColliding }) => ({
  width: `${width}%`,
  height: `${height}px`,
  gridRow: row,
  gridColumn: column,
  left: `${left}%`,
  cursor: 'pointer',    
  background: lighten(Colors.primary,0.5),
  animation: isColliding && `${pulseAnimation} 1.5s ease-in both`,
  zIndex: `${ZIndex.TOP}`,
  ...(status === validLookup.validAppointmentStatus.booked && {
    '&::before': {      
      position: 'absolute',
      content: '""',
      borderLeft: `4px solid ${Colors.booked}`,
      height: '100%',
    },   
  }),
  ...(status === validLookup.validAppointmentStatus.confirmed && {
    '&::before': {
      position: 'absolute',
      content: '""',
      borderLeft: `4px solid ${Colors.confirmed}`,
      height: '100%',
    },   
  }),
  ...(status === validLookup.validAppointmentStatus.completed && {
    '&::before': {
      position: 'absolute',
      content: '""',
      borderLeft: `4px solid ${Colors.completed}`,
      height: '100%',
    },   
  }),
  ...(status === validLookup.validAppointmentStatus.noshow && {
    '&::before': {
      position: 'absolute',
      content: '""',
      borderLeft: `4px solid ${Colors.noshow}`,
      height: '100%',
    },   
  }),
  ...(status === validLookup.validAppointmentStatus.cancelled && {
    '&::before': {
      position: 'absolute',
      content: '""',
      borderLeft: `4px solid ${Colors.cancelled}`,
      height: '100%', 
    },   
  }),
  ...(status === validLookup.validAppointmentStatus.arrived && {
    '&::before': {
      position: 'absolute',
      content: '""',
      borderLeft: `4px solid ${Colors.arrived}`,
      height: '100%',
    },   
  }),
  ...(status === validLookup.validAppointmentStatus.completed && {
    '&::before': {
      position: 'absolute',
      content: '""',
      borderLeft: `4px solid ${Colors.completed}`,
      height: '100%',
    },   
  }),
  ...(status === validLookup.validSlotStatus.open && {
    '&::before': {
      position: 'absolute',
      content: '""',
      borderLeft: `4px solid ${Colors.open}`,
      height: '100%',
    },   
  }),
  ...(status === validLookup.validSlotStatus.available && {
    background: `repeating-linear-gradient( 45deg, ${lighten(Colors.success,0.7)}, ${lighten(Colors.success,0.7)} 6px, #e7e7e7 5px, #ededed 20px )`,       
    zIndex: `${ZIndex.BELOW}`,
    '&::before': {
      position: 'absolute',
      content: '""',
      borderLeft: `10px solid ${Colors.success}`,
      height: '100%',
    },   
    '&:hover': {
      background: hover &&  `repeating-linear-gradient( 45deg, ${lighten(Colors.success,0.4)}, ${lighten(Colors.success,0.4)} 6px, #e7e7e7 5px, #ededed 20px )`,    
    },   
  }),
  ...((status === validLookup.validSlotStatus.close || status === validLookup.validSlotStatus.vacation) && {
    width: '100%',
    background: 'repeating-linear-gradient( 45deg, #cbcbcb, #cbcbcb 6px, #e7e7e7 5px, #ededed 20px )'   
  }),
  //'&:hover': {
    //boxShadow: `2px 2px 1px  ${Colors.dove_gray}`,
    //zIndex: hover && 999,
    // opacity: 0.9,
    // background: Styles.color.secondary,
  //}
}));

export const EventName = styled(Typography)(() => ({
  fontSize: '16px'
}));

export const EventTime = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 400,
}));
