import { Badge, IconButton } from "@mui/material";
import { NotificationAddOutlined } from "@mui/icons-material";
import NotificationDrawer from "./notificationdrawer";
import { useUIContext } from "../../../state/ui";

function UserNotifications() {
  const { notificationDrawerOpen, setNotificationDrawerOpen } = useUIContext();

  return (
    <IconButton
      size="large"
      aria-label="notifications for current user"
      sx={{
        color: "#fff",
      }}
      onClick={() => setNotificationDrawerOpen(!notificationDrawerOpen)}
    >
      <Badge badgeContent={4} color="info">
        <NotificationAddOutlined />
        <NotificationDrawer />
      </Badge>
    </IconButton>
  );
}

export default UserNotifications;
