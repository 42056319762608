import React, { useEffect, useState } from "react";
import { practioners } from "../../data";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Select, Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { LocationService } from "../../service/locationService";
import { EmployeeService } from '../../service/employeeService'
import { getTimeRange, toTitleCase } from "../../utils";
import { appSettings } from '../../data';
import { useAppContext } from "../../state/appState";

/***************************************************************
 * Practioner Selector
 ****************************************************************/
export const SelectPractioner = ({ practitioner, setPractitioner, fullWidth, sx }) => {
    const { appState } = useAppContext();

    // useEffect(() => {
    //     EmployeeService.getLocations().then(result => {

    //         if( result.code === 0 ) {
    //             setData(result.data);
    //         }
    //     }).catch(error => console.log(error));
    // },[]);

    const handleChange = (event) => { setPractitioner(event.target.value);}

    return (
        <FormControl fullWidth={fullWidth} size="small" sx={{
            minWidth: "200px",
            ...sx
        }}>
            <InputLabel id="practioner-select-label">Practitioner</InputLabel>
            <Select
                labelId="practitioner-select-label"
                id="practitioner-select"
                value={practitioner}
                label="practitioner"
                onChange={handleChange}
            >
                {appState.practitioners.map(p=> (
                    <MenuItem 
                    key={p.id} 
                    value={p.id}>{toTitleCase(p.fullName)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

/***************************************************************
 * Location Selector
 ****************************************************************/

export const SelectLocation = ({ location, setLocation, fullWidth, sx }) => {
    const { appState } = useAppContext();

    const handleChange = (event) => { 
        const loc = appState.locations.filter(l => l.id === event.target.value).pop() || null;

        setLocation(loc);
    }

    return (
        <Box>
        <FormControl fullWidth={fullWidth && fullWidth} size="small" sx={{
            minWidth: "200px",
            ...sx
        }}>
            <InputLabel id="location-select-label">Location</InputLabel>
            <Select
                labelId="location-select-label"
                id="location-select"
                value={location}
                label="Location"
                onChange={handleChange}
            >

                {appState.locations.map((location) => (
                    <MenuItem key={location.id} value={location.id}>{toTitleCase(location.alias)}</MenuItem>
                ))}
            </Select>
        </FormControl>
        </Box>

    );
};


/***************************************************************
 * Time Selector
 ****************************************************************/
 export const SelectTime = ({ location, setLocation, fullWidth, sx }) => {
    const [time, setTime] = useState([]);

    useEffect(() => {
        getTimeRange({h: appSettings.businessStartHour, m: 0 }, {h: appSettings.businessClosingHour, m: 0}, appSettings.timeSlotInterval);
    },[]);

    const handleChange = (event) => { 
        setLocation(event.target.value);
    }

    return (
        <FormControl fullWidth={fullWidth} size="small" sx={{
            minWidth: "200px",
            ...sx
        }}>
            <InputLabel id="time-select-label">Time</InputLabel>
            <Select
                labelId="time-select-label"
                id="time-select"
                value={time}
                label="Location"
                onChange={handleChange}
            >

                {time.map((time) => (
                    <MenuItem key={time} value={time}>{time}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

// function withData(WrappedComponent, data) {

//     return () => {
//         const [locationData, setLocationData] = useState([]);
//         useEffect(() => {
//             data.then(result => {
    
//                 if( result.code === 0 ) {
//                     setLocationData(result.data);
//                 }
//             }).catch(error => console.log(error));
//         },[]);


//         return <WrappedComponent data={locationData}/>
//     }
// }

// export const listWithData = withData(
//     SelectLocation,
//     (DataSource) =>  DataSource.getLocations()
// );