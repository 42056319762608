import request from "./httpService";

const getLocations = () => {
    return request({
        method: "GET",
        url: '/location'
    });
}

const getById = (id) => {
    return request({
        method: "GET",
        url: `/location/${id}`
    });
}



export const LocationService = {
    getLocations,
    getById,
};