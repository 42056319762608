import { addDays, addWeeks } from "date-fns";
import { createContext, useContext, useMemo, useReducer, useState } from "react";
import { dateFromString } from "../../utils";
import { useActiveView } from "./hooks/use-active-view";
import { useActiveWeek } from "./hooks/use-active-week";
import { useCalendarActions } from "./hooks/use-calendar-actions";
import { useCalendarEvents } from "./hooks/use-calendar-events";

export const CALENDAR_ACTION_TYPE = {
  SWITCHVIEW: 'switchview',
  PREVNEXT: 'prevnext',
  GOTODATE: 'gotodate',
  SETACTIVEWEEK: 'setactiveweek',
  SETACTIVEDAY: 'setactiveday',
  SETACTIVEMONTH: 'setactivemonth',
  TODAYDATE: 'todaydate'
}

const initialState = {
  activeView: 'day',
  prevnext: '',
  activeWeek: 0,
  activeDay: 0,
  activeMonth: 0,
  activeDate: new Date()
};

function reducer(state, action) {
  let lState = state;
  const { jumptype, jumpamount, goto } = { ...action.payload };

  switch (action.type) {
    case CALENDAR_ACTION_TYPE.SWITCHVIEW:
      return { ...state, activeView: action.payload };
    case CALENDAR_ACTION_TYPE.PREVNEXT:

      const direction = jumptype === 'next' ? 1 : -1;

      if (state.activeView === 'week') {
        lState = { ...state, 
          prevnext: jumptype, 
          activeWeek: state.activeWeek + (1 * direction),
          activeDate: addWeeks(state.activeDate, (1 * direction)) };
      } else if (state.activeView === 'day') {
        lState = { ...state, 
          prevnext: jumptype, 
          activeDay: state.activeDay + (1 * direction), 
          activeDate: addDays(state.activeDate, (1 * direction)) };
      }

      return lState;
    case CALENDAR_ACTION_TYPE.GOTODATE:

      if (state.activeView === 'week') {
        lState = { ...state, prevnext: jumptype, activeWeek: jumpamount, activeDate: dateFromString(goto) };
      } else if (state.activeView === 'day') {
        lState = { ...state, prevnext: jumptype, activeDay: jumpamount, activeDate:  dateFromString(goto) };
      }
      return lState;
    case CALENDAR_ACTION_TYPE.TODAYDATE:
      return { ...state, activeDay: 0, activeWeek: 0, activeDate: new Date() }
    case CALENDAR_ACTION_TYPE.SETACTIVEDAY:
      return { ...state, activeDay: state.activeDay + action.payload }
    case CALENDAR_ACTION_TYPE.SETACTIVEWEEK:
      return { ...state, activeWeek: state.activeWeek + action.payload }
    case CALENDAR_ACTION_TYPE.SETACTIVEMONTH:
      return { ...state, activeMonth: action.payload }
    default:
      throw new Error();
  }
}

export const CalendarContext = createContext({});
export const useCalendarContext = () => useContext(CalendarContext);

export const CalendarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [activeWeek, setActiveWeek] = useActiveWeek();
  const [activeDay, setActiveDay] = useActiveWeek();
  const { addListener, onNext, onPrev } = useCalendarActions();
  const { addEventListener, onAdded, onEdit, onRemoved, onResourceUpdated } = useCalendarEvents();
  const { activeView, onViewChanged } = useActiveView();
  const [timeIndicator, setTimeindicator] = useState(false);

  const value = {
    addListener,
    activeView,
    addEventListener,
    onViewChanged,
    onNext,
    onPrev,
    onAdded,
    onEdit,
    onRemoved,
    onResourceUpdated,
    activeWeek,
    setActiveWeek,
    activeDay,
    setActiveDay,
    timeIndicator,
    setTimeindicator,
    state,
    dispatch
  };

  return (
    <CalendarContext.Provider value={value}>
      {children}
    </CalendarContext.Provider>
  );
};
