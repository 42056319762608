import DashboardIcon from "@mui/icons-material/Dashboard";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import ListIcon from "@mui/icons-material/List";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

export const drawerMenu = [
    {
      text: "Dashboard",
      to: "/dashboard",
      icon: <DashboardIcon />,
    },
    {
      text: "Appointments",
      to: "/appointments",
      icon: <CalendarTodayIcon />,
    },
    {
      text: "Schedule",
      to: "/schedule",
      icon: <CalendarTodayIcon />,
    },
    {
      text: "Patient Record",
      to: "/patientrecord",
      icon: <AssignmentIcon />,
    },
    {
      text: "Orders",
      to: "/order",
      icon: <ShoppingCartIcon />,
    },
    {
      text: "Inventory",
      to: "/inventory",
      icon: <ListIcon />,
    },
    {
      text: "Reporting",
      to: "/reporting",
      icon: <PlaylistAddCheckIcon />,
    },
    {
      text: "Admin",
      to: '/admin',
      icon: <SupervisorAccountIcon />
    }
  ];