
import {
  CalendarHeader,
  TimeslotsContainer,
  TimeslotsHolder,
  Timeslot,
} from "../../../styles/calendar";
import Header, { DayHeader } from "../header";
import { timeslots } from '../../../data';
import { useCalendarContext } from '../../../state/calendar';

export default function DayView() {

  const { state } = useCalendarContext();

  return (
    <>
      <CalendarHeader>
        <Header days={1} render={(data) => <DayHeader activeDay={state.activeDay}/>} />
      </CalendarHeader>
      <TimeslotsContainer>
        <TimeslotsHolder >
          {timeslots.map((slot, index) => (
            <Timeslot key={index}>{slot}</Timeslot>
          ))}
        </TimeslotsHolder>
      </TimeslotsContainer>
    </>
  );
}
