import React, { useState } from 'react';
import {
    Grid,
    Typography,
    TextField,
    Button,
    Container,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    InputAdornment,
    IconButton,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AppDialogTitle from '../../ui/appDialogTitle';

// const useStyles = makeStyles((theme) => ({
//     form: {
//         marginTop: theme.spacing(2),
//         marginBottom: theme.spacing(2),
//     },
//     list: {
//         listStyleType: 'none',
//         padding: 0,
//         margin: 0,
//         '& li': {
//             margin: theme.spacing(1),
//         },
//     },
// }));

const roles = ['Sales', 'Manager', 'Practitioner', 'Accounting', 'Guest', 'Admin'];

const PasswordField = ({ label, value, onChange, onBlur, error, touched, fieldName, form: { values, setFieldValue }, ...otherProps }) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handlePasswordChange = (event) => {
        setFieldValue(fieldName, event.target.value);
    };

    return (
        <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id={fieldName}
            label={label}
            type={showPassword ? 'text' : 'password'}
            value={values[fieldName] || ''}
            onChange={handlePasswordChange}
            onBlur={onBlur}
            error={touched[fieldName] && error}
            helperText={touched[fieldName] && error}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={handleTogglePassword}>
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
            {...otherProps}
        />
    );
};

export default function EmployeeInputForm({ open, onClose }) {
    //const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);

    const initialValues = {
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        password: '',
        role: '',
    };

    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required('First name is required'),
        lastname: Yup.string().required('Last name is required'),
        phone: Yup.string()
            .matches(
                /^\d{10}$/,
                'Phone number must be 10 digits and contain numbers only'
            )
            .required('Phone number is required'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email address is required'),
        password: Yup.string()
            .min(8, 'Password must be at least 8 characters long')
            .required('Password is required'),
        role: Yup.string().required('Role is required'),
    });

    const handleSubmit = (values, { resetForm }) => {
        console.log(
            `Submitted: ${values.firstname}, ${values.lastname}, ${values.phone}, ${values.email}, ${values.password}, ${values.role}`
        );
        resetForm();
    };

    return (
        <Dialog open={open}>
            <AppDialogTitle title={"Add Employee"} onClose={onClose} />
            <DialogContent>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, setFieldValue, isSubmitting, touched }) => (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        as={TextField}
                                        name="firstname"
                                        label="First Name"
                                        required
                                        fullWidth
                                    />
                                    <ErrorMessage name="firstname" />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        as={TextField}
                                        name="lastname"
                                        label="Last Name"
                                        required
                                        fullWidth
                                    />
                                    <ErrorMessage name="lastname" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        as={TextField}
                                        name="phone"
                                        label="Phone Number"
                                        required
                                        fullWidth
                                    />
                                    <ErrorMessage name="phone" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        as={TextField}
                                        name="email"
                                        label="Email"
                                        required
                                        fullWidth
                                    />
                                    <ErrorMessage name="email" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="password"
                                        label="Password"
                                        component={PasswordField}
                                        fieldName="password"
                                        values={values}
                                        required
                                        touched
                                        setFieldValue={setFieldValue} />

                                    <ErrorMessage name="password" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        as={TextField}
                                        name="role"
                                        label="Role"
                                        select
                                        required
                                        fullWidth
                                    >
                                        {roles.map((role) => (
                                            <MenuItem key={role} value={role}>
                                                {role}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="role" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                    >
                                        Create
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};