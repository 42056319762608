import { alpha, Box, Button, IconButton, Popper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useReducer, useState } from "react"
import { Colors } from "../../../../styles/theme";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ButtonWithMenu from "../../../common/buttonWithMenu";
import useDialogModal from "../../../../hooks/useDialogModal";
import AddContactsRxDialog from "./addContactsRxDialog";
import AddGlassesRxDialog from "./addGlassesRxDialog";
import PaginationControl from "../../../ui/paginationComponent";
import { niceDate } from "../../../../utils";
import validLookup from "../../../../utils/validLookup";

export const rxdata = [{
    id: 1,
    date: '2022-02-10',
    type: validLookup.validRxType.glasses,
    doctor: 'Jane Doe',
    startDate: '2022-02-10',
    endDate: '2023-02-10'
},
{
    id: 2,
    date: '2022-02-10',
    type: validLookup.validRxType.contacts,
    doctor: 'Jane Doe',
    startDate: '2022-02-10',
    endDate: '2023-02-10'
}
]

export default function RxTab({ patient }) {

    const [state, updateState] = useReducer((state, params) => {
        return { ...state, ...params }
    }, {
        currentPageTotal: 0,
        items: rxdata,
        open: false,
        selectedItem: null,
        anchorEl: null,
    });

    const [ContactLenstDialog, showContactLensDialog] =
        useDialogModal(AddContactsRxDialog);
    const [GlassLenstDialog, showGlassLensDialog] =
        useDialogModal(AddGlassesRxDialog);

    useEffect(() => {
        updateState({ currentPageTotal: rxdata.length });
    }, [patient]);

    const handleAddGlassesRx = () => {
        showGlassLensDialog();
    }

    const handleAddContactsRx = () => {
        showContactLensDialog();
    }

    const handleRxEdit = (type) => {
        if (type === validLookup.validRxType.glasses) {
            showGlassLensDialog(null, {
                rowid: 1
            });
        } else if (type === validLookup.validRxType.contacts) {
            showContactLensDialog();
        }
    }

    const handleRxDelete = (rowid) => {

    }

    const handlePageChange = () => {

    }

    const handleDeleteTrigger = (element, row) => {
        updateState({open: true, selectedItem: row, anchorEl: element.currentTarget});
    }

    const handleDeleteItem = () => {
        let d = rxdata.filter(d => d.id !== state.selectedItem.id);
        updateState({open: false, selectedItem: null, items: d, anchorEl: null});
    }

    return (
        <Box display="flex" flexDirection="column">
            <Box sx={{ alignSelf: 'end', mb: 1 }}>
                <ButtonWithMenu
                    title={'Add'}
                    menuItems={[{
                        name: 'Glasses',
                        handler: handleAddGlassesRx
                    },
                    {
                        name: 'Contact Lens',
                        handler: handleAddContactsRx
                    }
                    ]} />
            </Box>

            {/* <ButtonWithIcon
                icon={<AddIcon />}
                title="Add new"
                variant="contained" size="small" sx={{ alignSelf: 'end', mb: 1 }} onClickHandler={() => { }} /> */}
            <TableContainer>
                <Table size="small" >
                    <TableHead sx={{ background: alpha(Colors.primary, 0.5) }}>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Doctor</TableCell>
                            <TableCell>Start date</TableCell>
                            <TableCell>Expiry date</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            state.items.map(row => (
                                <TableRow key={row.id}>
                                    <TableCell>{niceDate(row.date)}</TableCell>
                                    <TableCell>{row.type}</TableCell>
                                    <TableCell>{row.doctor}</TableCell>
                                    <TableCell>{niceDate(row.startDate)}</TableCell>
                                    <TableCell>{niceDate(row.endDate)}</TableCell>
                                    <TableCell>
                                        <Box display="flex">
                                            <IconButton onClick={() => handleRxEdit(row.type)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={(event) => handleDeleteTrigger(event, row)}>
                                                <DeleteForeverIcon color="danger" />
                                            </IconButton>

                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))
                        }


                    </TableBody>
                </Table>
            </TableContainer>
            <PaginationControl
                totalCount={rxdata.length}
                currentPageTotal={state.currentPageTotal}
                onPageChange={handlePageChange} />
            <ContactLenstDialog ignoreBackdropClick={true} />
            <GlassLenstDialog ignoreBackdropClick={true} />
            <Popper id={state.open ? 'delete-item-popper' : undefined}
                open={state.open}
                anchorEl={state.anchorEl}>
                <Box boxShadow={4} sx={{ borderRadius: 1, p: 1, bgcolor: Colors.white }}>
                    <Typography>Delete this item? </Typography>
                    <Stack direction={"row"}>
                        <Button color="danger" onClick={handleDeleteItem}>Yes</Button>
                        <Button color="success" onClick={() => updateState({ open: false, anchorEl: null, selectedItem: null })}>No</Button>
                    </Stack>

                </Box>
            </Popper>
        </Box>
    )
}