import {
    TableContainer,
    Table, TableHead, TableRow, TableCell, TableBody, Box, IconButton, Typography,
    TextField,
    Button,
    alpha,
    Pagination,
    Select,
    MenuItem
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ChevronLeftOutlined } from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from "react";
import { Colors } from "../../../styles/theme";
import { ButtonWithIcon } from "../../ui";
import { CommentService } from "../../../service/commentService";
import { isLocalDev } from "../../../utils/environment";
import { toast } from "react-toastify";
import PaginationControl, { initialPageSize } from "../../ui/paginationComponent";
import ConfirmationDialog from "../../ui/confirmationDialog";
import useDialogModal from "../../../hooks/useDialogModal";
import validLookup from "../../../utils/validLookup";

export default function CommentTab({ patientId }) {

    const [patientComments, setPatientComments] = useState([]);
    const [commentValue, setCommentValue] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [commentToEdit, setCommentToEdit] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPageTotal, setCurrentPageTotal] = useState(0);
    const [
        ConfirmationDialogComp,
        showConfirmationDialog,
        closeConfirmationDialog,
    ] = useDialogModal(ConfirmationDialog);

    /**
     * Load comments for this patient
     */
    useEffect(() => {
        loadComments(0, initialPageSize);
    }, []);

    const loadComments = (from, to) => {
        CommentService.getCommentsWithLimits(patientId, { offset: from, limit: to }).then(response => {
            const { code, data, message } = { ...response };

            if (code === 0) {
                setPatientComments(data.data);
                setCurrentPageTotal(data.data.length);
                setTotalCount(data.count);
            }
        });
    }

    /**
     * edit existing comment
     * @param {*} comment 
     */
    const handleCommentEdit = (comment) => {
        setCommentToEdit(comment);
        setCommentValue(comment.comments);
        setEditMode(true);
    }

    /**
     * copy existing comment and create new one
     * @param {*} comment 
     */
    const handleCommentCopy = (comment) => {
        setCommentValue(comment.comments);
        setEditMode(true);
    }

    const handleCommentDelete = async (comment) => {
        
        try {
            showConfirmationDialog(async (action) => {
                if (action === validLookup.confirmationDialogAction.ok) {
    
                    const response = await CommentService.deleteComment(comment.id);
    
                    const {data, code, message} = {...response};
    
                    if( code === 0 ) {
                        const filteredComments = patientComments.filter(c => c.id !== data.id);
                        setPatientComments(filteredComments);
                        toast.success(message);
                    } else {
                        toast.info(message);
                    }
    
                    closeConfirmationDialog();
    
                }
            });
        } catch (error) {
            if(isLocalDev) {
                console.log(error);
            }

            toast.error("Failed to delete record");
        }

    }

    const handleCommentChange = (event) => {
        setCommentValue(event.target.value);
    }

    const handleCommentSave = (id) => {

        if (patientId) {

            const newComment = {
                comments: commentValue,
                PatientId: patientId,
                id: id
            };

            CommentService.addComment(newComment).then(response => {
                const { code, data, message } = { ...response };
                if (code === 0) {
                    if( id > 0 ) {
                        const existingComments = patientComments;
                        const idx = existingComments.findIndex(c => c.id === data.id);
                        existingComments[idx] = data;
                        setPatientComments(existingComments);
                    } else {
                        setPatientComments(pc => [...pc, data]);
                    }

                    setEditMode(false);
                    setCommentToEdit(null);
                } else {
                    toast.info(message);
                }
            }).catch(error => {
                if (isLocalDev) {
                    console.log(error)
                }
            });

            //setPatientComments(c => [...c, newComment]);
            setEditMode(false);
        } else {
            toast.error("No patient to associate to.");
        }
    }

    const handleCancelEdit = () => {
        setEditMode(false);
        setCommentToEdit(null);
    }

    const handleAddComment = () => {
        setCommentValue('');
        setEditMode(true);
    }

    const handlePageChange = (from, to) => {
        loadComments(from, to);
    }

    return (
        <>
            {
                editMode ?
                    <Box>
                        <Box display="flex" alignItems="center">
                            <IconButton onClick={() => setEditMode(false)}>
                                <ChevronLeftOutlined color="primary" />
                            </IconButton><Typography sx={{ color: Colors.dim_grey }}>Back to Comments</Typography>
                        </Box>
                        <TextField
                            sx={{ mb: 1 }}
                            name="comment"
                            value={commentValue}
                            onChange={handleCommentChange}
                            multiline rows={10} id="pr-comment" label="Comment" variant="standard" fullWidth />
                        {
                            commentToEdit ?
                                <Button
                                    disabled={!commentValue}
                                    variant="contained" onClick={() => handleCommentSave(commentToEdit.id)} sx={{ mr: 1 }}>Save</Button>
                                :
                                <Button
                                    disabled={!commentValue}
                                    variant="contained" onClick={() => handleCommentSave(-1)} sx={{ mr: 1 }}>Save</Button>
                        }


                        <Button variant="outlined" onClick={handleCancelEdit}>Cancel</Button>
                    </Box>
                    :
                    <Box display="flex" flexDirection="column">
                        <ButtonWithIcon
                            icon={<AddIcon />}
                            title="Add"
                            variant="contained" size="small" sx={{ alignSelf: 'end', mb: 1 }} onClickHandler={handleAddComment} />
                        {
                            patientComments.length > 0 ?
                                <Box>
                                    <TableContainer>
                                        <Table size="small" >
                                            <TableHead sx={{ background: alpha(Colors.primary, 0.5) }}>
                                                <TableRow>
                                                    <TableCell>Date</TableCell>
                                                    <TableCell>Time</TableCell>
                                                    <TableCell>Author</TableCell>
                                                    <TableCell>Description</TableCell>
                                                    <TableCell>Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    patientComments.map(comment => (
                                                        <TableRow key={comment.id}>
                                                            <TableCell>{comment.stampDate}</TableCell>
                                                            <TableCell>{comment.stampTime}</TableCell>
                                                            <TableCell>{comment.stampUser}</TableCell>
                                                            <TableCell style={{
                                                                overflow: 'hidden',
                                                                maxWidth: 460, textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                                            }}>{comment.comments}</TableCell>
                                                            <TableCell >
                                                                <IconButton onClick={() => handleCommentEdit(comment)}>
                                                                    <EditIcon />
                                                                </IconButton>

                                                                <IconButton onClick={() => handleCommentCopy(comment)}>
                                                                    <ContentCopyIcon />
                                                                </IconButton>

                                                                <IconButton onClick={() => handleCommentDelete(comment)}>
                                                                    <DeleteForeverIcon color="danger" />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <PaginationControl
                                        totalCount={totalCount}
                                        currentPageTotal={currentPageTotal}
                                        onPageChange={handlePageChange} />
                                </Box>

                                :

                                <Typography>No comments to show</Typography>
                        }
                        <ConfirmationDialogComp
                            message={"Remove this comment?"}
                            label={{
                                positive: "Yes",
                                negative: "No",
                            }}
                        />
                    </Box>

            }
        </>
    )
}