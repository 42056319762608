import { TextField, Box } from "@mui/material";
import { useFormik } from "formik";
import { ButtonWithIcon, NumberInput } from "../../../ui";
import { Search } from "@mui/icons-material";

export default function PatientSearchForm({ onSearchDone }) {

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      phone: "",
    },
    onSubmit: async (param) => {
      onSearchDone(param);
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        display={"flex"}
        justifyContent="center"
        alignItems={"center"}>
        <TextField
          name="firstname"
          value={formik.values.firstname}
          onChange={formik.handleChange}
          sx={{ padding: 1 }}
          fullWidth
          variant="standard"
          placeholder="First name"
          size="small"
        />
        <TextField
          name="lastname"
          value={formik.values.lastname}
          onChange={formik.handleChange}
          sx={{ padding: 1 }}
          fullWidth
          variant="standard"
          placeholder="Last name"
          size="small"
        />
        <NumberInput
          name="phone"
          value={formik.values.phone}
          onChange={formik.handleChange}
          sx={{ padding: 1 }}
          fullWidth
          variant="standard"
          placeholder="Phone"
          size="small"
        />
        <Box>
          {formik.touched && formik.dirty && (
            <ButtonWithIcon
              type={"submit"}
              fullWidth
              icon={<Search />}
              title="Search"
            />
          )}
        </Box>
      </Box>
    </form>
  );
}
