import { Autocomplete, Button, capitalize, debounce, IconButton, Stack, TextField, Typography, Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { PatientService } from "../../service/patientService";
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse'
import { toTitleCase } from "../../utils";
import { parsePhoneNumber } from "libphonenumber-js";
import AddIcon from '@mui/icons-material/Add';
import AddNewPatientDialog from "../patientrecord/dialog/addNewPatientDialog";
import useDialogModal from "../../hooks/useDialogModal";

export default function PatientSearch({ onPatientSelected, reset, sx }) {
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const [AddPatientDialog, showAddPatientDialog] =
        useDialogModal(AddNewPatientDialog);

    useEffect(() => {
        setOptions([]);
        setValue(null);
    }, [reset]);

    const fetchPatients = useMemo(() =>
        debounce(async (request, callback) => {
            const patientSearchResult = await PatientService.search(request);
            callback(patientSearchResult);
        }, 500), []);

    useEffect(() => {
        let active = true;
        if (inputValue === '') {
            setLoading(false);
            setOptions(value ? [value] : []);
            return undefined;
        }

        const params = {
            firstname: inputValue.split(' ').length > 1 ? inputValue.split(' ')[0] : inputValue,
            lastname: inputValue.split(' ').length > 1 ? inputValue.split(' ')[1] : null
        };
        setLoading(true);
        fetchPatients(params, (result) => {
            if (active) {
                setLoading(false);
                const { code, data, message } = result;

                let newOptions = [];
                if (code === 0) {

                    if (value) {
                        newOptions = [value];
                    }

                    if (result) {
                        newOptions = [...newOptions, ...data];
                    }
                }
                setOptions(newOptions);
            }
        });

        return () => active = false;
    }, [value, inputValue, fetchPatients]);


    const handleAutoCompleteChange = (event, newValue, reason) => {

        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);

        if (reason === 'selectOption') {
            onPatientSelected(newValue);
        }

        if (reason === 'clear') {
            onPatientSelected(null);
        }
    }

    const handleAddPatient = (patient) => {

        if( patient ) {
            setValue({
                fullName: patient.fullName,
                email: patient.email,
                phone1: patient.phone1
            });
    
            onPatientSelected(patient);
        }

    }

    return (
        <Stack display={"flex"} flexDirection="row" sx={{ ...sx }}>
            <Autocomplete
                id="patient-search-box"
                filterOptions={(x) => x}
                size="small"
                selectOnFocus
                clearOnBlur
                fullWidth
                autoComplete
                autoSelect
                includeInputInList
                filterSelectedOptions
                noOptionsText="No patients found"
                options={options}
                value={value}
                onChange={handleAutoCompleteChange}
                autoHighlight={true}
                loading={loading}
                getOptionLabel={(option) => typeof option === 'string' ? option : capitalize(option.fullName)}
                onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                renderInput={(params) => (
                    <TextField {...params} label="Search..." fullWidth />
                )}

                renderOption={(props, option, { inputValue }) => {

                    const matches = match(option.fullName, inputValue, { insideWords: true });
                    const parts = parse(option.fullName, matches);

                    return (
                        <li {...props} key={option.id}>
                            <div>
                                {parts.map((part, index) => (
                                    <Box key={index} component="span" style={{
                                        textTransform: 'capitalize',
                                        fontWeight: part.highlight ? 700 : 400
                                    }}>
                                        {part.text}
                                    </Box>
                                ))}
                                <Typography variant="body2">
                                    {option.email || 'no email'}
                                </Typography>
                                <Typography variant="body2">
                                    {parsePhoneNumber(`+1${option.phone1}`).formatNational() || option.phone2}
                                </Typography>
                            </div>
                        </li>
                    )
                }}
            />
            <IconButton onClick={() => showAddPatientDialog()}>
                <AddIcon />
            </IconButton>
            <AddPatientDialog onDone={handleAddPatient} ignoreBackdropClick={true} />
        </Stack>

    )
}