import { useRef } from "react";


/**
 * These events are fired by calendar and anyone interested in them 
 * can just register and get notified.
 * E.g. Header can go to next week or prev week
 * and events can also be notified to load proper events
 * 
 * @returns 
 */
export function useCalendarActions() {
    const listeners = useRef([]);

    const addListener = ((listener) => {
        listeners.current = listeners.current.concat(listener);
        return () => {
            listeners.current = listeners.current.filter(l => l !== listener);
        }
    });

    const onNext = () => {
        listeners.current.forEach(listener => listener('next'));
    }

    const onPrev = () => {
        listeners.current.forEach(listener => listener('prev'));
    }

    return { addListener, onNext, onPrev };
}