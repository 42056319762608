
import { styled } from "@mui/material/styles";
import { UL } from "../../../styles/calendar";
import { Colors } from "../../../styles/theme";

export const drawerWidth = 400;

export const CalenderHolder = styled('main', { shouldForwardProp: (prop) => prop !== 'drawerOpen' })(
    ({ theme, drawerOpen }) => ({
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: `-${drawerWidth}px`,
        ...(drawerOpen && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        }),
    }),
);

export const CalendarContainer = styled("div")(() => ({
    position: "relative",
    display: "grid",
    gridTemplateColumns: "80px auto",
    gridTemplateRows: "auto",
    gap: "0px 1px",
    gridTemplateAreas:
        '". header" "timeslots-container main"',
    marginTop: "8px",
    background: Colors.body_bg,
    overflowX: 'hidden'
}));

export const CalendarHeader = styled("div")(() => ({
    gridArea: "header",
}));


export const DaysContainer = styled(UL)(({ days }) => ({
    display: "grid",
    gridTemplateColumns: `repeat(${days}, 1fr)`,
    textTransform: "capitalize",
    "&::after": {
        content: '""',
        left: "0",
        position: "absolute",
        width: "100%",
        height: "2px",
        background: Colors.primary,
        //background: `linear-gradient(90deg, rgba(235,235,235,0.25) 0%, ${Styles.color.dove_gray} 25%,${Styles.color.dove_gray} 75%, rgba(235,235,235,1) 100%)`,
        zIndex: 1,
    },
}));

export const ResourceContainer = styled('div', {
    shouldForwardProp: prop => prop !== 'gridTemplateAreas'
})(({ columns, gridTemplateAreas }) => ({
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    gridTemplateRows: 'repeat(1, 1fr)',
    gridTemplateAreas: `'${gridTemplateAreas}'`,//"'d-0 d-1 d-2 d-3 d-4 d-5 d-6'",
    columnGap: 1,
    gridArea: 'header',
    padding: '0px',
    height: '40px',
}));

export const PractitionerEvents = styled('div', {
    shouldForwardProp: prop => prop !== 'gridIndex'
})(({ gridIndex, top }) => ({
    position: 'absolute',
    display: 'grid',
    gridArea: `${gridIndex}`,
    gridTemplateRows: 'repeat(96, 1fr)',
    height: '100%',
    width: '100%',
    top: `${top}px`,
    whiteSpace: 'nowrap'
}));

export const ResourceWeekContainer = styled('div')(({ practitioner }) => ({
    display: 'grid',
    position: 'relative',
    gridArea: `${practitioner}`,
    padding: '2px',
    columnGap: 1,
}));

export const WeekEventContainer = styled('div')(() => ({
    display: 'grid',
    gridTemplateColumns: `repeat(7, 1fr)`,
    gridArea: 'main',
    gridTemplateAreas: "'d-0 d-1 d-2 d-3 d-4 d-5 d-6'",
    background: Colors.muted,
    height: '40px',
}));

export const ResourceByDayContainer = styled('div', {
    shouldForwardProp: prop => prop !== 'gridTemplateAreas'
})(({ day, gridTemplateAreas, columns }) => ({

    display: 'grid',
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    gridTemplateAreas: `'${gridTemplateAreas}'`,
    gridArea: `${day}`,
    columnGap: 1,
}));