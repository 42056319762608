import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
// import ManageSchedule from './ManageSchedule';
import ManageEmployee from './ManageEmployee';
import { TabPanel, a11yProps } from '../ui';
import ManageLocation from './ManageLocation';
import ManagePractitioner from './ManagePractitioner';


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function Admin() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} >
                    {/* <Tab label="Manage Schedule" {...a11yProps('admin',0)} /> */}
                    <Tab label="Manage Practitioners" {...a11yProps('admin',0)} />
                    <Tab label="Employees" {...a11yProps('admin',1)} />
                    <Tab label="Locations" {...a11yProps('admin',2)} />
                </Tabs>
            </Box>
            {/* <TabPanel idfor='admin' value={value} index={0} >
                <ManageSchedule />
            </TabPanel> */}
            <TabPanel idfor='admin' value={value} index={0}>
                <ManagePractitioner />
            </TabPanel>
            <TabPanel idfor='admin' value={value} index={1}>
                <ManageEmployee />
            </TabPanel>
            <TabPanel idfor='admin' value={value} index={2}>
                <ManageLocation />
            </TabPanel>
        </Box>
    );
}
