import { DialogTitle, IconButton, Box } from "@mui/material";
import { Colors } from "../../styles/theme";
import CloseIcon from '@mui/icons-material/Close';

export default function AppDialogTitle({
    title,
    onClose
}) {
    return (
        <DialogTitle sx={{ background: Colors.primary, mb: 4, color: Colors.white }}>
            <Box display={"flex"} alignItems="center" justifyContent={'space-between'}>
                {title}
                <IconButton onClick={onClose} sx={{ color: Colors.white }}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </DialogTitle>
    )
}