import {
    alpha, Box, Button, IconButton, Menu, MenuItem, Popper, Stack, Table,
    TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from "@mui/material";
import { useEffect, useReducer, useState } from "react"
import { Colors } from "../../../../styles/theme";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ButtonWithMenu from "../../../common/buttonWithMenu";
import useDialogModal from "../../../../hooks/useDialogModal";
import PaginationControl from "../../../ui/paginationComponent";
import { niceDate } from "../../../../utils";
import AddContactsOrderDialog from "./addContactsOrderDialog";
import AddGlassesOrderDialog from "./addGlassesOrderDialog";
import useConfirmationPopper from "../../../../hooks/useConfirmationPopper";


const data = [{
    id: 1,
    orderNumber: 123456,
    date: '2022-02-10',
    type: 'glasses',
    dueDate: '2022-02-20',
    status: 'New',
    notified: '2022-02-15'
},
{
    id: 2,
    orderNumber: 123456,
    date: '2022-02-10',
    type: 'glasses',
    dueDate: '2022-02-20',
    status: 'Approved',
    notified: '2022-02-15'
}
]

export default function OrderTab({ patient }) {

    const [state, updateState] = useReducer((state, params) => {
        return { ...state, ...params }
    }, {
        currentPageTotal: 0,
        items: data,
        open: false,
        selectedItem: null,
        anchorEl: null
    });
    const [ConfirmationPopper, showConfirmationPopper] = useConfirmationPopper();

    const [ContactOrderDialog, showContactOrderDialog] =
        useDialogModal(AddContactsOrderDialog);
    const [GlassesOrderDialog, showGlassesOrderDialog] =
        useDialogModal(AddGlassesOrderDialog);

    useEffect(() => {
        updateState({ currentPageTotal: state.items.length });
    }, [patient]);

    const handleAddGlassesOrder = () => {
        showGlassesOrderDialog();
    }

    const handleAddContactsOrder = () => {
        showContactOrderDialog();
    }

    const handleRxEdit = (type) => {
        if (type === 'glasses') {
            showGlassesOrderDialog(null, {
                rowid: 1
            });
        } else if (type === 'contacts') {
            showContactOrderDialog();
        }
    }

    const handlePageChange = () => {

    }

    const handleDeleteClicked = (element, row) => {
        showConfirmationPopper(element.currentTarget, () => {
            let fd = state.items.filter(d => d.id !== row.id);
            updateState({ selectedItem: null, items: fd });
        });
    }

    const handleAddFrameOrder = () => {

    }

    return (
        <Box display="flex" flexDirection="column">
            <Box sx={{ alignSelf: 'end', mb: 1 }}>
                <ButtonWithMenu
                    title={'Add'}
                    menuItems={[{
                        name: 'Glass Order',
                        handler: handleAddGlassesOrder
                    },
                    {
                        name: 'Contact Order',
                        handler: handleAddContactsOrder
                    },
                    {
                        name: 'Frame Only',
                        handler: handleAddFrameOrder
                    }
                    ]} />
            </Box>

            {/* <ButtonWithIcon
                icon={<AddIcon />}
                title="Add new"
                variant="contained" size="small" sx={{ alignSelf: 'end', mb: 1 }} onClickHandler={() => { }} /> */}
            <TableContainer>
                <Table size="small" >
                    <TableHead sx={{ background: alpha(Colors.primary, 0.5) }}>
                        <TableRow>
                            <TableCell>Order #</TableCell>
                            <TableCell>Order Date</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Due Date</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Notified</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            state.items.map(row => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.orderNumber}</TableCell>
                                    <TableCell>{niceDate(row.date)}</TableCell>
                                    <TableCell>{row.type}</TableCell>
                                    <TableCell>{niceDate(row.dueDate)}</TableCell>
                                    <TableCell>{row.status}</TableCell>
                                    <TableCell>{niceDate(row.notified)}</TableCell>
                                    <TableCell>
                                        <Box display="flex">
                                            <IconButton onClick={() => handleRxEdit(row.type)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={(event) => handleDeleteClicked(event, row)}>
                                                <DeleteForeverIcon color="danger" />
                                            </IconButton>

                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))
                        }


                    </TableBody>
                </Table>
            </TableContainer>
            <PaginationControl
                totalCount={state.items.length}
                currentPageTotal={state.currentPageTotal}
                onPageChange={handlePageChange} />
            <ContactOrderDialog ignoreBackdropClick={true} />
            <GlassesOrderDialog ignoreBackdropClick={true} />
            <ConfirmationPopper />
        </Box>
    )
}