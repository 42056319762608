import { useCallback, useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

export default function useList(DataSource, props) {
    const { fullWidth, paramList } = { ...props };

    if (!paramList) {
        throw new Error('You must provie a menuItem term. E.g. used internally in <MenuItem...>');
    }

    const [data, setData] = useState([]);
    const [value, setValue] = useState('');

    useEffect(() => {
        DataSource().then(result => {
            if (result.code === 0) {
                setData(result.data);
            }
        }).catch(error => console.log(error));

    }, []);

    const handleChange = (event) => {
        setValue(event.target.value);
    }

    const ListComponent = ({ sx, label }) => {
        return (
            <FormControl fullWidth={fullWidth} size="small" sx={{
                minWidth: "200px",
                ...sx
            }}>
                <InputLabel id={`${label}-select-label`}>{label || 'Please select'}</InputLabel>
                <Select
                    labelId={`${label}-select-label`}
                    id={`${label}-select`}
                    value={value}
                    label={`${label}`}
                    onChange={handleChange}
                >

                    {data.map((d) => (
                        <MenuItem key={d.id} value={d.id} sx={{
                            textTransform: 'capitalize'
                        }}>{paramList.map(p => d[p] + ' ')}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    return [ListComponent, value, setValue]
}

