import request from "./httpService";


const save = (data) => {
    return request({
        method: 'POST',
        url: '/schedule/new',
        data: data
    });
}

const update = (data) => {
    return request({
        method: 'POST',
        url: '/schedule/update',
        data: data
    });
}

const deleteEvent = (data) => {
    return request({
        method: 'DELETE',
        url: `/schedule`,
        data: data
    });
}

const getSchedule = (param) => {
    return request({
        method: "GET",
        url: '/schedule/byparam',
        params: param
    });
}

const getScheduleByLocation = (param) => {
    return request({
        method: "GET",
        url: '/schedule/practitioners',
        params: param
    });
}

const mySchedule = (param) => {
    return request({
        method: "GET",
        url: '/schedule/myschedule',
        params: param
    });
}

const getOpenSlots = (param) => {
    return request({
        method: "GET",
        url: '/schedule/slots/open',
        params: param
    });
}

const getOpenSlotsV2 = (param) => {
    return request({
        method: "GET",
        url: '/schedule/slots/openv2',
        params: param
    });
}

export const ScheduleService = {
    getSchedule,
    getScheduleByLocation,
    save,
    update,
    deleteEvent,
    getOpenSlots,
    getOpenSlotsV2,
    mySchedule
};