import { parse } from "date-fns";
import { appSettings } from "../../../data";
import { timeFromString } from "../../../utils";
/**
 * Given a start date time will return the cell position in a column
 * You can also pass HH:MM
 * @param {date} start
 */
export function getCell(start) {
  let h = 0;
  if (typeof start === "string") {
    h = start.split(":")[0];
  } else {
    h = start.getHours();
  }
  return +h - appSettings.businessStartHour;
}

/**
 * given a start date returns the column position
 *
 * input: startdate (yy-mm-dd)
 */
export function getColumnPosition(date) {
  const result = parse(date, "yyyy-MM-dd", new Date());

  //const y = +startdate.split("-")[0];
  //const m = +startdate.split("-")[1];
  //const d = +startdate.split("-")[2];

  //const date = new Date(y, m - 1, d);
  return result.getDay() + 1;
}

/**
 * given start and end times will return the height in px
 *
 * start: x:xx
 * end: x:xx
 */
export function getHeight(starttime, endtime) {
  const starthour = starttime.split(":")[0];
  const startmin = starttime.split(":")[1];
  const endhour = endtime.split(":")[0];
  const endmin = endtime.split(":")[1];

  var datestart = new Date();
  var dateend = new Date();
  datestart.setHours(parseInt(starthour));
  datestart.setMinutes(parseInt(startmin));

  dateend.setHours(parseInt(endhour));
  dateend.setMinutes(parseInt(endmin));

  var duration = Math.abs(datestart.valueOf() - dateend.valueOf()) / 1000;
  return duration;
}

/**
 * given start time returns the row position
 *
 * input: starttime (x:xx)
 */
export function getRowPosition(starttime) {
  const h = +starttime.split(":")[0];
  const m = +starttime.split(":")[1];
  const totalMinutes = Math.abs(appSettings.businessStartHour - h) * 60 + m;
  const rowPos = totalMinutes / appSettings.timeSlotInterval + 1;

  return rowPos;
}

/**
 * given start time returns the row position
 *
 * input: starttime (x:xx)
 */
export function getSlotPosition(starttime) {
  const h = +starttime.split(":")[0];  
  return Math.abs(appSettings.businessStartHour - h);  
}

/**
 * sort by starttime
 */
export function sortcomparer(e1, e2) {
  const t1start = timeFromString(e1.starttime);
  //const t1end = timeFromString(e1.endtime);
  const t2start = timeFromString(e2.starttime);
  //const t2end = timeFromString(e2.endtime);

  //const t1 = +(t1end - t1start);
  //const t2 = +(t2end - t2start);

  return t1start - t2start;
  
}

export function sortcomparerByDuration(e1, e2) {
  const t1start = timeFromString(e1.starttime);
  const t1end = timeFromString(e1.endtime);
  const t2start = timeFromString(e2.starttime);
  const t2end = timeFromString(e2.endtime);

  const t1 = +(t1end - t1start);
  const t2 = +(t2end - t2start);

  return t2 - t1;
  
}



