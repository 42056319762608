import { Button, Divider, Drawer, IconButton, MenuItem, Select, TextField, Typography, Box } from "@mui/material"
import { DrawerHeader } from "../../styles/drawer"
import { Colors } from "../../styles/theme"
import { niceDate, niceTime, toTitleCase } from "../../utils"
import validLookup from "../../utils/validLookup"
import { ChevronRightOutlined } from "@mui/icons-material";
import { drawerWidth } from "./styles"
import { useEffect, useReducer, useState } from "react"

export default function AppointmentDrawer({ drawerOpen,
    activeSlot,
    handleSlotMove,
    handleSlotUpdate,
    handleSlotCancel,
    handleDrawerClose }) {
    const [cancel, setCancel] = useState(false);

    const [appointmentData, updateAppointmentData] = useReducer((prev, next) => {
        return {...prev, ...next}
    }, {
        status: '',
        notes: ''
    });

    useEffect(() => {
        setCancel(false);

        if (activeSlot) {
            console.log(activeSlot.status)
            updateAppointmentData({status: activeSlot.status, notes: activeSlot.notes});
        }
    }, [drawerOpen, activeSlot]);

    const handleStatusChange = (event) => {
        updateAppointmentData({status: event.target.value});
    }

    const handleNoteChange = (event) => {
        updateAppointmentData({notes: event.target.value});
    };

    const handleUpdate = () => {
        const param = {
            status: appointmentData.status,
            notes: appointmentData.notes
        }

        handleSlotUpdate(param);
    }
    return (
        <Drawer
            PaperProps={{
                elevation: 8
            }}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant="persistent"
            anchor="right"
            open={drawerOpen}
        >
            <DrawerHeader sx={{
                display: 'flex',
                justifyContent: 'flex-start'
            }}>
                <IconButton
                    sx={{ color: Colors.white }}
                    onClick={handleDrawerClose}>
                    <ChevronRightOutlined />
                </IconButton>

                <Typography fontSize={"2rem"} sx={{ color: Colors.white }}>Appointment</Typography>
            </DrawerHeader>
            <Box sx={{ p: 1 }}>
                {activeSlot && drawerOpen &&
                    <>
                        <Select
                            disabled={activeSlot.status === validLookup.validAppointmentStatus.paid
                                || activeSlot.status === validLookup.validAppointmentStatus.completed}
                            fullWidth size="small" value={appointmentData.status} onChange={handleStatusChange}>
                            {
                                Object.keys(validLookup.validAppointmentStatus).map(vas => {
                                    return (
                                        <MenuItem key={vas} value={vas}>{toTitleCase(vas)}</MenuItem>
                                    )
                                })
                            }

                        </Select>
                        <Divider sx={{ mt: 2, mb: 2 }} />
                        <Typography>{toTitleCase(activeSlot.Patient.fullName)}</Typography>
                        <Typography>{activeSlot.Patient.phone1 || activeSlot.Patient.phone2 || activeSlot.Patient.phone3}</Typography>
                        <Typography>{activeSlot.Patient.email || 'no email'}</Typography>
                        <Divider sx={{ mt: 2, mb: 2 }} />
                        <Typography>{niceDate(activeSlot.startDate)}</Typography>
                        <Typography>{niceTime(activeSlot.startDate)} - {niceTime(activeSlot.endDate)}</Typography>
                        <TextField multiline
                            sx={{mt: 2}}
                            value={appointmentData.notes || ''}
                            onChange={handleNoteChange}
                            rows={5} fullWidth variant="standard" label="Notes" />
                        <Divider sx={{ mt: 2, mb: 2 }} />
                        <Box display={"flex"} flexDirection={'column'} sx={{ mt: 2 }}>
                            {
                                cancel ? <>

                                    <Typography sx={{ flexGrow: 1 }}>Please confirm if you want to cancel this appointment?</Typography>
                                    <Box display={"flex"} sx={{ pr: 2, width: '100%' }} justifyContent="flex-end" alignContent={'center'}>
                                        <Button
                                            onClick={() => setCancel(false)}>No</Button>
                                        <Button
                                            sx={{ mb: 2 }}
                                            variant="contained"
                                            onClick={handleSlotCancel}>Confirm</Button>

                                    </Box>

                                </> :

                                    <>
                                        <Button
                                            disabled={(appointmentData.status === activeSlot.status && appointmentData.notes === activeSlot.notes)}
                                            sx={{ mb: 2 }}
                                            variant="contained"
                                            onClick={handleUpdate}>Update</Button>
                                        <Button
                                            sx={{ mb: 2 }}
                                            variant="contained"
                                            color="secondary"
                                            disabled={activeSlot.status === validLookup.validAppointmentStatus.paid
                                                || activeSlot.status === validLookup.validAppointmentStatus.completed}
                                            onClick={handleSlotMove}>Move</Button>
                                        <Button
                                            disabled={activeSlot.status === validLookup.validAppointmentStatus.paid
                                                || activeSlot.status === validLookup.validAppointmentStatus.completed}
                                            variant="contained"
                                            color="danger"
                                            onClick={() => setCancel(true)}>Cancel Appiontment</Button>
                                    </>
                            }


                        </Box>

                    </>}
            </Box>


        </Drawer>
    )
}