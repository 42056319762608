import { CalendarHeader } from "../../styles/calendar";
import Header, { WeekHeader } from "../calendar/header";
import { Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Slot from "./slot";
import { ResourceByDayContainer, ResourceWeekContainer, WeekEventContainer } from "./styles";
import { getTimeString } from "../../utils";


export default function WeekView({ collides, activeSlot, draggedSlot, activePractitioners, onSlotClicked, tick }) {
    const [practitionerByDay, setPractitionerByDay] = useState(new Map());
    const [gridTemplateArea, setGridTemplateArea] = useState(new Map());
    const [loading, setLoading] = useState(true);

    const daysInAWeek = [0, 1, 2, 3, 4, 5, 6];
    /**
     * for each day how many doctors are working
     * and the slots for each doctor for that day
     * 
     * doctors / day
     * slots / doctor / day
     */
    useEffect(() => {
        const ppd = new Map();
        const gta = new Map();

        activePractitioners.forEach(p =>
            p.slots.forEach(s => {
                
                if (daysInAWeek.indexOf(s.dayOfWeek) !== -1) {
                    if (ppd.has(s.dayOfWeek)) {
                        const obj = {
                            practitioner: {
                                id: p.id,
                                name: p.fullName
                            },
                            slots: [s]
                        };
                        ppd.get(s.dayOfWeek).push(obj);

                        if (gta.get(s.dayOfWeek).indexOf(p.id) === -1) {
                            gta.get(s.dayOfWeek).push(p.id);
                        }
                    } else {
                        const obj = {
                            practitioner: {
                                id: p.id,
                                name: p.fullName
                            },
                            slots: [s]
                        };
                        ppd.set(s.dayOfWeek, [obj]);
                        gta.set(s.dayOfWeek, [p.id]);
                    }
                }
            })
        );
        setLoading(false);
        setPractitionerByDay(ppd);
        setGridTemplateArea(gta);
    }, [tick]);

    //data.practitioners.map(ap => 'ap-' + ap.id).join(' ')
    return (
        <>
            <CalendarHeader>
                <Header days={7} render={data => (<WeekHeader />)} />
                {loading && <Typography variant="h3">loading</Typography>}
                {activePractitioners.length > 0 && <WeekEventContainer>
                    {
                        !loading &&
                        daysInAWeek.map(d => (
                            practitionerByDay.has(d) &&
                            <ResourceByDayContainer
                                key={d}
                                columns={gridTemplateArea.get(d).length}
                                gridTemplateAreas={gridTemplateArea.get(d).map(p => 'ap-' + p).join(' ')}
                                day={`d-${d}`}>
                                {
                                    practitionerByDay.get(d).map((p, i) => (
                                        <ResourceWeekContainer
                                            key={i}
                                            practitioner={`ap-${p.practitioner.id}`}>
                                            <Typography>
                                                {p.practitioner.name}
                                            </Typography>
                                            {
                                                p.slots.map(s => (
                                                    <Slot
                                                    collides={collides}
                                                        activeSlot={activeSlot}
                                                        draggedSlot={draggedSlot}
                                                        practitionerid={p.practitioner.id}
                                                        id={s.id}
                                                        offset={40}
                                                        key={p.practitioner.id}
                                                        start={getTimeString(s.startDate)}
                                                        end={getTimeString(s.endDate)}
                                                        name={(s.Patient && s.Patient.fullName) || ''}
                                                        status={s.status}
                                                        onClick={() => onSlotClicked(s)}
                                                    />

                                                ))
                                            }
                                        </ResourceWeekContainer>
                                    ))}
                            </ResourceByDayContainer>
                        ))
                    }

                </WeekEventContainer>}
            </CalendarHeader>

        </>

    )
}

/**
 * -------------------------------------------------------------------------------------------------------
 *     sun     |    mon       |    tue       |     wed      |     thu      |     fri      |    sat       |
 * -------------------------------------------------------------------------------------------------------
 * doc1 | doc2 |   doc 1      | 
 * ------------------------------------------------------------------------------------
 */