import { Box, CssBaseline } from "@mui/material";
import { Outlet } from "react-router-dom";
import AppBar from "./appbar";
import Appdrawer from "./drawer";
import { useUIContext } from "../state/ui";
import { DrawerHeader, Header } from "../styles/drawer";
import { Main } from "../styles/main";

export default function Layout() {
  const { drawerOpen } = useUIContext();
  return (
    <Box>
      <CssBaseline />

      <AppBar />
      <Appdrawer />

      <Main open={drawerOpen}>
        <Header />
        <Outlet />
      </Main>
    </Box>
  );
}
