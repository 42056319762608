
const validLookup = {
  validRxType: {
    glasses: 'glasses',
    contacts: 'contacts'
  },

  validStatus: {
    active: "active",
    inactive: "inactive",
  },

  validRole: {
    sales: "sales",
    manager: "manager",
    customerservice: "customerService",
    practitioner: "practitioner",
  },

  validSlotStatus: {
    available: 'available',
    vacation: "vacation",
    break: "break",
    cancelled: 'cancelled',
  },

  validAppointmentStatus: {
    booked: "booked",
    // confirmed: 'confirmed',
    completed: 'completed',
    noshow: 'noshow',
    // cancelled: 'cancelled',
    arrived: 'arrived',
    paid: 'paid',
  },

  confirmationDialogAction: {
    ok: 'ok',
    cancel: 'cancel',
  }
};

export default validLookup;