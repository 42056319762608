import { Chip, Typography, Box} from "@mui/material";
import { ZIndex } from "../../styles/constants/enums";
import { Colors } from "../../styles/theme";


export default function TimeIndicatorBar({timebarTime, timebarRef, coords}) {
    return (
        <>
            <Box
                ref={timebarRef}
                sx={{
                    position: 'absolute',
                    gridArea: 'main',
                    width: '100%',
                    left: '0px',
                    transform: coords.y >= 0 ? `translate3d(0, ${coords.y}px, 0)` : undefined,
                    border: `1px solid ${Colors.info}`,
                    height: '2px',
                }}>
            </Box>
            <Chip
                label={<Typography textAlign={"left"} fontSize={"0.85rem"}>{timebarTime}</Typography>}
                color="info"
                sx={{
                    color: Colors.white,
                    position: 'absolute',
                    gridArea: 'timeslots-container',
                    left: '0px',
                    transform: coords.y >= 0 ? `translate3d(0, ${coords.y}px, 0)` : undefined,
                    zIndex: ZIndex.TOP
                }}>

            </Chip>
        </>
    )
}