import { Label } from "@mui/icons-material";
import { alpha, Box, Button, Dialog, DialogActions, DialogContent, FormControl, FormLabel, Grid, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { addYears } from "date-fns";
import { useFormik } from "formik";
import { useEffect, useReducer } from "react";
import { Colors } from "../../../../styles/theme";
import { dateFromString } from "../../../../utils";
import { SelectPractioner } from "../../../common";
import AppDialogTitle from "../../../ui/appDialogTitle";
import DatePickerComponent from "../../../ui/datePickerComponent";
import { contactLensModel } from "../../model/contactLensModel";
import { glassLensModel } from "../../model/glassesLensModel";
import GlassesRxTable from "./glassesRxTable";


export default function AddGlassesRxDialog({ onClose, open, rowid }) {

    const [state, updateState] = useReducer((state, params) => {
        return { ...state, ...params };
    }, {
        startDate: new Date(),
        endDate: addYears(Date.now(), 1)
    });

    // if we pass in a rx id then pull info for that one
    useEffect(() => {
        if (rowid) {
            formik.setValues({
                practitioner: 1,
                manufacturer: 1,
                model: 1,
            });
            updateState({
                startDate: '2023-02-10',
                endDate: '2024-02-10'
            });
        }
    }, [rowid]);

    const formik = useFormik({
        initialValues: { ...glassLensModel },
        onSubmit: (data) => {
            console.log(data);
        }
    });

    const handleStartDataSelect = (date) => {
        const dt = dateFromString(date);
        updateState({
            startDate: date,
            endDate: addYears(dt, 1)
        })
    }

    const handleSave = () => {
        console.log('save');
    }

    return (
        <Dialog onClose={onClose} open={open} fullWidth maxWidth={"xl"} >
            <AppDialogTitle onClose={onClose} title="New Glasses Rx" />
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>

                    <Grid container columnGap={1} sx={{ mb: 1 }}>
                        <Grid item sx={{ background: '#f6f6f6', p: 1 }}>
                            <Box display="flex">
                                <Stack>
                                    <SelectPractioner
                                        sx={{ mb: 2 }}
                                        setPractitioner={(practitioner) => formik.getFieldHelpers('practitioner').setValue(practitioner)}
                                        practitioner={formik.getFieldProps('practitioner').value} />

                                    <FormControl sx={{ mb: 2 }}>
                                        <FormLabel>Start Date</FormLabel>
                                        <DatePickerComponent value={state.startDate} onDateSelectionDone={handleStartDataSelect} name="date-start" id="schedule-date-start" />
                                    </FormControl>

                                    <FormControl sx={{ mb: 2 }}>
                                        <FormLabel>Expiry Date</FormLabel>
                                        <DatePickerComponent value={state.endDate} onDateSelectionDone={(date) => updateState({ endDate: date })} name="date-start" id="schedule-date-start" />
                                    </FormControl>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item sx={{ background: '#f6f6f6', p: 1, minWidth: '200px' }}>
                            <Box sx={{ mb: 1 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="manufacturer-select-label">Manufacturer</InputLabel>
                                    <Select
                                        size="small"
                                        labelId="manufacturer-select-label"
                                        label="Manufacturer"
                                        name="manufacturer"
                                        onChange={formik.handleChange}
                                        value={formik.values.manufacturer}
                                        fullWidth>
                                        <MenuItem value="1">Johnson & Johnson vision</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl fullWidth>
                                    <InputLabel id="model-select-label">Model</InputLabel>
                                    <Select size="small"
                                        labelId="model-select-label"
                                        label="Model"
                                        name="model"
                                        onChange={formik.handleChange}
                                        value={formik.values.model} fullWidth>
                                        <MenuItem value="1">1-Day Acuvue Define</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>

                    </Grid>

                    <GlassesRxTable formik={formik} />

                    <TextField
                        sx={{ mb: 1 }}
                        name="comment"
                        onChange={formik.handleChange}
                        value={formik.values.comment}
                        multiline rows={4} id="pr-comment" label="Comment" variant="standard" fullWidth />

                </DialogContent >
                <DialogActions>
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                    <Button color="primary" variant="contained" type="submit" autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog >
    )
}