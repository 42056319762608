import { IconButton, TextField } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import DateRangeIcon from '@mui/icons-material/DateRange';
import DatePickerDialog from "./datePickerDialog";
import useDialogModal from "../../hooks/useDialogModal";
import { dateFromString, getDateStringNonISO } from "../../utils";

export default function DatePickerComponent({ name, id, value, onDateSelectionDone }) {

    const [DatePicker, showDatePicker] =
        useDialogModal(DatePickerDialog);

    const [dateValue, setDateValue] = useState('');
    const [internalDateValue, setInternalDateValue] = useState('');

    useEffect(() => {

        if (value) {
            if (value instanceof Date) {
                setInternalDateValue(value);
                setDateValue(getDateStringNonISO(value));
            } else {
                setInternalDateValue(dateFromString(value));
                setDateValue(value);
            }

        }
    }, [value]);

    const handleDatePickerDone = (value) => {
        const dv = format(value, 'yyyy-MM-dd');
        setDateValue(dv)
        onDateSelectionDone(dv);
    };
    return (
        <>
            <TextField
                name={name}
                id={id}
                value={dateValue}
                variant="standard"
                fullWidth
                type={"date"}
                InputProps={{
                    endAdornment: <IconButton onClick={showDatePicker}>
                        <DateRangeIcon color="primary" />
                    </IconButton>
                }}
            />
            <DatePicker date={internalDateValue} onDone={handleDatePickerDone} />
        </>

    )

}