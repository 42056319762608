import { FormControl, MenuItem, Select } from "@mui/material";
import { appSettings } from "../../../data";
import { getTimeRange } from "../../../utils";


export const StartTimeControl = ({handleStartTimeChange,starttime, disabled}) => {
    return (
        <FormControl fullWidth sx={{p: 1}}>
        <Select
          labelId="starttime-select-label"
          id="starttime-select"
          value={starttime}
          label="starttime"
          variant="standard"
          disabled={!disabled}
          onChange={handleStartTimeChange}
        >
          {getTimeRange(appSettings.businessStartHour,
            appSettings.businessClosingHour,
            appSettings.timeSlotInterval, null, null, [], true).map((t, i) => (
              <MenuItem key={i} value={t.time}>{t.display}</MenuItem>
            ))}
        </Select>
      </FormControl>
    );
}