import {keyframes} from '@mui/material'
import { Colors } from '../styles/theme';

export const pulseAnimation = keyframes(`
  0% {
    background-color: ${Colors.danger};
  }
  100% {
    background-color: ${Colors.primary};
  }

`);