import { MenuItem, Pagination, Select, Typography, Box} from "@mui/material";
import { useEffect, useState } from "react";
import { Colors } from "../../styles/theme";

export const initialPageSize = 10;

export const withPagination = (Component) => {
    return props => {
        const [pageSize, setPageSize] = useState(initialPageSize);
        const [pagination, setPagination] = useState({
            from: 0,
            to: pageSize
        });

        useEffect(() => {
            props.onPageChange(pagination.from, pagination.to);
        }, [pagination.from, pagination.to]);

        const handlePageSizeChange = (event) => {
            const newPageSize = event.target.value;
            setPageSize(newPageSize);
            setPagination({ from: 0, to: newPageSize });
        }

        const handlePageChange = (event, page) => {
            const from = (page - 1) * pageSize;
            //const to = (page - 1) * pageSize + pageSize;

            setPagination({ from: from, to: pageSize });
        }

        return (
            <>
                <Component {...props} />
                {
                    !!props.totalCount &&
                    <Box display="flex"
                        flexDirection="row"
                        alignItems="center"
                        sx={{ mt: 1 }} >
                        {props.currentPageTotal > 0 &&
                            <Typography variant="subtitle" whiteSpace="nowrap" sx={{ color: Colors.light }}>Total records:
                                {pagination.from + 1}-{pagination.from + props.currentPageTotal} of {props.totalCount}</Typography>
                        }

                        <Box display="flex" flexDirection="row" alignItems="center" sx={{ width: '100%' }}
                            justifyContent="flex-end">
                            <Typography variant="subtitle" sx={{ mr: 1, color: Colors.light }}>Show</Typography>
                            <Select size="small" value={pageSize} onChange={handlePageSizeChange} >
                                <MenuItem value={initialPageSize}>{initialPageSize}</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                            <Pagination
                                count={Math.ceil(props.totalCount / pageSize)} onChange={handlePageChange} showFirstButton showLastButton />
                        </Box>

                    </Box>
                }

            </>)

    }
}