import { Dialog, DialogContent } from "@mui/material";
import AppDialogTitle from "../../../ui/appDialogTitle";
import SearchPatient from "../../searchPatient";

export default function SearchPatientDialog({ open, onClose, onDone }) {

  const handlePatientSelect = (patient) => {
    onDone(patient);
    onClose();
  };

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth={"xl"}>
      <AppDialogTitle title="Search patient" onClose={onClose} />
      <DialogContent
        sx={{
          minHeight: {
            xs: "400px",
            sm: "800px",
          },
        }}
      >
        <SearchPatient onDone={handlePatientSelect} />
      </DialogContent>
    </Dialog>
  );
}
