import { Colors } from "../../../styles/theme";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, Grid } from "@mui/material";
import GeneralInfoCard from "./generalInfoCard";
import AddressInfoCard from "./addressInfoCard";
import AdditionalInfoCard from "./additionalInfoCard";
import CommentsCard from "./commentsCard";
import { useFormik } from "formik";
import "yup-phone-lite";
import { PatientService } from "../../../service/patientService";
import { UI_ACTION_TYPE, useUIContext } from "../../../state/ui";
import { isLocalDev } from "../../../utils/environment";
import { toast } from "react-toastify";
import AppDialogTitle from "../../ui/appDialogTitle";
import { patientModel } from "../model/profileModel";
import { validationSchema } from "../model/validationSchema";

export default function AddNewPatientDialog({
    open,
    onClose,
    onDone,
    familyId    
}) {

    const { dispatch } = useUIContext();

    // for the phonenumber parsing have to add country code e.g. +1 for US/CAD
    // parsePhoneNumber('+14164070024').format('NATIONAL')
    const formik = useFormik({
        initialValues: {
            ...patientModel
        },
        initialStatus: 'start',
        validationSchema: validationSchema,
        onSubmit: async (formData) => {

            dispatch({loading: true, type: UI_ACTION_TYPE.LOADING,loadingtext: "Creating record...Please wait" });
            try {
                const patientServiceResponse = await PatientService.add(formData);
                const {
                    code,
                    data,
                    message
                } = {...patientServiceResponse};
                dispatch({loading: false, type: UI_ACTION_TYPE.LOADING});
                if( code === 0 ) {
                    toast.success(message);
                    onClose();
                    onDone(data)
                }
                else {
                    toast.error(message);
                }
            } catch (error) {
                if( isLocalDev )
                    console.log(error);

                dispatch({loading: false, type: UI_ACTION_TYPE.LOADING});
                toast.error(error.data.message);

            }
        }
    });


    return (
        <Dialog onClose={onClose} open={open} fullWidth maxWidth={'xl'} >
            <AppDialogTitle title="Add new patient" onClose={onClose} />
            <form onSubmit={formik.handleSubmit}>
                <DialogContent sx={{ background: Colors.dove_gray }}>

                    <Grid container pt={2} columnSpacing={1} rowSpacing={1}>
                        <Grid item xs={12} sm={6}>
                            <GeneralInfoCard formik={formik} values={formik.values}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <AdditionalInfoCard formik={formik} />

                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <AddressInfoCard formik={formik} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CommentsCard formik={formik} />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    {//formik.isSubmitting ? <h1>Saving</h1> :
                        <>
                            <Button disabled={!formik.isValid || !formik.dirty} autoFocus type="submit">
                                Create
                            </Button>
                            <Button onClick={onClose} sx={{ color: Colors.muted }}>
                                Cancel
                            </Button>
                        </>
                    }

                </DialogActions>
            </form>

        </Dialog >
    )
}