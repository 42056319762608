
import {styled} from "@mui/material/styles";
import { Button, TableCell, TextField, Typography, Box } from "@mui/material";
import { Colors } from "../../styles/theme";

////////////////////////////////////////////////////////////////////////
//                  Button
////////////////////////////////////////////////////////////////////////
export const ButtonWithIcon = ({icon, title, onClickHandler, sx, type}) => {
    return (
        <Button 
        type={type || 'button'}
        startIcon={icon}
        onClick={onClickHandler}
        variant="contained" sx={{...sx, color: `${Colors.white}`}}>{title}</Button>
    );
}

////////////////////////////////////////////////////////////////////////
//                  Input field
////////////////////////////////////////////////////////////////////////
const NumberInputStyle = styled(TextField)(() => ({
    
    'input::-webkit-outer-spin-button': {
        display: 'none'
    },
    'input::-webkit-inner-spin-button': {
        display: 'none'
    }
}));

export const NumberInput = (props) => {
    return (
        <NumberInputStyle type={"number"} {...props}/>
    );
}

////////////////////////////////////////////////////////////////////////
//                  Table
////////////////////////////////////////////////////////////////////////
export const TableHeaderCell = styled(TableCell)(() => ({
    '&.MuiTableCell-head': {
        background: Colors.primary,
        color: Colors.white,
        fontSize: '1rem',
    }
}));


////////////////////////////////////////////////////////////////////////
//                  Tab panel
////////////////////////////////////////////////////////////////////////
export function TabPanel(props) {
    const { idfor, children, value, index,background, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`${idfor}-tabpanel-${index}`}
            aria-labelledby={`${idfor}-tab-${index}`}
            
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, background: background || Colors.white }}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export function a11yProps(forid, index) {
    return {
        id: `${forid}-tab-${index}`,
        'aria-controls': `${forid}-tabpanel-${index}`,
    };
}