import { Button, Grid } from "@mui/material";
import { useFormik } from "formik";
import { AsYouType } from 'libphonenumber-js';
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PatientService } from "../../../service/patientService";
import { UI_ACTION_TYPE, useUIContext } from "../../../state/ui";
import { isLocalDev } from "../../../utils/environment";
import AdditionalInfoCard from "../../patientrecord/dialog/additionalInfoCard";
import AddressInfoCard from "../../patientrecord/dialog/addressInfoCard";
import CommentsCard from "../../patientrecord/dialog/commentsCard";
import GeneralInfoCard from "../../patientrecord/dialog/generalInfoCard";
import { patientModel } from "../../patientrecord/model/profileModel";
import { validationSchema } from "../../patientrecord/model/validationSchema";
import SearchPatient from "../../patientrecord/searchPatient";

export default function ContactInfo({ contact, contactInfo, setContactInfo }) {

    const [selectedPatient, setSelectedPaitient] = useState(null);
    const { dispatch } = useUIContext();
    const [singleCol, setSingleCol] = useState(false);

    const formik = useFormik({
        initialValues: {
            ...patientModel
        },
        initialStatus: 'start',
        validationSchema: validationSchema,
        onSubmit: async (formData) => {

            dispatch({ loading: true, type: UI_ACTION_TYPE.LOADING, loadingtext: "Creating record...Please wait" });
            try {
                const patientServiceResponse = await PatientService.add(formData);
                const {
                    code,
                    data,
                    message
                } = { ...patientServiceResponse };
                dispatch({ loading: false, type: UI_ACTION_TYPE.LOADING });
                if (code === 0) {
                    toast.success(message);
                    setContactInfo(data);
                }
                else {
                    toast.error(message);
                }
            } catch (error) {
                if (isLocalDev)
                    console.log(error);

                dispatch({ loading: false, type: UI_ACTION_TYPE.LOADING });
                toast.error(error.data.message);

            }
        }
    });

    useEffect(() => {
        if (contact) {
            formik.setValues(contact);
            setSingleCol(true);
        }
    }, [contact]);

    const handleFirstName = (event) => {
        setContactInfo({
            ...contactInfo,
            firstname: event.target.value
        });
    }
    const handleLastName = (event) => {
        setContactInfo({
            ...contactInfo,
            lastname: event.target.value
        });
    }
    const handlePhone = (event) => {
        setContactInfo({
            ...contactInfo,
            phone: new AsYouType('US').input(event.target.value)
        });
    }

    const handleSearchDone = (result) => {
        setSelectedPaitient(result);
        formik.setValues(result);
    }

    const handlePatientSelect = () => {
        setContactInfo(selectedPatient);
    }

    return (
        <Grid container pt={1} columnSpacing={1} rowSpacing={0}>
            {
                !singleCol &&
                <Grid item xs={singleCol ? 0 : 6}>
                    <Grid container rowSpacing={1}>
                        <Grid item xs={12}>
                            <SearchPatient onDone={handleSearchDone} />
                        </Grid>
                    </Grid>
                </Grid>
            }

            <Grid item xs={singleCol ? 12 : 6}>
                <Grid container rowSpacing={1}>
                    <Grid item xs={12}>
                        <GeneralInfoCard formik={formik} />
                    </Grid>
                    <Grid item xs={12}>
                        <AdditionalInfoCard formik={formik} />
                    </Grid>

                    <Grid item xs={12}>
                        <AddressInfoCard formik={formik} />
                    </Grid>
                    <Grid item xs={12}>
                        <CommentsCard formik={formik} />
                    </Grid>
                    {
                        !contact &&
                        <Grid item xs={12} alignItems="end">
                            {
                                selectedPatient ?
                                    <Button
                                        disabled={!formik.isValid || !formik.dirty}
                                        autoFocus
                                        variant="contained"
                                        type="button"
                                        onClick={handlePatientSelect}
                                    >
                                        Select
                                    </Button> :
                                    <Button
                                        disabled={!formik.isValid || !formik.dirty}
                                        autoFocus
                                        variant="contained"
                                        type="button"
                                        onClick={() => formik.submitForm()}
                                    >
                                        Create
                                    </Button>
                            }
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}