import { Label } from "@mui/icons-material";
import { alpha, Box, Button, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from "@mui/material";
import { addYears } from "date-fns";
import { useFormik } from "formik";
import { useEffect, useReducer } from "react";
import useDialogModal from "../../../../hooks/useDialogModal";
import { Colors } from "../../../../styles/theme";
import { dateFromString, niceDate } from "../../../../utils";
import validLookup from "../../../../utils/validLookup";
import { SelectPractioner } from "../../../common";
import { a11yProps, TabPanel } from "../../../ui";
import AppDialogTitle from "../../../ui/appDialogTitle";
import DatePickerComponent from "../../../ui/datePickerComponent";
import { glassLensModel } from "../../model/glassesLensModel";
import GlassesRxTable from "../rx/glassesRxTable";
import FrameDetails from "./frameDetails";
import ImportRxDialog from "./importRxDialog";
import LensDetails from "./lensDetails";
import OrderBilling from "./orderBilling";
import OrderDocuments from "./orderDocuments";


export default function AddContactsOrderDialog({ onClose, open, rowid }) {

    const [ImportRx, showImportRxDialog] = useDialogModal(ImportRxDialog);

    const [state, updateState] = useReducer((state, params) => {
        return { ...state, ...params };
    }, {
        startDate: new Date(),
        endDate: addYears(Date.now(), 1),
        tabIndex: 0
    });

    // if we pass in a rx id then pull info for that one
    useEffect(() => {
        if (rowid) {
            updateState({
                startDate: '2023-02-10',
                endDate: '2024-02-10'
            });
        }
    }, [rowid]);

    const formik = useFormik({
        initialValues: { ...glassLensModel },
        onSubmit: (data) => {
            console.log(data);
        }
    });

    const handleStartDataSelect = (date) => {
        const dt = dateFromString(date);
        updateState({
            startDate: date,
            endDate: addYears(dt, 1)
        })
    }

    const handleSave = () => {
        console.log('save');
    }

    const handleTabChange = (event, newValue) => {
        updateState({ tabIndex: newValue });
    }

    const handleRxImport = () => {
        showImportRxDialog(null, {
            rxType: validLookup.validRxType.glasses
        });
    }

    const handleRxSelect = (rx) => {
        //console.log(rx)
        formik.setValues({ ...rx.od, ...rx.os });
    }

    return (
        <Dialog onClose={onClose} open={open} fullScreen >
            <AppDialogTitle onClose={onClose} title="New Contacts Rx" />

            <DialogContent>
                <Box display={'flex'} alignItems="center">
                    <Typography sx={{pr: 1}}>Order date: {niceDate(new Date())}</Typography>
                    <TextField label="Tray" labelId="tray" size="small" sx={{ mt: 1, pr: 1 }}></TextField>
                    <TextField label="Associate" labelId="tray" size="small" sx={{ mt: 1 }}></TextField>
                </Box>

                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={state.tabIndex} onChange={handleTabChange}>
                        <Tab label="Rx & Lens" {...a11yProps("glassesorder", 0)} />
                        <Tab label="Billing & Payment" {...a11yProps("glassesorder", 1)} />
                        <Tab label="Documents" {...a11yProps("glassesorder", 2)} />
                    </Tabs>
                </Box>
                <TabPanel idfor="glassesorder" value={state.tabIndex} index={0}>
                    <Button sx={{ mb: 1 }} variant="outlined" onClick={handleRxImport}>Import Rx</Button>
                    <GlassesRxTable formik={formik} />
                    <Box sx={{ background: Colors.muted, p: 0.5 }}><Typography>Lens</Typography></Box>
                    <LensDetails />
                </TabPanel>
                <TabPanel idfor="glassesorder" value={state.tabIndex} index={1}>
                    <OrderBilling />
                </TabPanel>
                <TabPanel idfor="glassesorder" value={state.tabIndex} index={2}>
                    <OrderDocuments />
                </TabPanel>
            </DialogContent >
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
                <Button color="primary" variant="contained" type="submit" autoFocus>
                    Save
                </Button>
            </DialogActions>
            <ImportRx onRxSelect={handleRxSelect} />
        </Dialog >
    )
}