import { styled } from "@mui/material/styles";
import { Button, ButtonGroup, FormControlLabel, FormGroup, IconButton, Switch,Box } from "@mui/material";
import { ChevronLeftOutlined, ChevronRightOutlined } from "@mui/icons-material";
import { CALENDAR_ACTION_TYPE, useCalendarContext } from "../../../state/calendar";
import { KeyboardKeys } from "../../../enums/keyboard-keys";
import EventIcon from '@mui/icons-material/Event';
import { Colors } from "../../../styles/theme";
import WeekView from "../week";
import DayView from "../day";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from "react";
import MiniCalendar from "../../ui/mini-calendar";
import { differenceInCalendarDays } from "date-fns/fp";
import { differenceInCalendarWeeks, format } from "date-fns";
import { dateFromString } from "../../../utils";
import useDialogModal from "../../../hooks/useDialogModal";
import DatePickerDialog from "../../ui/datePickerDialog";

const ActionbarContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "flex-end",
  margin: 1
}));

/***************************************************************
 * View Selector
 ****************************************************************/
export const ViewSelector = ({ viewSelected }) => {
  const { dispatch } = useCalendarContext();

  const handleViewChange = (view) => {
    dispatch({ type: CALENDAR_ACTION_TYPE.SWITCHVIEW, payload: view });
    viewSelected(view);
  }

  return (
    <ActionbarContainer>
      <ButtonGroup variant="contained" aria-label="outlined primary group">
        <Button onClick={() => handleViewChange('day')}>Day</Button>
        <Button onClick={() => handleViewChange('week')}>Week</Button>
        
        {/* <Button onClick={() => handleViewChange('month')}>
          Month
        </Button> */}
      </ButtonGroup>
    </ActionbarContainer>
  );
};

/***************************************************************
 * Calendar View (day,week,year)
 ****************************************************************/
export const CalendarView = () => {
  const { state } = useCalendarContext();
  return (
    <>
      {state.activeView === "week" && <WeekView />}
      {state.activeView === "day" && <DayView />}
    </>

  );
};

/***************************************************************
 * Go to date
 ****************************************************************/
export const GotoDate = ({ gotoDate }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dayOfAppointment, setDayOfAppointment] = useState();
  const { state, dispatch } = useCalendarContext();
  const [DatePicker, showDatePicker] =
  useDialogModal(DatePickerDialog);

  const handleDatePickerDone = (value) => {
    const dt = format(value, 'yyyy-MM-dd');
    dispatch({type: CALENDAR_ACTION_TYPE.GOTODATE, payload: {
      goto: dt
    }});
};

  const handleClick = (event) => {
    showDatePicker();
    //setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDayClicked = (day) => {
    const dt = `${day.year}-${day.month}-${day.day}`;
    const date = dateFromString(dt);

    const diff = state.activeView === 'day' ? differenceInCalendarDays(new Date(),date) : differenceInCalendarWeeks(date,new Date());

    dispatch({type: CALENDAR_ACTION_TYPE.GOTODATE, payload: {
      jumpamount: diff
    }});
    //gotoDate(dt);
  }

  return (
    <ActionbarContainer>
      <ButtonGroup variant="contained" aria-label="outlined primary group">
        <Button startIcon={<EventIcon sx={{ color: Colors.white }} />} onClick={handleClick}>Go to Date</Button>
      </ButtonGroup>
      <DatePicker  onDone={handleDatePickerDone}/>
      {/* <Menu
        id="gotodate-mini-calendar"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          style: {
            width: '600px',
          }
        }}
      >
        <MiniCalendar
          dayOfAppointment={dayOfAppointment}
          setDayOfAppointment={setDayOfAppointment}
          onDayClicked={handleDayClicked}
          disablePastDates={false} />
      </Menu> */}
    </ActionbarContainer>
  );
};

/***************************************************************
 * today
 ****************************************************************/
export const TodayDate = ({ props }) => {
  const { dispatch } = useCalendarContext();

  return (
    <ActionbarContainer>
      <ButtonGroup variant="contained" aria-label="outlined primary group">
        <Button onClick={() => dispatch({ type: CALENDAR_ACTION_TYPE.TODAYDATE })}>Today</Button>
      </ButtonGroup>
    </ActionbarContainer>
  );
};

/***************************************************************
 * NextPrev
 ****************************************************************/
export const NextPrev = () => {

  const { dispatch, onNext, onPrev } = useCalendarContext();

  const handleKeyDown = (e) => {

    if (e.key === KeyboardKeys.ArrowLeft) {
      dispatch({ type: CALENDAR_ACTION_TYPE.PREVNEXT, payload: {jumptype: 'prev'} });
    }

    if (e.key === KeyboardKeys.ArrowRight) {
      dispatch({ type: CALENDAR_ACTION_TYPE.PREVNEXT, payload: {jumptype: 'next'} });
    }
  }

  return (
    <Box>
      <IconButton
        onKeyDown={handleKeyDown}
        onClick={() => dispatch({ type: CALENDAR_ACTION_TYPE.PREVNEXT, payload: {jumptype: 'prev', jumpamount: 1} })}
        aria-label="prevous date"
        color="primary"
      >
        <ChevronLeftOutlined />
      </IconButton>
      <IconButton
        onClick={() => dispatch({ type: CALENDAR_ACTION_TYPE.PREVNEXT, payload: {jumptype: 'next', jumpamount: 1} })}
        aria-label="next date"
        color="primary"
      >
        <ChevronRightOutlined />
      </IconButton>
    </Box>
  );
};

/***************************************************************
 * Time indicator toggle
 ****************************************************************/
export const TimeIndicatorToggle = () => {

  const { timeIndicator, setTimeindicator } = useCalendarContext();

  return (
    <FormGroup sx={{ ml: 2 }}>
      <FormControlLabel control={<Switch checked={timeIndicator} onChange={(event) => setTimeindicator(e => event.target.checked)} />} label="Time indicator" />
    </FormGroup>
  )
}