/**
 * Used with `KeyboardEvent.key`
 *
 * See https://www.w3.org/TR/uievents-key/#key-attribute-value
 * for possible options.
 */
export const KeyboardKeys = {
  Tab: "Tab",
  Enter: "Enter",
  Backspace: "Backspace",
  ArrowLeft: "ArrowLeft",
  ArrowRight: "ArrowRight",
};
