import request from "./httpService";

const getPractitioners = () => {
    return request({
        method: "GET",
        url: '/practitioner'
    });
}

const getProfileStatus = (cardholderid, token) => {
    return request({
        method: "GET",
        url: `/cardholder/status/profile/${cardholderid}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const verifyPasswordResetLink = (data) => {
    return request({
        method: "POST",
        url: `/cardholder/password/reset/verify`,
        data: data,
    });
};

const passwordResetComplete = (data) => {
    return request({
        method: "POST",
        url: `/cardholder/password/reset/complete`,
        data: data,
    });
};

const forgotPassword = (data) => {
    return request({
        method: "POST",
        url: `/cardholder/password/forgot`,
        data: data,
    });
};

const search = (data) => {
    return request({
        method: "GET",
        url: `/cardholder/search/${data}`,
    });
}

export const EmployeeService = {
    getPractitioners,
    verifyPasswordResetLink,
    passwordResetComplete,
    forgotPassword,
    getProfileStatus,
    search
};