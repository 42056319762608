

export  const practioners = [
  {
    id: 1,
    designation: "Dr",
    first_name: "John",
    last_name: "Doe",
  },
  {
    id: 2,
    designation: "Dr",
    first_name: "Criss",
    last_name: "Ng",
  },
  {
    id: 3,
    designation: "Dr",
    first_name: "Rashid",
    last_name: "Siraj",
  },
];

export  const locations = [
  {
    id: 1,
    name: "Location A",
  },
  {
    id: 2,
    name: "Location B",
  },
  {
    id: 3,
    name: "Location C",
  },
];


export const patients = [
{
  id: 1,
  firstname: 'john',
  lastname: 'doe',
  communication: [
    {
      type: 'phone',
      value: '1234567890',
      preferred: 0,
      category: 'home'
    },
    {
      type: 'phone',
      value: '1234567890',
      preferred: 1,
      category: 'cell'
    },
    {
      type: 'phone',
      value: '1234567890',
      preferred: 0,
      category: 'work'
    },
    {
      type: 'email',
      value: 'john.doe@email.com',
      preferred: 0,
      category: 'personal'
    },
  ],
  address: {
    no: 123,
    street: 'john st w',
    city: 'anycity',
    province: 'anyprovince',
    postal: '1a1 b1b',
  }
}
]

/**
 * Practioner schedule
 * id
 * practitioner_id
 * date
 * from
 * to
 */
export const schedule = [
  {
    id: 1,
    practitioner_id: 1,
    date: '05/06/2022',
    from: '9:00',
    to: '14:00'
  },
  {
    id: 1,
    practitioner_id: 1,
    date: '06/06/2022',
    from: '11:00',
    to: '14:00'
  },
  {
    id: 1,
    practitioner_id: 1,
    date: '07/06/2022',
    from: '11:00',
    to: '17:00'
  },
]
  

export const timeslots = [
  "9 am",
  "10 am",
  "11 am",
  "12 pm",
  "1 pm",
  "2 pm",
  "3 pm",
  "4 pm",
  "5 pm",
  "6 pm",
  "7 pm",
  "8 pm",
];

export const appSettings = {
  businessStartHour: 9,
  businessClosingHour: 20,
  timeSlotInterval: 15,  
  workWeek: 7,
  timeZone: 'America/Toronto'
  
}