import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import { TextField, Grid } from "@mui/material";
import { Colors } from "../../../styles/theme";

export default function CommentsCard({ formik }) {

    return (
        <Card elevation={1}>
            <CardHeader title="Notes" sx={{ color: Colors.muted }} />
            <Divider />
            <CardContent>

                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            name="comment"
                            value={formik.values.comment}
                            onChange={formik.handleChange}
                            multiline rows={5} id="pr-comment" label="Notes" variant="standard" fullWidth />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}