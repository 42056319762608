import { Box, Pagination, Stack } from "@mui/material";
import PatientTable, { PatientTableWithPagination } from "./patientTable";
import { useState } from "react";
import PatientSearchForm from "./dialog/searchPatient/patientSearchForm";
import { PatientService } from "../../service/patientService";
import { toast } from "react-toastify";
import { isLocalDev } from "../../utils/environment";
import useEffectOnUpdates from "../../hooks/useEffectOnUpdates";
import { UI_ACTION_TYPE, useUIContext } from "../../state/ui";
import { initialPageSize, withPagination } from "../hoc/withPagination";

const pageSize = 20;

export default function SearchPatient({ onDone, filterPatient }) {

    const [pagination, setPagination] = useState({
        count: 0,
        from: 0,
        to: pageSize
    });
    const [patients, setPatients] = useState([]);
    const [searchParam, setSearchParam] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPageTotal, setCurrentPageTotal] = useState(0);

    const { dispatch } = useUIContext();

    const handlePatientSelect = (patient) => {
        onDone(patient);
    };

    useEffectOnUpdates(() => {
        if(searchParam.firstname || searchParam.lastname || searchParam.phone)
            doSearch()
    }, [searchParam]);

    const handleSearchDone = async (searchParam) => {
        setSearchParam({ ...searchParam, offset: 0, limit: initialPageSize });
    }

    const doSearch = async () => {
        dispatch({
            loading: true,
            type: UI_ACTION_TYPE.LOADING,
            loadingtext: "Searching...Please wait",
        });
        try {

            const patientSearchResult = await PatientService.search(searchParam);
            const { code, message, data } = { ...patientSearchResult };

            dispatch({ loading: false, type: UI_ACTION_TYPE.LOADING });
            if (code === 0) {
                let patientRows = data.rows;
                if (filterPatient) {
                    patientRows = patientRows.filter(p => p.familyGroupId !== filterPatient.familyGroupId)
                }
                setPatients(patientRows);
                setTotalCount(data.count);
                setCurrentPageTotal(patientRows.length);
                //setPagination({ ...pagination, count: data.count });
            } else {
                setPatients([]);
                setTotalCount(0);
                setCurrentPageTotal(0);
                toast.info(message);
            }
        } catch (error) {
            dispatch({ loading: false, type: UI_ACTION_TYPE.LOADING });
            if (isLocalDev) {
                console.log(error);
            }
        }
    }

    const handlePageChange = (from, to) => {
        setSearchParam({ ...searchParam, offset: from, limit: to });
    }

    return (
        <Stack>

            <PatientSearchForm onSearchDone={handleSearchDone} />
            <PatientTableWithPagination
                onPageChange={handlePageChange}
                currentPageTotal={currentPageTotal}
                totalCount={totalCount}
                patients={patients}
                onSelect={handlePatientSelect} />

        </Stack>
    );
}


// {withPagination(<PatientTable patients={patients} onSelect={handlePatientSelect} onPageChange={handlePageChange} />,
// handlePageChange, currentPageTotal, totalCount)}