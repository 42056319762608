import { CircularProgress, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { useEffect } from "react";
import { useUIContext } from '../../state/ui';
import { ZIndex } from "../../styles/constants/enums";
import { Colors } from '../../styles/theme';

const LoadingScreen = styled('div')(({
    loading
}) => ({
    position: 'absolute',
    background: alpha(Colors.black, 0.5),
    visibility: loading,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: ZIndex.MAX
}));

export default function AppLoader() {

    const {state} = useUIContext();

    return (
        <LoadingScreen
          loading={state.loading ? 'visible' : 'hidden'}
        >
            <CircularProgress size={60} color="info" />
            <Typography sx={{
                mt: 2,
                fontSize: '2rem',
                color: Colors.white
            }}>{state.loadingtext}</Typography>
        </LoadingScreen>
    );
}
