import { Grid, lighten, styled } from "@mui/material";
import { Colors } from "../theme";

export const DoctorTile = styled(Grid)(({active}) => ({
    cursor: 'pointer',
    background: active === 'true'  && Colors.primary,
    '&:hover': {
        border: `1px solid ${Colors.primary}`,
        boxShadow: '2px 2px 10px rgba(0,0,0,0.2)'
    }
}));


export const FieldSet = styled('fieldset')(() => ({
    borderRadius: '4px',
    borderColor: lighten(Colors.dove_gray, 1)
}));

export const FieldSetLabel = styled('legend')(() => ({
    background: Colors.primary,
    color: Colors.white,
    fontWeight: 'bold',
    padding: '2px 10px',
    borderRadius: '8px',
}));