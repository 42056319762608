import { styled } from "@mui/material/styles";
import Styles from "../";
import { EventsContainer, UL } from "../calendar";

export const ResourceContainer = styled(UL)(({columns}) => ({
    display: "grid",
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    textTransform: "capitalize",
    "&::after": {
      content: '""',
      left: "0",
      position: "absolute",
      width: "100%",
      height: "2px",
      background: Styles.color.primary,
      //background: `linear-gradient(90deg, rgba(235,235,235,0.25) 0%, ${Styles.color.dove_gray} 25%,${Styles.color.dove_gray} 75%, rgba(235,235,235,1) 100%)`,
      zIndex: 1,
    },
  }));

  export const ResourceEventsContainer = styled(EventsContainer, {
    shouldForwardProp: (props) => props !== 'numberOfResources'
  })(({numberOfResources}) => ({
    position: 'relative',
    display: "grid",
    gridTemplateColumns: `repeat(${numberOfResources}, 1fr)`,
    gridTemplateRows: `repeat(48, 1fr)`,
    gridArea: "main",
    whiteSpace: 'nowrap'
  }));