import { TableContainer, Box, 
    Paper, TableBody, TableHead, TableRow, TableCell, Table, IconButton, Button, styled } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from "react";
import { EmployeeService } from "../../../service/employeeService";

const TableCellTitleCase = styled
(TableCell)(() => ({
    textTransform: 'capitalize'
}))

export default function ManagePractitioner() {
    const [loading, setLoading] = useState(false);
    const [practitioner, setPractitioners] = useState([]);

    useEffect(() => {
        EmployeeService.getPractitioners().then(result => {
            if( result.code === 0 ) {
                setPractitioners(result.data);
            }
        }).catch(error => console.log(error));
    }, []);

    return (
        <Box>
            <Button sx={{mb: 2}} variant="contained" startIcon={<AddIcon />}>Add</Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Designation</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            practitioner.map(p =>                         
                            <TableRow key={p.id}>
                                <TableCellTitleCase>{p.firstName}</TableCellTitleCase>
                                <TableCellTitleCase>{p.lastName}</TableCellTitleCase>
                                <TableCellTitleCase>{p.roleType}</TableCellTitleCase>
                                <TableCell>
                                    <IconButton>
                                        <MoreVertIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>)
                        }
                    </TableBody>
                </Table>

            </TableContainer>
        </Box>

    )

}