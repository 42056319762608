import request from "./httpService";

const getComments = (patientId) => {
    return request({
        method: "GET",
        url: `/comment/patient/${patientId}`
    });
}

const getCommentsWithLimits = (patientId, params) => {
    return request({
        method: "GET",
        url: `/comment/patient/${patientId}/withlimit`,
        params: params,
    });
}

const addComment = (data) => {
    return request({
        method: "POST",
        url: '/comment/new',
        data: data
    });
}

const deleteComment = (id) => {
    return request({
        method: "DELETE",
        url: `/comment/${id}`,
    });
}


export const CommentService = {
    getComments,
    getCommentsWithLimits,
    addComment,
    deleteComment,
};