import {
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
    IconButton,
    DialogActions,
    Button,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Colors } from '../../styles/theme';
import { useRef, useState } from "react";
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Calendar from "react-calendar";
import { ChevronLeftOutlined, ChevronRightOutlined } from "@mui/icons-material";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

export function PaperComponent(props) {
    const nodeRef = useRef(null);
    return (
        <Draggable
            nodeRef={nodeRef}
            handle="#date-picker-dialog"
        >
            <Paper id="date-picker-dialog" ref={nodeRef} {...props} />
        </Draggable>
    );
}

export default function DatePickerDialog({ open,
    onClose, onDone, date}) {
    const [value, onChange] = useState(date || new Date());

    const handleDone = () => {
        onClose();
        onDone(value);
    }
    return (
        <Dialog onClose={onClose} open={open} fullWidth maxWidth={'xs'} PaperComponent={PaperComponent}>
            <DialogTitle sx={{ background: Colors.primary, mb: 4, color: Colors.white, cursor: 'move' }}
                aria-labelledby="date-picker-dialog">
                <Box display={"flex"} alignItems="center" justifyContent={'space-between'}>
                    Choose date
                    <IconButton onClick={onClose} sx={{ color: Colors.white }}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ width: '100%', height: '100%' }} display="flex" flexDirection={"column"}>
                    <Calendar 
                    prev2Label={<KeyboardDoubleArrowLeftIcon />}
                    prevLabel={<ChevronLeftOutlined />}
                    nextLabel={<ChevronRightOutlined />}
                    next2Label={<KeyboardDoubleArrowRightIcon />}
                    onChange={onChange} value={value} tileClassName={"dp-custom-tile"} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleDone}>
                    Done
                </Button>
                <Button onClick={onClose} sx={{ color: Colors.muted }}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}



