import { Box, Card, CardContent, CardHeader, Divider, 
    FormControl, FormHelperText, FormControlLabel, Switch, InputLabel, Select, MenuItem } from "@mui/material";
import { TextField, Typography } from "@mui/material";
import { Colors } from "../../../styles/theme";
import DatePickerComponent from "../../ui/datePickerComponent";

export default function AdditionalInfoCard({ formik }) {

    const handleDatePickerDone = (value) => {
        formik.getFieldHelpers('birthdate').setValue(value);
    };

    return (
        <Card elevation={1}>
            <CardHeader title="Additional Details" sx={{ color: Colors.muted }} />
            <Divider />
            <CardContent>
                <Box display="flex" flexDirection={"column"} >
                    <FormControl variant="filled" fullWidth>
                        <DatePickerComponent name="birthdate" id="pr-birthdate" value={formik.values.birthdate}
                        onDateSelectionDone={handleDatePickerDone} />
                        <FormHelperText component={"span"} sx={{display: 'flex'}}>Date of birth <Typography color={Colors.danger}>*</Typography></FormHelperText>
                    </FormControl>

                    <TextField
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        id="pr-email" label="Email" variant="standard" fullWidth sx={{ mt: 0 }} />
                    <TextField
                        name="healthcard"
                        value={formik.values.healthcard}
                        onChange={formik.handleChange}
                        id="pr-healthcard" label="Healthcard" variant="standard" fullWidth sx={{ mt: 1 }} />

                    <Box display={"flex"} sx={{mt: 4}}>
                        <FormControl sx={{width: '40%'}}>
                            <InputLabel id="pr-communicationtype">Communicate by:</InputLabel>
                            <Select
                                name="communicationType"
                                id="pr-communicationType"
                                value={formik.values.communicationType}
                                onChange={formik.handleChange}
                                label="Phone Type" fullWidth size="small" variant="standard">
                                <MenuItem value={"none"}>None</MenuItem>
                                <MenuItem value={"phone"}>Phone</MenuItem>
                                <MenuItem value={"email"}>Email</MenuItem>
                            </Select>
                        </FormControl>
                        {/* <FormControlLabel control={
                        <Switch
                            name="emailandtextoptin"
                            checked={formik.values.emailandtextoptin}
                            onChange={formik.handleChange}
                        />}
                        label={
                        <Typography color={formik.values.emailandtextoptin ? Colors.black : Colors.muted}>Text message and email notification?</Typography>} /> */}

                        <FormControlLabel sx={{ml: 2}} control={
                            <Switch
                                name="marketingEmailOptin"
                                checked={formik.values.marketingEmailOptin}
                                onChange={formik.handleChange} />}
                            label={<Typography color={formik.values.marketingEmailOptin ? Colors.black : Colors.muted}>Marketing email opt in?</Typography>} />
                    </Box>

                </Box>
            </CardContent>
        </Card>
    )
}

//onChange={(e) => setOptinState({ ...optinState, emailAndText: e.target.checked })}