import { useDraggable } from "@dnd-kit/core";
import { Box, Typography } from "@mui/material";
import { format, parse } from "date-fns";
import { pulseAnimation } from "../../animation";
import { ZIndex } from "../../styles/constants/enums";
import { Colors } from "../../styles/theme";
import { niceTime, toTitleCase } from "../../utils";
import validLookup from "../../utils/validLookup";
import { slotBackground } from "./slot";

export default function DraggableSlot(props) {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: props.id,
        data: {
            practitionerid: props.practitionerid,
            top: props.rect.top,
            start: props.start
        }
    });
    return (
        <Box
            id={props.id}
            component="div"
            {...listeners} {...attributes}
            ref={setNodeRef}
            sx={{
                transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
                cursor: 'pointer',
                position: 'absolute',
                top: `${(props.offset || 0) + props.rect.top}px`,
                background: slotBackground(props.status),
                boxShadow: props.isDragging ? 12 : undefined,
                width: '100%',
                height: `${props.rect.height}px`,
                zIndex: props.status === validLookup.validSlotStatus.available ? 1 : 999,
                left: 0,
                overflow: 'hidden',
                wordWrap: 'break-word',
                ...(props.status === validLookup.validAppointmentStatus.paid && {
                    borderLeft: `10px solid ${Colors.paid}`
                }),
                ...(props.status === validLookup.validAppointmentStatus.arrived && {
                    borderLeft: `10px solid ${Colors.arrived}`
                }),
                ...(props.status === validLookup.validAppointmentStatus.noshow && {
                    borderLeft: `10px solid ${Colors.noshow}`
                }),
                ...(props.status === validLookup.validAppointmentStatus.completed && {
                    borderLeft: `10px solid ${Colors.completed}`
                }),
                ...(props.collides && props.collides.id === props.id && {
                    animation: `${pulseAnimation} 1.5s ease-in both`,
                }),
                ...(props.activeSlot && props.id === props.activeSlot.id && {
                    boxShadow: 12,
                    borderLeft: `16px solid ${Colors.warning}`,
                    height: 'auto',
                    zIndex: ZIndex.MAX
                }),
                paddingLeft: '6px',
                "&:hover": {
                    height: 'auto',
                    opacity: 0.9,
                    zIndex: ZIndex.MAX
                },
                "&::after": {
                    position: 'absolute',
                    top: '-1px',
                    left: '-1px',
                    width: 'calc(100% + 1px)',
                    height: 'calc(100% + 1px)',
                    content: '""',
                    boxSizing: 'border-box',
                    borderRadius: '2px',
                    border: `1px solid ${Colors.secondary}`,
                }
                
            }}
            onClick={props.onClick}
        >
            <Typography sx={{pt: 1}} variant="body2">{niceTime(props.start, 'HH:mm',false)} - {niceTime(props.end, 'HH:mm', false)}</Typography>
            <Typography>{toTitleCase(props.name)}</Typography> 
            <Typography variant="caption">{props.status}</Typography> 
            </Box>

    )
}