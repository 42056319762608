import { getDate, parse, format, parseISO, addMinutes, differenceInMinutes } from "date-fns";
import { format as formattz } from 'date-fns-tz';
import { parsePhoneNumber } from "libphonenumber-js";
import { appSettings } from "../data";
import { isLocalDev } from "./environment";
export const DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss.SSS';

export const randomColor = () => Math.floor(Math.random() * 16777215).toString(16).padEnd(6, 'ff');

export const eventSlotColor = (day) => {
    return ['A5D6A7', 'E57373', '42A5F5', 'FFB74D', 'FFD600', 'CDDC39', '00BCD4', 'B388FF', '26C6DA', '00C853'
    ].at(day);
}

export const debounce = (fn, wait = 0) => {
    let timeout;

    return (...args) => {
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => console.log("test"), wait);
    }
}

/**
 * input format 
 * @returns date in Month, day year format e.g. Nov 12, 2022
 */
export const niceDate = (dateString, inFormat) => {
    try {
        if (dateString instanceof Date) {
            const op = {
                dateStyle: 'full'
            };
            return new Intl.DateTimeFormat('en-CA', op).format(dateString);
        }
        return format(parseISO(dateString, inFormat || 'yyyy-MM-dd HH:mm:ss.SSS', new Date()), 'MMM, dd yyyy');
    } catch (error) {
        throw new Error(error);
    }
}

export const niceTime = (timeString, inFormat, iso = true) => {
    
    if (timeString) {
        try {
            // 
            return iso ?
                format(parseISO(timeString, inFormat || 'yyyy-MM-dd HH:mm:ss.SSS', new Date()), 'hh:mm a') :
                format(parse(timeString, inFormat || 'HH:mm', new Date()), 'hh:mm a');
        } catch (error) {
            if(isLocalDev) {
                console.log(error);
            }
            throw new Error(error);
        }
    }

}

/**
 * input format dd/mm/yyyy
 * @returns date in Month, day year format
 */
export const formateDate = (dateString, inFormat, outFormat) => {
    try {

        return format(parseISO(dateString, inFormat || 'dd/MM/yyyy', new Date()), outFormat || 'MMM, dd yyyy');
    } catch (error) {
        throw new Error(error);
    }
}

/**
 * Given a date in ISO 8601 format will return date only
 * @param {*} date 
 * @param {*} outFormat 
 * @returns 
 */
export const getDateString = (date, outFormat) => {
    return formateDate(date, 'yyyy-MM-dd HH:mm:ss.SSS', outFormat || 'yyyy-MM-dd');
}


/**
 * Given a date in ISO 8601 format will return date only
 * @param {*} date 
 * @param {*} outFormat 
 * @returns 
 */
export const getDateStringNonISO = (date, outFormat) => {
    return formateDate(date.toISOString(), 'yyyy-MM-dd HH:mm:ss.SSS', outFormat || 'yyyy-MM-dd');
}

/**
 * Given a date in ISO 8601 format will return time only
 * @param {*} date 
 * @param {*} outFormat 
 * @returns 
 */
export const getTimeString = (date, outFormat) => {
    return formateDate(date, 'yyyy-MM-dd HH:mm:ss.SSS', outFormat || 'HH:mm');
}

export const getTimeStringNonIso = (date, outFormat) => {
    return formateDate(date.toISOString(), 'yyyy-MM-dd HH:mm:ss.SSS', outFormat || 'HH:mm');
}

export const dateFromString = (dateString, inFormat, iso = false) => {
    if (iso)
        return parseISO(dateString, inFormat || DATE_FORMAT, new Date());

    return parse(dateString, inFormat || 'yyyy-MM-dd', new Date())
}

/**
 * returns date with time
 * @param {Date} forDate 
 * @param {String} timeString 
 * @returns 
 */
export const dateSetTime = (forDate, timeString) => {
    const date = dateFromString(forDate, 'yyyy-MM-dd', true);

    const newStart = timeFromString(timeString);
    newStart.setFullYear(date.getFullYear());
    newStart.setMonth(date.getMonth());
    newStart.setDate(date.getDate());

    return newStart;
}

export const formatTime = (timeString) => {
    return format(parse(timeString, 'HH:mm', new Date()), 'HH:mm')
}

/**
 * given a time in HH:mm format will return diff in minutes
 * @param {string} time1 
 * @param {string} time2 
 */
export const timeDiff = (time1, time2) => {
    let t1 = null,
        t2 = null;
    //if has a space then is in ISO format otherwise is just time
    if (time1.indexOf(' ') > 0) {
        t1 = parse(time1, DATE_FORMAT, new Date());
        t2 = parse(time2, DATE_FORMAT, new Date());
    } else {
        t1 = parse(time1, 'HH:mm', new Date());
        t2 = parse(time2, 'HH:mm', new Date());
    }

    return Math.abs(differenceInMinutes(t2, t1));
}

/**
 * returns date in local timezone
 * @param {Date} date 
 */
export const toLocalTimeZone = (date) => {
    return formattz(date, DATE_FORMAT, { timeZone: appSettings.timeZone });
}

/**
 * breaks a timestring into hour and min component
 * @param {string} timeString 
 * @returns 
 */
export const timeComponent = (timeString) => {
    const hour = +timeString.split(':')[0];
    const minutes = +timeString.split(':')[1];

    return {
        hour: hour,
        minutes: minutes
    }
}

/**
 * 
 * @returns day of month
 */
export const today = () => {
    var result = getDate(new Date());
    return result;
}

/**
 * 
 * @returns day of week
 */
export const dayOfWeek = () => {
    var result = getDate(new Date());
    return result;
}

/**
 * 
 * @param {*} month 
 * @returns string representation of month
 */
export const getMonthString = (month) => {
    return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].at(month);
}

export const weekdays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

export const getDayString = (day) => {
    return ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"].at(day);
}

export const toTitleCase = (str) => {
    return str ? str.toLowerCase().split(' ').map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ') : '';
}

/**
 * returs adrr No street city
 * @param {*} address 
 * @returns 
 */
export const buildAddress = (address) => {
    return `${address.addrNo} ${toTitleCase(address.addrStreet)}, ${toTitleCase(address.addrCity)}`;
}

/**
 * returns hour with 0 padding
 * @param {date} date 
 * @returns 
 */
const getHour = (date) => {
    return ('00' + date.getHours()).slice(-2);
}

/**
 * returns minutes with 0 padding
 * @param {date} date 
 * @returns 
 */
const getMinutes = (date) => {
    return ('00' + date.getMinutes()).slice(-2);
}

export function timeFromString(param) {
    if (!param) return null;

    let format = 'HH:mm';
    const paramlc = param.toLowerCase();
    if (paramlc.endsWith('am') || paramlc.endsWith('pm')) {
        format = `hh:mm a`;
    }

    const dt = parse(paramlc, format, new Date());
    return dt;
    // var d = new Date();
    // var parts = string.split(' ');
    // let timePart = parts[0];
    // let ampm = 'am';

    // let h = +timePart.split(":")[0];
    // let m = +timePart.split(":")[1];

    // // check for am/pm
    // if (parts.length > 1) {
    //     ampm = parts[1].toLowerCase();

    //     if (ampm === 'pm') {
    //         h += 12;
    //     }
    // }


    // d.setHours(h);
    // d.setMinutes(m);
    // d.setSeconds(0);

    // return d;
}

/**
 * given a start and an end with a step will return time range.
 * default step is 15 min
 * @param {Object} start {hh: h, mm: m, ampm: ampm}
 * @param {Object} end {hh: h, mm: m, ampm: ampm}
 * @param {number} step xx (in minutes)
 */
export const getTimeRange = (start, end, step, availableStart, availableEnd, skipRange, endInclusive = false) => {
    const startDate = new Date();
    const endDate = new Date();


    // check for 24 hour
    // 11 pm
    // if( start.ampm === 'pm' && start.h <= 12 ) {
    //     start.h += 12;
    // }        
    // if( end.ampm === 'pm' && end.h <= 12 ) {
    //     end.h += 12;
    // }    

    // startDate.setHours(start.h);
    // startDate.setMinutes(start.m)

    // endDate.setHours(end.h);
    // endDate.setMinutes(end.m)

    // if( start <= 12 ) {
    //     start += 12;
    // }        
    // if( end <= 12 ) {
    //     end+= 12;
    // }    

    startDate.setHours(start);
    startDate.setMinutes(0)

    endDate.setHours(end);
    endDate.setMinutes(0)
    const range = [];

    if (availableStart) {
        const beginFromTime = timeComponent(availableStart);

        startDate.setHours(beginFromTime.hour);
        startDate.setMinutes(beginFromTime.minutes);

    }
    if (availableEnd) {
        const endToTime = timeComponent(availableEnd);

        endDate.setHours(endToTime.hour);
        endDate.setMinutes(endToTime.minutes);
    }

    let s = addMinutes(startDate, 0);

    for (let i = startDate.getTime(); i <= endDate.getTime(); i += step * 60 * 1000) {

        const h = getHour(s);
        const m = getMinutes(s);

        const time = `${h}:${m}`;
        const displaytime = `${h > 12 ? h - 12 : h}:${m} ${h >= 12 ? 'PM' : 'AM'}`;


        startDate.setHours(s.getHours());
        startDate.setMinutes(s.getMinutes());
        let skipIt = false;
        for (var sk = 0; sk < skipRange.length; sk++) {
            const skip = skipRange[sk];
            const timeLower = timeFromString(skip.starttime);
            const timeUpper = timeFromString(skip.endtime);

            if (startDate >= timeLower && startDate < timeUpper) {
                skipIt = true;
                break;
            }
        }

        !skipIt && range.push({ time: time, display: displaytime });
        s = addMinutes(startDate, step);
    }

    if (!endInclusive) range.pop();

    return range;
}

export const getRandomNumber = (min, max) => {
    return Math.round(Math.random() * (max - min) + min);
}

export const getEventCoordinates = (ev) => {
    let target = ev;

    return {
        clientX: target.clientX,
        clientY: target.clientY,
        pageX: target.pageX,
        pageY: target.pageY
    }
}

export const formatPhoneNumber = (phone) => {
    return parsePhoneNumber(`+1${phone}`).formatNational();
}

/**
 * given a file size will return a human readable form
 * @param {*} bytes 
 * @returns 
 */
export function formatFileSize(bytes) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }
  