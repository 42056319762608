import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Typography,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import Styles from "../../../../styles";
import { LocationService } from "../../../../service/locationService";
import { EmployeeService } from "../../../../service/employeeService";
import { ScheduleService } from '../../../../service/scheduleService';
import useList from "../../../../hooks/useList";
import MiniCalendar from "../../../ui/mini-calendar";
import useEffectOnUpdates from "../../../../hooks/useEffectOnUpdates";
import { dateFromString, timeDiff, timeFromString, toTitleCase } from "../../../../utils";
import EditIcon from '@mui/icons-material/Edit';
import { Colors } from "../../../../styles/theme";
import { AppointmentService } from "../../../../service/appointmentService";
import { isLocalDev } from "../../../../utils/environment";
import validLookup from "../../../../utils/validLookup";
import { useUIContext } from "../../../../state/ui";
import GroupByPractitioners from "../../../calendar/groupByResource/GroupByPractitioners";
import { appSettings } from "../../../../data";
import ChooseTimeDialog, {durationMarks}  from "../../../ui/choose-time-dialog";
import useDialogModal from "../../../../hooks/useDialogModal";
import ConfirmationDialog from "../../../ui/confirmationDialog";

export default function AppointmentDetailsDialog({ open, onClose, selectedEvent, setSelectedEvent, onUpdated }) {

  const [dayOfAppointment, setDayOfAppointment] = useState();
  const [openSlot, setOpenSlot] = useState();
  const [editMode, setEditMode] = useState(false);
  const { setToast } = useUIContext();
  const [resourceGroup, setResourceGroup] = useState({ tick: new Date().getTime(), data: [] });
  const [TimeSelectDialog, showTimeSelectDialog] = useDialogModal(ChooseTimeDialog);
  const [canSaveTimeSelectDialog, setCanSaveTimeSelectDialog] = useState(true);
  const [bookedSlots, setBookedSlots] = useState([]);
  const [duration, setDuration] = useState(durationMarks.at(1).value);
  const [ConfirmationDialogComp, showConfirmationDialog] = useDialogModal(ConfirmationDialog);

  const [collidesWith, setCollidesWith] = useState({
    tick: new Date().getTime(),
    column: 0,
    starttime: '',
    endtime: ''
  });
  const [columnAndSlotId, setColumnAndSlotId] = useState({
    column: -1,
    slotId: -1
  });
  const [timeOfAppointment, setTimeOfAppointment] = useState({
    start: '',
    end: ''
  });
  const [timeSelectDialogTimes, setTimeSelectDialogTimes] = useState({
    availableStart: appSettings.businessStartHour,
    availableEnd: appSettings.businessClosingHour
  });
  const [LocationList, location, setLocation] = useList(
    LocationService.getLocations,
    {
      paramList: ["addrCity"],
      fullWidth: true,
    }
  );

  const [PractitionerList, practitioner, setPractitioner] = useList(
    EmployeeService.getPractitioners,
    {
      paramList: ["firstName", "lastName"],
      fullWidth: true,
    }
  );

  useEffect(() => {
    setLocation(selectedEvent.LocationId);
    setPractitioner(selectedEvent.practitionerId);
  }, [selectedEvent])


  useEffectOnUpdates(() => {

    if (editMode) {
      setDayOfAppointment({
        year: selectedEvent.dateofappointment.split('-')[0],
        month: selectedEvent.dateofappointment.split('-')[1],
        day: selectedEvent.dateofappointment.split('-')[2]
      });
      const data = {
        location: location,
        dateOfAppointment: selectedEvent.dateofappointment
      }
      getOpenSlots(data);
    }
  }, [editMode, location]);

  /**
   * on event reschedule
   */
  const handleReschedule = () => {

    const updatedEvent = {
      ...selectedEvent,
      starttime: openSlot.time.start,
      endtime: openSlot.time.end,
      duration: timeDiff(openSlot.time.start, openSlot.time.end),
      date: `${dayOfAppointment.year}-${dayOfAppointment.month}-${dayOfAppointment.day}`,
      locationid: location
    };

    setSelectedEvent({ ...updatedEvent });

    AppointmentService.update(updatedEvent).then(response => {

      if (response.code === 0) {
        setToast({ open: true, message: response.message });
        onUpdated();
        onClose();
      }
    }).catch(error => {
      if (isLocalDev) {
        console.log(error);
      }

      console.log(error.message);
    });

  }

  const handleCancel = () => {

    showConfirmationDialog((action) => {
      if (action === validLookup.confirmationDialogAction.ok) {
        handleAppointmentStatusChange({ target: { value: 'cancelled' } });
        onClose();
      }
    });
  }

  /**
   * when an available slot is clicked
   * we should move the selected event there (if not colliding)
   * @param {Object} slot 
   */
  const handleSlotClicked = (slot) => {

    setTimeOfAppointment({
      start: '',
      end: ''
    });
    const { starttime, endtime, practitionerid, status, col } = { ...slot };
    const slotsForPractitioner = resourceGroup.data;

    if (status === validLookup.validSlotStatus.available) {

      const existingEventPractitionerGroupIndex = slotsForPractitioner.findIndex(p => p.practitionerid === selectedEvent.practitionerid);
      const targetPractitionerGroupIndex = slotsForPractitioner.findIndex(p => p.practitionerid === practitionerid);

      // see if we have to move to another practitioner
      if (existingEventPractitionerGroupIndex !== targetPractitionerGroupIndex) {

        // assign new practitioner
        setSelectedEvent({ ...selectedEvent, practitionerid: practitionerid });

        const existingBookedSlot = slotsForPractitioner[existingEventPractitionerGroupIndex].bookedtime.find(bi => bi.id === selectedEvent.id);
        const existingBookedSlotIndex = slotsForPractitioner[existingEventPractitionerGroupIndex].bookedtime.findIndex(bi => bi.id === selectedEvent.id);

        setBookedSlots(slotsForPractitioner[targetPractitionerGroupIndex].bookedtime);

        /**
         * we only want to remove the event the user clicked on
         */
        if (existingBookedSlot) {
          // remove slot from the original place
          slotsForPractitioner[existingEventPractitionerGroupIndex].bookedtime.splice(existingBookedSlotIndex, 1);
        }
        setResourceGroup({ tick: new Date().getTime(), data: slotsForPractitioner });

        // if (existingBookedSlot) {
        //   // make sure there is no collision with an already booked slot on the target 
        //   if (isCollidingWithBookekdSlot({ starttime, endtime }, practitionerid, existingBookedSlot.id)) {

        //     setToast({ open: true, message: "Selected time overlaps with an existing appointment. Please choose different time." });
        //     setCanSaveTimeSelectDialog(false);
        //   } else {
        //     setCanSaveTimeSelectDialog(true);
        //   }
        // }

      } else {
        setBookedSlots([]);
      }


      //setBookedSlots(slotsForPractitioner[practitionerGroupIndex].bookedtime);
      setColumnAndSlotId({ column: targetPractitionerGroupIndex, slotId: -1 });

      setTimeSelectDialogTimes({
        availableStart: starttime,
        availableEnd: endtime
      });
      showTimeSelectDialog();
    }

  }

  /**
   * day on calendar is clicked
   * @param {Object} day 
   */
  const handleDayClicked = (day) => {

    const data = {
      location: location,
      dateOfAppointment: `${day.year}-${day.month}-${day.day}`
    }

    getOpenSlots(data);

  }

  const handleAppointmentStatusChange = (event) => {
    const newStatus = event.target.value;
    AppointmentService.updateStatus({ id: selectedEvent.id, newstatus: newStatus }).then(response => {

      if (response.code === 0) {
        setToast({ open: true, message: "Appointment updated" });
        setSelectedEvent({
          ...selectedEvent,
          status: newStatus
        });
        onUpdated();
      }
    }).catch(error => {
      if (isLocalDev) {
        console.log(error);
      }
      setToast({ open: true, message: error.data.message });
    });

  }

  const getBookedSlots = (practitionerid) => {
    const slotsForPractitioner = resourceGroup.data;
    const practitionerGroupIndex = slotsForPractitioner.findIndex(p => p.practitionerid === practitionerid);
    return slotsForPractitioner[practitionerGroupIndex].bookedtime;
  }

  const isCollidingWithBookekdSlot = (requestedTime, forPractitioner, self) => {

    if (self === -1) return;

    // get all the booked slots
    const bookedSlots = getBookedSlots(forPractitioner);
    const { starttime, endtime } = requestedTime;

    if (starttime && endtime) {
      for (let b = 0; b < bookedSlots.length; b++) {
        const booked = bookedSlots[b];
        const rst = timeFromString(starttime);
        const ret = timeFromString(endtime);
        const bst = timeFromString(booked.starttime);
        const bet = timeFromString(booked.endtime);

        // avoid self collision
        if (booked.id === self) continue;

        if ((bst >= rst && bst < ret) || (bet > rst && bet <= ret)) {

          setCollidesWith({ ...booked, column: columnAndSlotId.column, tick: new Date().getTime() });

          return true;
        }
      }
    }
    return false;
  }

  const handleTimeChange = (newTime, params) => {

    setTimeOfAppointment({
      start: newTime.start,
      end: newTime.end
    });
    const { column, slotId } = { ...params };
    const slotsForPractitioner = resourceGroup.data;

    //const slotIdx = slotsForPractitioner[params].bookedtime.findIndex(s => s.id === params)
    const bookedTimeIdx = slotsForPractitioner[column].bookedtime.findIndex(s => s.id === selectedEvent.id);
    const self = bookedTimeIdx >= 0 ? slotsForPractitioner[column].bookedtime[bookedTimeIdx].id : -1;

    if (bookedTimeIdx >= 0) {
      // existing one
      slotsForPractitioner[column].bookedtime[bookedTimeIdx].starttime = newTime.start;
      slotsForPractitioner[column].bookedtime[bookedTimeIdx].endtime = newTime.end;

    } else {
      // add new
      setColumnAndSlotId({ column: column, slotId: selectedEvent.id });

      slotsForPractitioner[column].bookedtime = [...slotsForPractitioner[column].bookedtime, {
        id: selectedEvent.id,
        starttime: newTime.start,
        endtime: newTime.end,
        practitioner: slotsForPractitioner[column].practitioner
      }];
    }

    if (isCollidingWithBookekdSlot({ starttime: newTime.start, endtime: newTime.end },
      slotsForPractitioner[column].practitionerid, self)) {
      setToast({ open: true, message: "Selected time overlaps with an existing appointment. Please choose different time." });
      setCanSaveTimeSelectDialog(false);
    } else {
      setResourceGroup({ tick: new Date().getTime(), data: slotsForPractitioner });
      setCanSaveTimeSelectDialog(true);
    }

  };

  const handleSave = (time, param) => {
    const { column, slotId } = { ...param };

    //setDuration(timeDiff(time.start, time.end));
    // check to make sure it's within the available time
    const slotsForPractitioner = resourceGroup.data;
    setOpenSlot({ time: time, practitionerid: slotsForPractitioner[column].practitionerid, practitioner: slotsForPractitioner[column].name });

  }

  /**
   * do nothing
   * @param {*} param 
   */
  const handleDelete = (param) => {
    setCollidesWith({ starttime: '', endtime: '', column: 0, tick: new Date().getTime() });

    const { column, slotId } = { ...param };
    const slotsForPractitioner = resourceGroup.data;
    slotsForPractitioner[column].bookedtime = slotsForPractitioner[column].bookedtime.filter(b => b.id !== slotId)

    setResourceGroup({ tick: new Date().getTime(), data: slotsForPractitioner });
  }

  /**
   * When a booked event is clicked
   * @param {Object} event 
   */
  const handleEventClicked = (event) => {

    // allow only if same event
    if (event.id === selectedEvent.id) {

      const slotsForPractitioner = resourceGroup.data;
      const practitionerGroupIndex = slotsForPractitioner.findIndex(p => p.practitionerid === event.practitionerId);

      const {startTime, endTime, duration} = {...slotsForPractitioner[practitionerGroupIndex].availabletime};

      setDuration(duration);

      setTimeOfAppointment({
        start: event.starttime,
        end: event.endtime
      });

      setTimeSelectDialogTimes({
        availableStart: startTime,
        availableEnd: endTime
      });
      setColumnAndSlotId({ column: event.col - 1, slotId: event.id });

      showTimeSelectDialog();
    }

  }

  function getOpenSlots(data) {
    ScheduleService.getOpenSlotsV2(data).then(result => {

      if (result.code === 0) {
        setResourceGroup({ tick: new Date().getTime(), data: result.data });
      }
    }).catch(error => console.log(error));
  }

  return (
    <>
      <Dialog onClose={onClose} open={open} fullWidth maxWidth={editMode ? "xl" : "md"}>
        <DialogTitle sx={{ background: Colors.primary }}>
          <Box display={"flex"} alignItems="center">
            <Typography variant="h5" flexGrow={1} sx={{ color: Colors.white }}>
              {editMode ? 'Update Appointment' : 'Appointment Details'}
              {/* {format(event.date, "eee MMMM dd u")} */}
            </Typography>
            {selectedEvent.status !== validLookup.validAppointmentStatus.cancelled &&
              <IconButton onClick={() => setEditMode(!editMode)}>
                <EditIcon sx={{ fontSize: '3rem', border: `1px solid ${Colors.dove_gray}`, borderRadius: '50%', p: 1, color: Colors.white }} />
              </IconButton>
            }

          </Box>
        </DialogTitle>
        <DialogContent>
          {editMode ?
            <Grid container columnSpacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} md={2}>
                <Box
                  sx={{
                    marginTop: 1,
                    padding: 1,
                  }}
                >
                  <TextField size={"sm"} fullWidth id="name" label="Name" type="text" value="Fix me" />

                  <Box
                    sx={{
                      marginTop: `${Styles.margins.margin}px`,
                    }}
                  >
                    {/* <PractitionerList label={"Practitioner"} sx={{ mb: 2 }} /> */}
                    <LocationList label={"Location"} sx={{ mb: 2 }} />
                    {/* <SelectPractioner 
            practioner={practioner} 
            setPractioner={setPractioner} 
            sx={{mb: 1}} fullWidth={true} />
            <SelectLocation 
            location={location} 
            setLocation={setLocation}
            fullWidth={true}  /> */}
                  </Box>
                  <TextField fullWidth label="Notes" multiline maxRows={4} value={selectedEvent.notes || ''}
                    onChange={(event) => setSelectedEvent({ ...selectedEvent, notes: event.target.value })} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <MiniCalendar
                  dayOfAppointment={dayOfAppointment}
                  setDayOfAppointment={setDayOfAppointment}
                  onDayClicked={handleDayClicked}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {/* <DoctorOpenSlotList
                setDuration={30}
                openSlots={availableSlots}
                activeTile={activeTile}
                setActiveTile={setActiveTile}
                onOpenTileSelect={handleOpenTileSelect} /> */}
                <GroupByPractitioners
                  resources={resourceGroup}
                  onAvailableSlotClicked={handleSlotClicked}
                  collidesWith={collidesWith}
                  onEventClicked={handleEventClicked}
                  forDate={dayOfAppointment
                    &&
                    dateFromString(`${dayOfAppointment.year}-${dayOfAppointment.month}-${dayOfAppointment.day}`)
                  }
                />
              </Grid>
            </Grid>
            : (
              <Box sx={{ mt: 2 }}>
                <Box display={"flex"} alignItems="baseline">
                  <Typography sx={{ fontWeight: 'bold', fontSize: '1.25rem', mb: 2 }} flexGrow={1}>Name</Typography>

                  {selectedEvent.status !== validLookup.validAppointmentStatus.cancelled &&
                  <Select disabled={selectedEvent.status === validLookup.validAppointmentStatus.cancelled} value={selectedEvent.status} variant="standard" onChange={handleAppointmentStatusChange}>
                    {
                      Object.keys(validLookup.validAppointmentStatus).filter(v => v !== validLookup.validAppointmentStatus.cancelled).map(vas => {
                        return (
                          <MenuItem key={vas} value={vas}>{toTitleCase(vas)}</MenuItem>
                        )
                      })
                    }

                  </Select>}
                </Box>


                <Grid container rowSpacing={2}>
                  <Grid item container columnSpacing={4}>
                    <Grid item xs={4}>
                      <Typography sx={{ fontWeight: 'bold' }}>Location</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{selectedEvent.location}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container columnSpacing={4}>
                    <Grid item xs={4}>
                      <Typography sx={{ fontWeight: 'bold' }}>Time</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{selectedEvent.start}{"-"}{selectedEvent.end}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container columnSpacing={4}>
                    <Grid item xs={4}>
                      <Typography sx={{ fontWeight: 'bold' }}>Duration</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{selectedEvent.duration}{" "}min</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container columnSpacing={4}>
                    <Grid item xs={4}>
                      <Typography sx={{ fontWeight: 'bold' }}>Practitioner</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{selectedEvent.practitioner}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container columnSpacing={4}>
                    <Grid item xs={4}>
                      <Typography sx={{ fontWeight: 'bold' }}>Service</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{selectedEvent.service}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container columnSpacing={4}>
                    <Grid item xs={4}>
                      <Typography sx={{ fontWeight: 'bold' }}>Notes</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{selectedEvent.notes || ''}</Typography>
                    </Grid>
                  </Grid>
                </Grid>

              </Box>
            )
          }
        </DialogContent>
        <DialogActions>
          {editMode && <Button onClick={handleReschedule} >Reschedule</Button>}
          {selectedEvent.id > 0 && 
          !editMode && 
          selectedEvent.status !== validLookup.validAppointmentStatus.cancelled &&
          <Button onClick={handleCancel} color="danger">
            Cancel Appointment
          </Button>}
          <Button onClick={onClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <TimeSelectDialog
        hideDelete={true}
        startAt={timeOfAppointment.start}
        params={columnAndSlotId}
        endAt={timeOfAppointment.end}
        availableStart={timeSelectDialogTimes.availableStart}
        availableEnd={timeSelectDialogTimes.availableEnd}
        bookedSlots={bookedSlots}
        canSave={canSaveTimeSelectDialog}
        onSave={handleSave}
        onDelete={handleDelete}
        duration={duration}
        onTimeChange={handleTimeChange} />

      <ConfirmationDialogComp message={"Are you sure you want to cancel the appointment?"} label={{
        positive: "Yes",
        negative: "No"
      }}/>
    </>
  );

}
