import { useCallback, useRef, useState } from "react";


export default function useDialogModal(Component) {

    const [open, setOpen] = useState(false);
    const listener = useRef(null);
    const [args, setArgs] = useState({});

    const openDialog = useCallback((l, props) => {
        setOpen(true);

        if( l ) {
            listener.current = l;
        }

        if( props ) {
            setArgs(props);
        } else {
            setArgs(null);
        }

    },[]);

    const handleClose = () => {
        setOpen(false);
        listener.current = null;
    }

    const DialogComponent = useCallback(({...props}) => {
        if (!open) return null;
        const { ignoreBackdropClick } = props;

        const onClose = (event, reason) => {
            if( reason === 'backdropClick' && ignoreBackdropClick ) return;
            
            handleClose();
        }

        if (Component) {
            return (
                <Component open={open} onClose={onClose} {...props} {...args} listener={listener.current} />
            )
        }
    }, [open, Component]);
    
    return [DialogComponent, openDialog, handleClose]
}

