import { Box, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material";
import { ButtonWithIcon } from "../../../ui";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function FrameDetails() {

    return (
        <>
            <Grid container columnSpacing={1}>
                <Grid item md={3} xs={12} sx={{ mt: 1 }}>
                    <Box display={'flex'} justifyContent="center" alignItems={'center'}>
                        <Typography textAlign={'center'}>Frame Source</Typography>
                    </Box>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <InputLabel id="framesource-select-label">Source</InputLabel>
                        <Select
                            size="small"
                            labelId="framesource-select-label"
                            label="Source"
                            name="frameSource"
                            fullWidth>
                            <MenuItem value="1">House</MenuItem>
                            <MenuItem value="2">Patient</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={3} xs={12} sx={{ mt: 1 }}>
                    <Box display={'flex'} justifyContent="center" alignItems={'center'}>
                        <Typography textAlign={'center'}>Product Selection</Typography>
                    </Box>

                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <InputLabel id="framemanufacturer-select-label">Manufacturer</InputLabel>
                        <Select
                            size="small"
                            labelId="framemanufacturerselect-label"
                            label="Manufacturer"
                            name="frameManufacturer"
                            fullWidth>
                            <MenuItem value="1">Kenmark Eyeware</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <InputLabel id="framemodel-select-label">Model</InputLabel>
                        <Select
                            size="small"
                            labelId="framemodel-select-label"
                            label="Model"
                            name="frameModel"
                            fullWidth>
                            <MenuItem value="1">Model</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <InputLabel id="framematerial-select-label">Material</InputLabel>
                        <Select
                            size="small"
                            labelId="framematerial-select-label"
                            label="Frame Material"
                            name="frameMaterial"
                            fullWidth>
                            <MenuItem value="1">Regular</MenuItem>
                            <MenuItem value="2">Polar</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <InputLabel id="framecolor-select-label">Color</InputLabel>
                        <Select
                            size="small"
                            labelId="framecolor-select-label"
                            label="Color"
                            name="frameColor"
                            fullWidth>
                            <MenuItem value="1">Black</MenuItem>
                            <MenuItem value="1">Red</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <InputLabel id="framerimtype-select-label">Rim Type</InputLabel>
                        <Select
                            size="small"
                            labelId="framerimtype-select-label"
                            label="Rim Type"
                            name="frameRimType"
                            fullWidth>
                            <MenuItem value="1">Full-rimmed</MenuItem>
                            <MenuItem value="1">Semi-rimless</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={3} xs={12} sx={{ mt: 1 }}>
                    <Box display={'flex'} justifyContent="center" alignItems={'center'}>
                        <Typography textAlign={'center'}>Frame Size</Typography>
                    </Box>

                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <InputLabel id="framesizedbl-select-label">DBL</InputLabel>
                        <Select
                            size="small"
                            labelId="framesizedbl-select-label"
                            label="DBL"
                            name="frameSizeDbl"
                            fullWidth>
                            <MenuItem value="1">32</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <InputLabel id="framesizea-select-label">A</InputLabel>
                        <Select
                            size="small"
                            labelId="framesizea-select-label"
                            label="A"
                            name="frameSizeA"
                            fullWidth>
                            <MenuItem value="1">32</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <InputLabel id="framesizeb-select-label">B</InputLabel>
                        <Select
                            size="small"
                            labelId="framesizeb-select-label"
                            label="B"
                            name="frameSizeB"
                            fullWidth>
                            <MenuItem value="1">32</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <InputLabel id="framesizeed-select-label">ED</InputLabel>
                        <Select
                            size="small"
                            labelId="framesizeed-select-label"
                            label="ED"
                            name="frameSizeEd"
                            fullWidth>
                            <MenuItem value="1">32</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <InputLabel id="framesizetemple-select-label">Temple</InputLabel>
                        <Select
                            size="small"
                            labelId="framesizetemple-select-label"
                            label="Temple"
                            name="frameSizeTemple"
                            fullWidth>
                            <MenuItem value="1">32</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <TextField
                name="notes"
                multiline rows={2} id="lens-notes" label="Notes" variant="standard" fullWidth />
        </>

    )
}