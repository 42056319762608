import { Box, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material";
import { ButtonWithIcon } from "../../../ui";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function LensDetails() {

    return (
        <>
            <Grid container columnSpacing={1}>
                <Grid item md={3} xs={12} sx={{ mt: 1 }}>
                    <Box display={'flex'} justifyContent="center" alignItems={'center'}>
                        <Typography textAlign={'center'}>OD</Typography>
                        <Tooltip title="copy to OS">
                            <IconButton>
                                <ContentCopyIcon />
                            </IconButton>
                        </Tooltip>

                    </Box>

                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <InputLabel id="od-lensbrand-select-label">Brand</InputLabel>
                        <Select
                            size="small"
                            labelId="od-lensbrand-select-label"
                            label="Brand"
                            name="odLensBrand"
                            fullWidth>
                            <MenuItem value="1">House</MenuItem>
                            <MenuItem value="2">Nikon</MenuItem>
                            <MenuItem value="3">Essilor</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <InputLabel id="od-lensmodel-select-label">Model</InputLabel>
                        <Select
                            size="small"
                            labelId="od-lensmodel-select-label"
                            label="Model"
                            name="odLensModel"
                            fullWidth>
                            <MenuItem value="1">Solo</MenuItem>
                            <MenuItem value="1">Relaxsee</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <InputLabel id="od-lenstype-select-label">Material</InputLabel>
                        <Select
                            size="small"
                            labelId="od-lensmaterial-select-label"
                            label="Lens Material"
                            name="odLensMaterial"
                            fullWidth>
                            <MenuItem value="1">Regular</MenuItem>
                            <MenuItem value="2">Polar</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <InputLabel id="od-lensstyle-select-label">Index</InputLabel>
                        <Select
                            size="small"
                            labelId="od-lensindex-select-label"
                            label="Index"
                            name="odLensIndex"
                            fullWidth>
                            <MenuItem value="1">1.5</MenuItem>
                            <MenuItem value="1">Poly</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={3} xs={12} sx={{ mt: 1 }}>
                    <Box display={'flex'} justifyContent="center" alignItems={'center'}>
                        <Typography textAlign={'center'}>OS</Typography>
                        <Tooltip title="copy to OD">
                            <IconButton>
                                <ContentCopyIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>

                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <InputLabel id="os-lensbrand-select-label">Brand</InputLabel>
                        <Select
                            size="small"
                            labelId="os-lensbrand-select-label"
                            label="Brand"
                            name="osLensBrand"
                            fullWidth>
                            <MenuItem value="1">House</MenuItem>
                            <MenuItem value="2">Nikon</MenuItem>
                            <MenuItem value="3">Essilor</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <InputLabel id="os-lensmodel-select-label">Model</InputLabel>
                        <Select
                            size="small"
                            labelId="os-lensmodel-select-label"
                            label="Model"
                            name="osLensModel"
                            fullWidth>
                            <MenuItem value="1">Solo</MenuItem>
                            <MenuItem value="1">Relaxsee</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <InputLabel id="os-lenstype-select-label">Material</InputLabel>
                        <Select
                            size="small"
                            labelId="os-lensmaterial-select-label"
                            label="Lens Material"
                            name="osLensMaterial"
                            fullWidth>
                            <MenuItem value="1">Regular</MenuItem>
                            <MenuItem value="2">Polar</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <InputLabel id="os-lensstyle-select-label">Index</InputLabel>
                        <Select
                            size="small"
                            labelId="os-lensindex-select-label"
                            label="Index"
                            name="osLensIndex"
                            fullWidth>
                            <MenuItem value="1">1.5</MenuItem>
                            <MenuItem value="1">Poly</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={3} xs={12} sx={{ mt: 3 }}>
                    <Typography textAlign={'center'}>Tint</Typography>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <InputLabel id="os-lensthickness-select-label">Color</InputLabel>
                        <Select
                            size="small"
                            labelId="lenstintcolor-select-label"
                            label="Color"
                            name="lensColor"
                            fullWidth>
                            <MenuItem value="1">Grey</MenuItem>
                            <MenuItem value="2">Green</MenuItem>
                            <MenuItem value="3">Brown</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <InputLabel id="os-lensthickness-select-label">Type</InputLabel>
                        <Select
                            size="small"
                            labelId="lenstinttype-select-label"
                            label="Type"
                            name="tintType"
                            fullWidth>
                            <MenuItem value="1">Solid</MenuItem>
                            <MenuItem value="2">Gradient</MenuItem>
                            <MenuItem value="3">Match</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <TextField label="Density" name="lensDensity" size="small"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                    </FormControl>

                </Grid>
                <Grid item md={3} xs={12} sx={{ mt: 3 }}>
                    <Typography textAlign={'center'}>Coating</Typography>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <InputLabel id="os-lensthickness-select-label">Coating options</InputLabel>
                        <Select
                            size="small"
                            labelId="lenstintcolor-select-label"
                            label="Color"
                            name="lensColor"
                            fullWidth>
                            <MenuItem value="1">HCC</MenuItem>
                            <MenuItem value="2">Blue</MenuItem>
                            <MenuItem value="3">ICE</MenuItem>
                        </Select>
                    </FormControl>

                </Grid>
            </Grid>

            <TextField
                name="notes"
                multiline rows={2} id="lens-notes" label="Notes" variant="standard" fullWidth />
        </>

    )
}