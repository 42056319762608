/**
 * Whenever an event gets added this gets notified and is displayed on the calendar
 */

import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useCalendarContext } from "../../../state/calendar";
import { OneSlot, EventTime } from "../../../styles/calendar";
import { eventSlotColor } from "../../../utils";
import {
  getColumnPosition,
  getHeight,
  getRowPosition,
  sortcomparer,
  sortcomparerByDuration,
} from "../helpers";

/**
 * How far apart events should be before they are shifted
 */
const minDifferenceThreshold = 8;

export default function Events({
  calendarEvents,
  backgroundEvents,
  onEventAdded,
  onEventEdit,
  onEventRemoved,
  onEventClicked,
  onEventResized,
  onEventMoved,
}) {
  const daysInAWeek = 7;
  let multiplier = 1;
  const [events, setEvents] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const { state, addEventListener } = useCalendarContext();

  const processWeekEvents = () => {
    // sort the events
    calendarEvents.sort(sortcomparer);

    const groupByCol = new Map();

    for (var i = 0; i < calendarEvents.length; i++) {
      const event = calendarEvents[i];

      const colPos = getColumnPosition(event.date);
      const rowPos = getRowPosition(event.starttime);

      event.col = colPos;
      event.row = rowPos;

      if (!event.leftOffset) event.leftOffset = 0;

      // we give each row a depth that each child on that row inherits
      let temp = calendarEvents.slice(i + 1, calendarEvents.length);
      temp = temp.filter((t) => t.date === event.date);

      for (var j = 0; j < temp.length; j++) {
        // if not on same row
        const overLapsWith = temp.find(
          (c) =>
            Math.abs(rowPos - getRowPosition(c.starttime)) <
            minDifferenceThreshold &&
            getRowPosition(c.starttime) < getRowPosition(event.endtime)
        );

        /* and only if they are not on same line */
        if (
          overLapsWith &&
          rowPos - getRowPosition(overLapsWith.starttime) !== 0
        ) {
          overLapsWith.leftOffset = 3.5 + event.leftOffset;
        }
        temp.splice(j, 1);
      }

      if (groupByCol.has(colPos)) {
        // this gives the row map
        const rowMapAtCol = groupByCol.get(colPos);
        // this gives the array
        const eventsAtRow = rowMapAtCol.get(rowPos);

        if (eventsAtRow) {
          groupByCol.set(
            colPos,
            rowMapAtCol.set(rowPos, [...eventsAtRow, event])
          );
        } else {
          groupByCol.get(colPos).set(rowPos, [event]);
        }
      } else {
        groupByCol.set(colPos, new Map().set(rowPos, [event]));
      }
    }

    // render the events
    //console.log(groupByCol);

    groupByCol.forEach((eventsPerCol, col) => {
      eventsPerCol.forEach((eventsPerRow, row) => {
        var offset = 0;
        // the depth is per row
        const leftOffset = eventsPerRow[0].leftOffset;
        eventsPerRow.sort(sortcomparerByDuration);
        eventsPerRow.map((event, index) => {
          if (offset === eventsPerRow.length - 1) {
            multiplier = 0.95;
          }

          const perc = 100 / (daysInAWeek + 1 - col);
          const percW = Math.floor(perc / eventsPerRow.length);
          let width = percW * multiplier;
          const height = getHeight(event.starttime, event.endtime);
          /**
           * for last event don't shift by the offset otherwise it will spill into next column
           */
          let left =
            percW * offset +
            (index === eventsPerRow.length - 1 ? -0.25 : leftOffset);
          if (eventsPerRow.length === 1) {
            width -= leftOffset;
            left = percW * offset + leftOffset;
          }

          const oneEvent = {
            width: width,
            height: height,
            left: left,
            row: row,
            col: col,
            background: eventSlotColor(col),
            ...event,
          };

          setEvents((e) => [...e, oneEvent]);
          offset++;
        });
      });
    });

  };

  const processDayEvents = () => {

    // sort the events
    calendarEvents.sort(sortcomparer);

    const groupByCol = new Map();

    for (var i = 0; i < calendarEvents.length; i++) {
      const event = calendarEvents[i];

      const colPos = 0; //getColumnPosition(event.date);
      const rowPos = getRowPosition(event.starttime);

      event.col = colPos;
      event.row = rowPos;

      if (!event.leftOffset) event.leftOffset = 0;

      // we give each row a depth that each child on that row inherits
      let temp = calendarEvents.slice(i + 1, calendarEvents.length);
      temp = temp.filter((t) => t.date === event.date);

      for (var j = 0; j < temp.length; j++) {
        // if not on same row
        const overLapsWith = temp.find(
          (c) =>
            Math.abs(rowPos - getRowPosition(c.starttime)) <
            minDifferenceThreshold &&
            getRowPosition(c.starttime) < getRowPosition(event.endtime)
        );

        /* and only if they are not on same line */
        if (
          overLapsWith &&
          rowPos - getRowPosition(overLapsWith.starttime) !== 0
        ) {
          overLapsWith.leftOffset = 3.5 + event.leftOffset;
        }
        temp.splice(j, 1);
      }

      if (groupByCol.has(colPos)) {
        // this gives the row map
        const rowMapAtCol = groupByCol.get(colPos);
        // this gives the array
        const eventsAtRow = rowMapAtCol.get(rowPos);

        if (eventsAtRow) {
          groupByCol.set(
            colPos,
            rowMapAtCol.set(rowPos, [...eventsAtRow, event])
          );
        } else {
          groupByCol.get(colPos).set(rowPos, [event]);
        }
      } else {
        groupByCol.set(colPos, new Map().set(rowPos, [event]));
      }
    }

    // render the events
    //console.log(groupByCol);

    groupByCol.forEach((eventsPerCol, col) => {
      eventsPerCol.forEach((eventsPerRow, row) => {
        
        var offset = 0;
        // the depth is per row
        const leftOffset = eventsPerRow[0].leftOffset;
        eventsPerRow.sort(sortcomparerByDuration);
        eventsPerRow.map((event, index) => {
          if (offset === eventsPerRow.length - 1) {
            multiplier = 0.95;
          }

          const perc = 100;// / (daysInAWeek + 1 - col);
          const percW = Math.floor(perc / eventsPerRow.length);

          let width = percW * multiplier;
          const height = getHeight(event.starttime, event.endtime);
          /**
           * for last event don't shift by the offset otherwise it will spill into next column
           */
          let left =
            percW * offset +
            (index === eventsPerRow.length - 1 ? -0.25 : leftOffset);
          if (eventsPerRow.length === 1) {
            width -= leftOffset;
            left = percW * offset + leftOffset;
          }

          const oneEvent = {
            width: width,
            height: height,
            left: left,
            row: row,
            col: col,
            background: eventSlotColor(col),
            ...event,
          };

          setEvents((e) => [...e, oneEvent]);
          offset++;
        });
      });
    });
  }

  useEffect(() => {
    setEvents([]);
    if (state.activeView === 'week') {
      processWeekEvents();
    } else if (state.activeView === 'day') {
        processDayEvents();
    } else {
      throw new Error("Invalid view");
    }

    const sub = addEventListener((event) => {
      const start = event.data.start;
      const end = event.data.end;
      const eventToAdd = {
        id: Math.floor(Math.random() * 1000),
        date: format(start, "yyyy-MM-dd"),
        starttime: format(start, "HH:mm"),
        endtime: format(end, "HH:mm"),
        name: format(start, "yyyy-MM-dd"),
        locationid: event.data.locationid,
        practitionerid: event.data.practitionerid,
      };
      switch (event.type) {
        case "add":
          onEventAdded(eventToAdd);
          break;
        case "edit":
          // keep same id
          eventToAdd.id = event.data.id;
          onEventEdit(eventToAdd);
          break;
        case "remove":
          onEventRemoved(event);
          break;
        default:
          break;
      }
    });

    return () => {
      sub();
    };
  }, [calendarEvents]);

  const processBackgroundEvents = () => {
    setAvailableSlots([]);

    if (state.activeView === "day") {
      // process available slots
      if (backgroundEvents.length > 0) {
        const slot = backgroundEvents[0];
        const startTime = slot.starttime;
        const endTime = slot.endtime;

        // get col pos
        // col pos is same as it's index in the array
        const col = 1;

        // get row pos
        const row = getRowPosition(startTime);

        let width = 100;
        const availableSlotheight = getHeight(startTime, endTime);

        const oneSlot = {
          width: width,
          height: availableSlotheight,
          left: 0,
          row: row,
          col: col,
          ...slot,
        };
        setAvailableSlots((e) => [...e, oneSlot]);
      }

    } else {
      // process available slots
      for (var i = 0; i < backgroundEvents.length; i++) {
        const slot = backgroundEvents[i];
        const startTime = slot.starttime;
        const endTime = slot.endtime;

        // get col pos
        // col pos is same as it's index in the array
        const col = slot.dayofweek + 1;

        // get row pos
        const row = getRowPosition(startTime);

        const perc = 100 / (7 - slot.dayofweek);
        const percW = Math.floor(perc);
        let width = percW * 1;
        const availableSlotheight = getHeight(startTime, endTime);

        const oneSlot = {
          width: width,
          height: availableSlotheight,
          left: 0,
          row: row,
          col: col,
          ...slot,
        };
        setAvailableSlots((e) => [...e, oneSlot]);
      }
    }
  };

  useEffect(() => {
    if (backgroundEvents) processBackgroundEvents();
  }, [backgroundEvents]);

  const handleDragStart = (ev, event) => {
    //console.log(event);
    ev.dataTransfer.setData("text/plain", JSON.stringify(event));
    //console.log(ev.dataTransfer);
    event.start = "drag";
  };

  const renderEvents = events.map((event, index) => {
    return (
      <OneSlot
        hover={true}
        onDragStart={(ev) => handleDragStart(ev, event)}
        draggable="true"
        key={index}
        width={event.width}
        height={event.height}
        status={event.status}
        row={event.row}
        column={event.col}
        left={event.left}
        background={event.background}
        onClick={() => onEventClicked(event)}
      >
        {/* <EventName>{event.name}</EventName> */}
        <EventTime variant="caption" sx={{ ml: 1 }}>
          {event.starttime}
          {"-"}
          {event.endtime}
        </EventTime>
      </OneSlot>
    );
  });

  const renderAvailableSlots = availableSlots.map((a, i) => {
    return (
      <OneSlot
        hover={false}
        draggable="false"
        key={i}
        row={a.row}
        {...a}
        column={a.col}
      />
    );
  });

  return (
    <>
      {renderEvents}
      {renderAvailableSlots}
    </>
  );
}
