import *  as yup from 'yup';

export const validationSchema = yup.object({
    firstName: yup
        .string('First name')
        .required("First name is required"),
    lastName: yup
        .string('Last name')
        .required("Last name is required"),
    phone1: yup
        .string('Phone')
        .phone("CA", "Plese enter a valid phone number")
        .required("At least one phone is required"),
    birthdate: yup
    .date()
    .required("Date of birth is required"),
    sex: yup
    .string()
    .required("Gender selection is required")
});