import { capitalize, List, ListItem, ListItemText, ListSubheader,Box } from "@mui/material";
import TextField from '@mui/material/TextField';
import { Colors } from "../../../../../styles/theme";
import { niceDate } from "../../../../../utils";

export default function ConfirmAppointmentDetails({
  address,
  dayOfAppointment,
  openSlot,
  contactInfo,
  duration,
  notes,
  setNotes
}) {

  return (
    <Box sx={{ width: "100%" }}>
      <List
        sx={{ width: "100%", background: Colors.dove_gray }}
        subheader={
          <ListSubheader
            sx={{
              background: Colors.black,
              color: Colors.white,
            }}
          >
            Location
          </ListSubheader>
        }
      >
        <ListItem>
          <ListItemText>{address}</ListItemText>
        </ListItem>
      </List>
      <List
        sx={{ width: "100%", background: Colors.dove_gray }}
        subheader={
          <ListSubheader
            sx={{
              background: Colors.black,
              color: Colors.white,
            }}
          >
            Date
          </ListSubheader>
        }
      >
        <ListItem>
          <ListItemText>
            Date: {niceDate(dayOfAppointment)}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Time: {openSlot.time.start}{"-"}{openSlot.time.end}{openSlot.ampm || ''}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Duration: {duration} min
          </ListItemText>
        </ListItem>
      </List>
      <List
        sx={{ width: "100%", background: Colors.dove_gray }}
        subheader={
          <ListSubheader
            sx={{
              background: Colors.black,
              color: Colors.white,
            }}
          >
            Practitioner
          </ListSubheader>
        }
      >
        <ListItem>
          <ListItemText>{openSlot.practitioner}</ListItemText>
        </ListItem>
      </List>
      <List
        sx={{ width: "100%", background: Colors.dove_gray }}
        subheader={
          <ListSubheader
            sx={{
              background: Colors.black,
              color: Colors.white,
            }}
          >
            Contact Info
          </ListSubheader>
        }
      >
        <ListItem>
          <ListItemText>
            Name: {capitalize(contactInfo.fullName)}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Date of birth: {capitalize(contactInfo.birthdate)}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>Phone: {contactInfo.phone1}</ListItemText>
        </ListItem>
      </List>
      <TextField multiline value={notes} onChange={(event) => setNotes(event.target.value)}
        maxRows={4} fullWidth variant="standard" label="Notes" />
    </Box>
  );
}
