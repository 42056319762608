export const contactLensModel = {
    practitioner: '',
    type: '',
    manufacturer: '',
    model: '',
    odSphere: '',
    odCylinder: '',
    odAxis: '',
    odAdd: '',
    odBc: '',
    odDiameter: '',
    osSphere: '',
    osCylinder: '',
    osAxis: '',
    osAdd: '',
    osBc: '',
    osDiameter: '',
    comment: '',
};