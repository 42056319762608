import { useDroppable } from "@dnd-kit/core";
import { Box } from "@mui/material";
import { useEffect } from "react";
import validLookup from "../../utils/validLookup";
import { slotBackground } from "./slot";

export default function DroppableSlot(props) {

    const { isOver, setNodeRef } = useDroppable({
        id: props.id,
        data: {
            practitionerid: props.practitionerid
        }
    });

    return (
        <Box
            id={props.id}
            ref={setNodeRef}
            sx={{
                cursor: 'pointer',
                position: 'absolute',
                top: `${(props.offset || 0) + props.rect.top}px`,
                background: isOver ? slotBackground(props.status, 0.5) : slotBackground(props.status),
                width: '100%',
                height: `${props.rect.height}px`,
                zIndex: (props.status === validLookup.validSlotStatus.available || props.status === validLookup.validSlotStatus.break) ? 1 : 999,
                left: 0,
            }}
            onClick={props.onClick}
        >{props.name} {props.status}</Box>
    )
}