import { Paper, Table, TableHead, TableBody, TableContainer, TableRow, TableCell, Typography, alpha} from "@mui/material";
import { parsePhoneNumber } from "libphonenumber-js";
import { Colors } from "../../styles/theme";
import { withPagination } from "../hoc/withPagination";
import { TableHeaderCell } from "../ui";

export default function PatientTable({
    patients,
    onSelect
}) {

    const handleRowClick = (row) => {
        onSelect(row);
    }

    return (
        <>

            {
                patients.length > 0 ?
                    <TableContainer component={Paper}>

                        <Table>
                            <TableHead>
                                <TableRow>
                                    {/* <TableHeaderCell>
                            ID
                        </TableHeaderCell> */}
                                    <TableHeaderCell align="center">
                                        First Name
                                    </TableHeaderCell>
                                    <TableHeaderCell align="center">
                                        Last Name
                                    </TableHeaderCell>
                                    <TableHeaderCell align="center">
                                        Phone
                                    </TableHeaderCell>
                                    <TableHeaderCell align="center">
                                        Date of Birth
                                    </TableHeaderCell>
                                    <TableHeaderCell align="center">
                                        Email
                                    </TableHeaderCell>
                                    {/* <TableHeaderCell>
                            Mobile Phone
                        </TableHeaderCell>
                        <TableHeaderCell>
                            Work Phone
                        </TableHeaderCell> */}
                                    {/* <TableHeaderCell align="center">
                                        Actions
                                    </TableHeaderCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    patients.map(p =>
                                        <TableRow sx={{
                                            cursor: 'pointer',
                                            '&:hover': {
                                                background: alpha(Colors.muted, 0.2)
                                            }
                                        }} key={p.id} onClick={() => handleRowClick(p)} >
                                            {/* <TableCell>{p.id}</TableCell> */}
                                            <TableCell align="center">{p.firstName}</TableCell>
                                            <TableCell align="center">{p.lastName}</TableCell>
                                            <TableCell align="center">{parsePhoneNumber(`+1${p.phone1}`).formatNational()}</TableCell>
                                            <TableCell align="center">Nov 05, 2022</TableCell>
                                            <TableCell align="center">abc@email.com</TableCell>
                                        </TableRow>
                                    )
                                }

                            </TableBody>
                        </Table>

                    </TableContainer>
                    :
                    <Typography variant="h5" sx={{ color: Colors.light }} textAlign={"center"}>Search by name or phone</Typography>
            }
        </>
    )
}


export const PatientTableWithPagination = withPagination(PatientTable);

