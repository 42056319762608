
import { useRef } from "react";
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Colors } from "../../styles/theme";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Box } from "@mui/material";
import validLookup from "../../utils/validLookup";

function PaperComponent(props) {
    const nodeRef = useRef(null);
    return (
        <Draggable
            nodeRef={nodeRef}
            handle="#confirmation-dialog-title"
        >
            <Paper id="confirmation-dialog-title" ref={nodeRef} {...props} />
        </Draggable>
    );
}

export default function ConfirmationDialog({
    open,
    onClose,
    message,
    title,
    listener,
    label
}) {

    const handleCancel = () => {
        listener && listener(validLookup.confirmationDialogAction.cancel);
        onClose();
    }

    const handleOk = () => {
        listener && listener(validLookup.confirmationDialogAction.ok);
    }

    return (
        <Dialog onClose={onClose} open={open} fullWidth maxWidth={'xs'} PaperComponent={PaperComponent} >
            <DialogTitle sx={{ background: Colors.primary, mb: 4, color: Colors.white, cursor: 'move' }}
                aria-labelledby="choose-time-dialog-title">
                <Box display={"flex"} alignItems="center" justifyContent={'space-between'}>
                    {title || "Confirmation"}
                </Box>
            </DialogTitle>
            <DialogContent>
                {message}
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel}>
                    {label.negative}
                </Button>
                <Button onClick={handleOk} variant="contained">
                    {label.positive}
                </Button>
            </DialogActions>
        </Dialog>
    )
}