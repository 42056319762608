import { Box, TableBody, TableCell, TableContainer, TableHead, TableRow, Table, IconButton, 
    Button, Popper, Typography, Stack } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import useDialogModal from "../../../../hooks/useDialogModal";
import FileUploadDialog from "./fileUploadDialog";
import { useReducer } from "react";
import { niceDate } from "../../../../utils";
import { Colors } from "../../../../styles/theme";
import { ZIndex } from "../../../../styles/constants/enums";
import useConfirmationPopper from "../../../../hooks/useConfirmationPopper";

export default function OrderDocuments() {
    const [ConfirmationPopper, showConfirmationPopper] = useConfirmationPopper();
    const [FileUpload, showFileUploadDialog] = useDialogModal(FileUploadDialog);
    const [state, updateState] = useReducer((state, params) => {
        return { ...state, ...params }
    }, {
        files: [],
        deleteConfirmationPopper: false,
        selectedItem: null,
    });

    const handleUploadDocument = () => {
        showFileUploadDialog();
    }

    const handleFilesUpload = (files) => {
        updateState({
            files: [...state.files, ...files]
        });
    }

    const showDeleteItemPopper = (element, row) => {
        showConfirmationPopper(element.currentTarget, () => {
            let d = state.files.filter(d => d.name !== row.name);
            updateState({ selectedItem: null, files: d });
        });
        //updateState({ deleteConfirmationPopper: true, selectedItem: row, anchorEl: element.currentTarget });
    }

    return (
        <Box >
            <Button sx={{ mb: 1 }} variant="outlined" onClick={handleUploadDocument}>Upload document</Button>
            {
                state.files.length > 0 &&

                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Notes</TableCell>
                                <TableCell>Date uploaded</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                state.files.map((file, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{file.name}</TableCell>
                                        <TableCell>{file.notes}</TableCell>
                                        <TableCell>{niceDate(new Date())}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={(event) => showDeleteItemPopper(event, file)}>
                                                <DeleteForeverIcon color="danger" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            <FileUpload onUploadDone={handleFilesUpload} />
            <ConfirmationPopper />
        </Box>
    )
}