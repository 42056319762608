import { useState, useEffect, useRef } from "react";

export default function useMouseMovement(target) {

    const listener = useRef();
    const [transform, setTransform] = useState(null);

    useEffect(() => {
        if (target) {
            listener.current = new AbortController();
            target.addEventListener('mousemove', (event => {
                const { pageX, pageY, offsetX, offsetY, clientX, clientY } = event;

                const t = {
                    x: clientX,
                    y: clientY,
                }
                // const t = {
                //     x: Math.ceil(clientX / gridSize) * gridSize,
                //     y: Math.ceil(clientY / gridSize) * gridSize,
                // }
                setTransform({...t});
            }), { signal: listener.current.signal });
        }

        return () => {
            if (listener.current)
                listener.current.abort();
        }
    }, [target]);

    return transform;
}