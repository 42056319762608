import { Box, Button, Typography } from "@mui/material";
import { useReducer, useRef, useState } from "react";
import useEffectOnUpdates from "../../hooks/useEffectOnUpdates";
import { CalendarProvider } from "../../state/calendar";
import { ScheduleService } from "../../service/scheduleService";
import { addMinutes, differenceInMinutes, format } from "date-fns";
import useDialogModal from "../../hooks/useDialogModal";
import BookAppointmentDialog from "../appointments/dialog/book-appointment-dialog";
import { ZIndex } from "../../styles/constants/enums";
import { dateFromString, dateSetTime, niceTime, timeFromString } from "../../utils";
import ConfirmationDialog from "../ui/confirmationDialog";
import validLookup from "../../utils/validLookup";
import { AppointmentService } from "../../service/appointmentService";
import { toast } from "react-toastify";
import AppointmentDrawer from "./appointmentDrawer";
import BookAppointmentDrawer from "./bookAppointmentDrawer";
import { isLocalDev } from "../../utils/environment";
import AddScheduleDialog from "../admin/ManageSchedule/addScheduleDialog";
import EditSchedule from "./editSchedule";
import Calendar from "./calendar";

export default function Schedule() {

    const toastRef = useRef();

    // filter the practitioners
    const [location, setLocation] = useState('');
    const [practitionerState, setPractitionerState] = useState({
        selectedPractitioners: [],
        list: [],
        active: [],
        tick: new Date().getTime()
    });

    const [state, updateState] = useReducer((state, params) => {
        return { ...state, ...params }
    }, {
        lock: [],
        activeDay: null,
        activeWeek: null,
        activeSlot: null,
        draggedSlot: null,
        isEditSchedule: false,
        activeAction: null,
        activeView: 'day',
        timebarTime: null,
        bookAppointmentDrawerOpen: false,
        appointmentDrawer: false,
    });

    const [
        ConfirmationDialogComp,
        showConfirmationDialog,
        closeConfirmationDialog,
    ] = useDialogModal(ConfirmationDialog);

    const [BookAppointmentDialogModal, showBookAppointmentDialog] =
        useDialogModal(BookAppointmentDialog);
    const [move, setMove] = useState(false);
    const [collides, setCollides] = useState(null);

    /** Add schedule */
    const [ScheduleDialog, showScheduleDialog] =
        useDialogModal(AddScheduleDialog);

    useEffectOnUpdates(() => {
        if (location) {
            getAppointments();
        }

    }, [state.activeView, location, state.activeDay, state.activeWeek]);

    const handleWeekChanged = (activeWeek) => {
        updateState({ activeWeek: activeWeek });
    }

    const handleDayChanged = (activeDay) => {
        updateState({ activeDay: activeDay });
    }

    function getAppointments() {
        setCollides(null);
        const params = {};

        // filter by location
        if (location) {
            params['location'] = location.id;
        }
        // first filter by practioer
        // if (practitioner) {
        //     params['practitioner'] = practitioner;
        // }
        if (state.activeView === 'week') {
            if (!state.activeWeek) return;

            params['start'] = format(state.activeWeek.start, 'yyyy-MM-dd');
            params['end'] = format(state.activeWeek.end, 'yyyy-MM-dd');
        }

        if (state.activeView === 'day') {
            params['start'] = format(state.activeDay, 'yyyy-MM-dd');
            params['end'] = format(state.activeDay, 'yyyy-MM-dd');;
        }

        ScheduleService.getScheduleByLocation({ ...params })
            .then((result) => {

                const { code, data, message } = { ...result };
                let names = data.map(l => l.fullName);

                if (code === 0) {

                    if (state.lock.length > 0) {

                        const list = [];
                        // choose only the acitve 
                        data.forEach(d => {
                            const idx = state.lock.findIndex(ap => ap.id === d.id);
                            if (idx !== -1) {
                                list.push(d);
                            }
                        });
                        names = list.map(l => l.fullName);

                        setPractitionerState({ selectedPractitioners: names, list: data, active: list, tick: new Date().getTime() });
                    } else setPractitionerState({ selectedPractitioners: names, list: data, active: data, tick: new Date().getTime() });
                }
            })
            .catch((error) => console.log(error));
    }

    const isCollidingWithAnotherBookedslot = (slot, s, e) => {
        const start = s;//dateFromString(s, 'yyyy-MM-dd HH:mm:ss');
        const end = e;//dateFromString(e, 'yyyy-MM-dd HH:mm:ss');

        let collidesWith = null;
        practitionerState.active.every(p => {
            const slots = p.slots;
            const booked = slots.filter(bs => bs.ScheduleId
                && bs.id !== state.activeSlot.id
                && bs.dayOfWeek === slot.dayOfWeek
                && bs.practitionerId === slot.EmployeeId);

            booked.every(s => {

                const bStart = dateFromString(s.startDate, 'yyyy-MM-dd HH:mm:ss');
                const bEnd = dateFromString(s.endDate, 'yyyy-MM-dd HH:mm:ss');

                //if ((bStart >= start && bStart < end) || (bEnd > start && bEnd <= end)) {
                if (bStart > start && differenceInMinutes(bStart, start) < state.activeSlot.duration) {
                    collidesWith = s;

                    return false;
                }
                //}
                return true;
            });

            if (collidesWith) return false;
            return true;
        });

        return collidesWith;
    }

    const handleSlotClicked = async (slot, atTime) => {

        // check if move is active?
        if (move) {
            //console.log(activeSlot, slot);
            let srcstart = dateFromString(state.activeSlot.startDate, 'yyyy-MM-dd HH:mm:ss', true);
            // we need to take start_Date since startDate is when the schedule starts
            const newStart = dateSetTime(slot.start_Date, atTime);

            const newEnd = addMinutes(newStart, state.activeSlot.duration);

            const collidesWith = isCollidingWithAnotherBookedslot(slot, newStart, newEnd);

            if (collidesWith) {
                setCollides(collidesWith);
            } else {

                showConfirmationDialog(async (action) => {
                    if (action !== validLookup.confirmationDialogAction.ok) {
                        getAppointments();
                    } else {
                        const data = {
                            id: state.activeSlot.id,
                            startDate: newStart,
                            endDate: newEnd,
                            practitionerId: slot.EmployeeId,
                            locationId: location.id,
                            dayOfWeek: slot.dayOfWeek,
                            scheduleId: slot.id
                        }

                        // commit changes
                        const updateAppointmentResult = await AppointmentService.update(data);
                        const { code, message } = updateAppointmentResult;

                        if (code === 0) {
                            toast.success(message);
                            getAppointments();
                            setMove(false);
                            toast.dismiss(toastRef.current);
                            updateState({ activeSlot: null, appointmentDrawer: false });
                        } else {
                            toast.warn(message);
                        }
                        //console.log(updateAppointmentResult);
                    }
                    closeConfirmationDialog();
                }, {
                    title: 'Move this appointmet?',
                    message: "Please confirm if you would like to move this appointment?",
                });
            }
            //console.log(activeSlot, timebarTime, slot);
        } else {
            if (slot.status === validLookup.validSlotStatus.available) {
                if (state.isEditSchedule && slot.status === validLookup.validSlotStatus.available) {
                    updateState({ activeAction: 'edit', activeSlot: slot });
                } else if (!state.appointmentDrawer) {
                    updateState({bookAppointmentDrawerOpen: true, activeSlot: slot, timebarTime: atTime});
                }
            } else if (Object.keys(validLookup.validAppointmentStatus).indexOf(slot.status) !== -1) {
                updateState({bookAppointmentDrawerOpen: false,appointmentDrawer: true, activeSlot: slot});
            }
        }
    }

    const handleOnBook = () => {
        getAppointments();
    }

    const handleSlotCancelMove = () => {
        toast.dismiss(toastRef.current);
        setMove(false);
    }

    const handleSlotUpdate = async (param) => {
        param['id'] = state.activeSlot.id;

        const updateAppointmentResult = await AppointmentService.update(param);
        const { code, message } = updateAppointmentResult;

        if (code === 0) {
            toast.success(message);
            getAppointments();
            handleDrawerClose();
        } else {
            toast.warning('Failed to update appointment');
        }
    }

    const handleSlotCancel = async () => {
        const cancelAppointmentResult = await AppointmentService.updateStatus({ id: state.activeSlot.id, newstatus: validLookup.validSlotStatus.cancelled });
        const { code, message } = cancelAppointmentResult;

        if (code === 0) {
            toast.success(message);
            getAppointments();
            handleDrawerClose();
        } else {
            toast.warning('Failed to cancel appointment');
        }
    }

    const handleDrawerClose = () => {
        setCollides(null);
        setMove(false);
        toast.dismiss(toastRef.current);
        updateState({ activeSlot: null, activeAction: null, isEditSchedule: false, bookAppointmentDrawerOpen: false, appointmentDrawer: false });
    }

    const handleAppointmentBook = (param) => {
        /**
         * startDate
         * endData
         * notes
         * practitionerId,
         * locationId
         * patientId
         * dayOfWeek
         * scheduleId
         */

        const payload = {
            startdate: dateSetTime(state.activeSlot.start_Date, param.startTime),
            enddate: dateSetTime(state.activeSlot.start_Date, param.endTime),
            scheduleId: state.activeSlot.id,
            location: location.id,
            employee: 5,
            dayOfWeek: state.activeSlot.dayOfWeek,
            ...param
        };

        AppointmentService.bookAppointment(payload).then(response => {

            const { code, data, message } = response;
            if (code === 0) {
                getAppointments();
                toast.success("Appointment booked");
                updateState({ activeSlot: null, bookAppointmentDrawerOpen: false });

            } else {
                toast.warning(message);
            }
        }).catch(error => {
            toast.error(error.message);
            if (isLocalDev)
                console.log(error);
        });
    }

    /** Schedule */
    const handleAddSchedule = () => {
        showScheduleDialog();
    }

    const handleEditSchedule = () => {
        if (state.isEditSchedule) return;

        toastRef.current = toast.warning(() => <>
            <Box display={"flex"} >
                <Typography sx={{ fontWeight: 'bold', fontSize: '1.25rem' }}>Edit mode on</Typography>
                <Button onClick={() => {
                    handleDrawerClose();
                    toast.dismiss(toastRef.current);
                }}
                    variant="contained">Turn off</Button>
            </Box>
        </>, {
            position: "top-center",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
            closeButton: false,
        });
        updateState({ isEditSchedule: true });
    }

    const handleSaveSchedule = (data) => {

        ScheduleService.save(data).then(result => {
            const { code, message } = result;
            if (code === 0) {
                toast.info(message);
                //setEvents([...events, event]);
            }
        }).catch(error => {
            console.log(error);
            toast.error(error.message);
        });
    }

    const handleScheduleUpdate = (params) => {

        const payload = {
            scheduleid: state.activeSlot.id,
            startDate: dateSetTime(state.activeSlot.start_Date, params.startTime),
            endDate: dateSetTime(state.activeSlot.start_Date, params.endTime),
            ...params
        }

        ScheduleService.update(payload).then(response => {
            const { code, message } = response;
            if (code === 0) {
                toast.success(message);
                getAppointments();
            } else toast.info(message);

        }).catch(error => {
            if (isLocalDev) console.log(error);

            toast.error(error.message);
        });
    }

    const handleSlotMove = () => {

        //if move is already active then return
        if (move) return;

        toastRef.current = toast.warning(() => <>
            <Box display={"flex"} >
                <Typography sx={{ fontWeight: 'bold', fontSize: '1.25rem' }}>Click on an open slot</Typography>
                <Button onClick={handleSlotCancelMove} variant="contained">Cancel move</Button>
            </Box>
        </>, {
            position: "top-center",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
            closeButton: false,
        });

        setMove(true);
    };

    const handleSlotDragged = async (action,data) => {
        if (action !== validLookup.confirmationDialogAction.ok) {
            getAppointments();
        } else {
            // commit changes
            const updateAppointmentResult = await AppointmentService.update(data);
            const { code, message } = updateAppointmentResult;

            if (code === 0) {
                toast.success(message);
            } else {
                toast.warn(message);
            }
            //console.log(updateAppointmentResult);
        }
    }

    return (
        <>
            <CalendarProvider>
                <Box display={"flex"}>
                    <Calendar
                        open={state.appointmentDrawer || state.bookAppointmentDrawerOpen || state.activeAction === 'edit'}
                        handleWeekChanged={handleWeekChanged}
                        handleDayChanged={handleDayChanged}
                        handleAddSchedule={handleAddSchedule}
                        handleEditSchedule={handleEditSchedule}
                        handleSlotClicked={handleSlotClicked}
                        slotDragged={handleSlotDragged}
                        location={location}
                        setLocation={setLocation}
                        state={state}
                        updateState={updateState}
                        practitionerState={practitionerState}
                        setPractitionerState={setPractitionerState}
                    />

                    <Box sx={{ zIndex: state.activeSlot ? ZIndex.DRAWER + 10 : -1 }}>
                        {
                            state.activeAction !== 'edit' ?
                                <>
                                    <AppointmentDrawer
                                        drawerOpen={state.appointmentDrawer}
                                        handleSlotMove={handleSlotMove}
                                        handleSlotUpdate={handleSlotUpdate}
                                        handleSlotCancel={handleSlotCancel}
                                        handleDrawerClose={handleDrawerClose}
                                        activeSlot={state.activeSlot} />

                                    {state.bookAppointmentDrawerOpen && <BookAppointmentDrawer
                                        onBook={handleAppointmentBook}
                                        drawerOpen={state.bookAppointmentDrawerOpen}
                                        handleDrawerClose={handleDrawerClose}
                                        activeSlot={state.activeSlot}
                                        startTime={state.timebarTime}
                                        practitioner={(state.activeSlot && practitionerState.active.find(p => p.id === state.activeSlot.EmployeeId)) || ''}
                                        location={location.alias || ''}
                                        endTime={state.timebarTime && niceTime(addMinutes(timeFromString(state.timebarTime), 15).toISOString())} />}
                                </> :
                                <EditSchedule
                                    practitioner={(state.activeSlot && practitionerState.active.find(p => p.id === state.activeSlot.EmployeeId)) || ''}
                                    location={location.alias || ''}
                                    event={state.activeSlot}
                                    open={state.activeAction === 'edit'}
                                    onClose={handleDrawerClose}
                                    onDelete={() => { }}
                                    onUpdate={handleScheduleUpdate} />
                        }
                    </Box>
                </Box>
            </CalendarProvider>
            <ConfirmationDialogComp
                label={{
                    positive: "Confirm",
                    negative: "Cancel",
                }} />
            <ScheduleDialog onSave={handleSaveSchedule} ignoreBackdropClick={true} />

        </>



    );
}


