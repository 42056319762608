import { useState } from "react";
import { ChevronLeft } from "@mui/icons-material";
import {
  Divider,
  IconButton,
  List,
  ListItemText,
  ListItemIcon,
  Popover,
  Typography,
  ListItemButton,
} from "@mui/material";
import { DrawerHeader } from "../../styles/drawer";
import { Drawer } from "../../styles/drawer";
import { useUIContext } from "../../state/ui";
import { AppNavLink } from "../../styles/links";
import Styles from "../../styles";
import { drawerMenu } from './drawerMenu';

function Appdrawer() {
  const { drawerOpen, setDrawerOpen } = useUIContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [focusElement, setFocusElement] = useState(null);

  //const [open, setOpen] = useState(false);
  const open = Boolean(anchorEl);
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleMouseEnter = (whichElement, event) => {
    if (drawerOpen) return;
    //setOpen(true);
    setFocusElement(whichElement);
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = (event) => {
    setAnchorEl(null);
    //setOpen(false);
    //console.log("leave " + element);
  };
  return (
    <>
      <Drawer variant="permanent" open={drawerOpen} elevation={2}>
        <DrawerHeader>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              color: "#fff",
            }}
          >
            One Vision
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeft />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {drawerMenu.map((menu, index) => (
            <AppNavLink to={menu.to} key={index}>
              {({ isActive }) => (
                <ListItemButton
                  sx={{
                    background: isActive ? Styles.color.secondary : undefined,
                  }}
                  key={menu.text}
                  aria-haspopup="true"
                  aria-owns={open ? "my-popover" : undefined}
                  onMouseEnter={(e) => handleMouseEnter(menu.text, e)}
                  onMouseLeave={handleMouseLeave}
                >
                  <ListItemIcon>
                    {menu.icon}
                  </ListItemIcon>
                  <ListItemText primary={menu.text} />
                </ListItemButton>
              )}
            </AppNavLink>
          ))}
        </List>
        
        <Popover
          id={open ? "my-popover" : undefined}
          open={open}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            elevation: 0,
            sx: {
              padding: "10px 20px",
              borderTop: 1,
              borderRight: 1,
              borderBottom: 1,
              borderColor: "grey.200",
            },
          }}
          anchorEl={anchorEl}
          sx={{
            pointerEvents: "none",
          }}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          disableRestoreFocus
        >
          {focusElement}
        </Popover>
      </Drawer>
    </>
  );
}

export default Appdrawer;
