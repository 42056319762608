
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box, alpha, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material"
import { useEffect, useReducer } from "react"
import { Colors } from "../../../../styles/theme";
import { niceDate } from "../../../../utils"
import validLookup from "../../../../utils/validLookup";
import AppDialogTitle from "../../../ui/appDialogTitle";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const rxData = {
    type: validLookup.validRxType.glasses,
    data: [
        {
            id: 1,
            startDate: '2022-02-10',
            expDate: '2023-02-10',
            status: 'active',
            type: 'soft',
            model: 'Model A',
            manufacturer: 'Manufacturer',
            od: {
                odSphere: '+1.25',
                odCylinder: '1',
                odNearAdd: '1',
                odAxis: '-0.1',
                odDpd: '1',
                odNpd: '1',
                odHPrism: '1',
                odVPrism: '1'
            },
            os: {
                osSphere: '+1.50',
                osCylinder: '1',
                osNearAdd: '1',
                osAxis: '-0.1',
                osDpd: '1',
                osNpd: '1',
                osHPrism: '1',
                osVPrism: '1'
            }
        },
        {
            id: 2,
            startDate: '2022-12-10',
            expDate: '2023-02-10',
            status: 'active',
            type: 'soft',
            model: 'Model B',
            manufacturer: 'Manufacturer',
            od: {
                odSphere: '+2.25',
                odCylinder: '1',
                odNearAdd: '1',
                odAxis: '-0.1',
                odDpd: '1',
                odNpd: '1',
                odHPrism: '1',
                odVPrism: '1'
            },
            os: {
                osSphere: '+2.50',
                osCylinder: '1',
                osNearAdd: '1',
                osAxis: '-0.1',
                osDpd: '1',
                osNpd: '1',
                osHPrism: '1',
                osVPrism: '1'
            }
        }
    ]
}

export default function ImportRxDialog({ open, onClose, onRxSelect, rxType }) {

    const [state, updateState] = useReducer((state, params) => {
        return { ...state, ...params }
    }, {
        items: rxData.data,
        selectedRow: null
    });

    // useEffect(() => {
    //     const items = [].filter(rxd => rxd.type === rxType)

    //     updateState({ items: items });
    // }, [rxType]);

    const handleRowSelect = (item) => {
        updateState({ selectedRow: item });
    }

    const handleImport = () => {

        onRxSelect(state.selectedRow);
        updateState({ selectedRow: null });
        onClose();
    }
    return (
        <Dialog open={open} fullWidth maxWidth={'lg'}>
            <AppDialogTitle onClose={onClose} title="Import Rx" />
            <DialogContent>
                <TableContainer>
                    <Table size="small" >
                        <TableHead sx={{ background: alpha(Colors.primary, 0.5) }}>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Start Date</TableCell>
                                <TableCell>Expiry Date</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Manufacturer</TableCell>
                                <TableCell>Model</TableCell>
                                <TableCell>Type</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {state.items.map(item => (
                                <TableRow key={item.id} onClick={(event) => handleRowSelect(item)} sx={{ cursor: 'pointer' }}>
                                    <TableCell>
                                        {state.selectedRow && state.selectedRow.id === item.id &&
                                            <CheckCircleIcon color="success" />}
                                    </TableCell>
                                    <TableCell>{niceDate(item.startDate)}</TableCell>
                                    <TableCell>{niceDate(item.expDate)}</TableCell>
                                    <TableCell>{item.status}</TableCell>
                                    <TableCell>{item.manufacturer}</TableCell>
                                    <TableCell>{item.model}</TableCell>
                                    <TableCell>{item.type}</TableCell>
                                </TableRow>
                            )
                            )}

                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
                <Button color="primary" variant="contained" autoFocus onClick={handleImport}>
                    Import
                </Button>
            </DialogActions>
        </Dialog>

    )
}