
import { Dialog, DialogContent, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, alpha, DialogActions, Button, Checkbox } from "@mui/material";
import { useReducer } from "react";
import { Colors } from "../../../../styles/theme";
import AppDialogTitle from "../../../ui/appDialogTitle";

const servicesData = [
    {
        id: 1,
        description: 'Screw adjustment',
        amount: 50
    },
    {
        id: 2,
        description: 'Lens coating',
        amount: 150
    }
]

export default function OrderServcesDialog({ open, onClose, onAdd }) {

    const [state, updateState] = useReducer((state, params) => {
        return {...state, ...params}
    }, {
        services: []
    });

    const handleAddService = (check, service) => {
        // if already have service then remove it, 
        let existingServices = [...state.services];
        const index = existingServices.findIndex(s => s.id === service.id);
        if( index !== -1 ) {
            existingServices.splice(index, 1);
        } else {
            existingServices.push(service);
        }
        // otherwise add it
        updateState({services: existingServices});
    }

    const handleAdd = () => {
        onAdd(state.services);
        onClose();
    }
    return (
        <Dialog onClose={onClose} open={open}>
            <AppDialogTitle onClose={onClose} title="Additional services" />
            <DialogContent>
                <TableContainer>
                    <Table>
                        <TableHead sx={{ background: alpha(Colors.primary, 0.5) }}>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Price</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                servicesData.map(service => (
                                    <TableRow key={service.id}>
                                        <TableCell><Checkbox onChange={(event) => handleAddService(event.target.checked, service)} /></TableCell>
                                        <TableCell>{service.description}</TableCell>
                                        <TableCell>${service.amount}</TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleAdd}>Add</Button>
            </DialogActions>
        </Dialog>
    )
}