import { TableContainer, Box, 
    Paper, TableBody, TableHead, TableRow, TableCell, Table, IconButton, Button } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import useDialogModal from "../../../hooks/useDialogModal";
import EmployeeInputForm from "./addEmployeeForm";

export default function ManageEmployee() {
    const [EmployeeInputDialog, showEmployeeInputForm] = useDialogModal(EmployeeInputForm);

    const handleAddEmployeeForm = () => {
        showEmployeeInputForm();
    }

    return (
        <Box>
            <Button sx={{mb: 2}} onClick={handleAddEmployeeForm} variant="outlined" startIcon={<AddIcon />}>Add</Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Johnss</TableCell>
                            <TableCell>Doe</TableCell>
                            <TableCell>Sales</TableCell>
                            <TableCell>
                                <IconButton>
                                    <MoreVertIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Rashid</TableCell>
                            <TableCell>Siraj</TableCell>
                            <TableCell>Manager</TableCell>
                            <TableCell>
                                <IconButton>
                                    <MoreVertIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Criss</TableCell>
                            <TableCell>Ng</TableCell>
                            <TableCell>Customer service</TableCell>
                            <TableCell>
                                <IconButton>
                                    <MoreVertIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

            </TableContainer>
           <EmployeeInputDialog />
        </Box>

    )

}