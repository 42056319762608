import {
  CalendarContainer,
  EventsContainer,
} from "../../styles/calendar";
import { ViewSelector, NextPrev, TimeIndicatorToggle, GotoDate, CalendarView, TodayDate } from "./actions";
import { useState } from "react";
import { Box, Stack } from "@mui/material";
import Period from "./period";
import { DayColumnContainer } from "../../styles/calendar";
import { Columns } from "./column";
import Events from "./Events";
import { CalendarProvider } from "../../state/calendar";

export default function Calendar({ events,
  backgroundEvents,
  onEventAdded,
  onEventEdit,
  onEventRemoved,
  onWeekChanged,
  onDayChanged,
  onViewChanged,
  onGotoDate,
  disableSlotClick,
  onEventClicked }) {

  const handleViewSelected = (view) => {
    onViewChanged(view);
  };

  const gotoDate = (date) => {
    onGotoDate(date);
  }

  return (
    <CalendarProvider>

      <Stack direction="row" alignItems="center">
        <NextPrev />
        <Box sx={{ padding: "0px 8px" }}>
          <Period onWeekChanged={onWeekChanged} onDayChanged={onDayChanged} />
        </Box>
        <TimeIndicatorToggle />
        <ViewSelector viewSelected={handleViewSelected} />
        <TodayDate />
        <GotoDate gotoDate={gotoDate} />
      </Stack>

      <CalendarContainer>
        <CalendarView />
        <EventsContainer>
          <DayColumnContainer columns={7}>
            <Columns
              numberOfColumns={[0, 1, 2, 3, 4, 5, 6]}
              disableSlotClick={disableSlotClick} />
          </DayColumnContainer>
          {events && <Events
            calendarEvents={events}
            backgroundEvents={backgroundEvents}
            onEventAdded={onEventAdded}
            onEventEdit={onEventEdit}
            onEventClicked={onEventClicked}
            onEventRemoved={onEventRemoved} />}
        </EventsContainer>
      </CalendarContainer>
    </CalendarProvider>
  );
}

{/* <CalendarContainer>
<TimeslotsContainer>
    <TimeslotsHolder >
        {timeslots.map((slot, index) => (
            <Timeslot key={index}>{slot}</Timeslot>
        ))}
    </TimeslotsHolder>
</TimeslotsContainer>
{
    {
        'day': <DayView activePractitioners={activePractitioners} />,
        'week': <WeekView />
    }[activeView]

}
</CalendarContainer> */}