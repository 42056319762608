import { Grid, Box } from "@mui/material";
import { useFormik } from "formik";
import { useEffect } from "react";
import AdditionalInfoCard from "../dialog/additionalInfoCard";
import AddressInfoCard from "../dialog/addressInfoCard";
import CommentsCard from "../dialog/commentsCard";
import GeneralInfoCard from "../dialog/generalInfoCard";
import { PatientService } from "../../../service/patientService";
import { isLocalDev } from "../../../utils/environment";
import { toast } from "react-toastify";

export default function ProfileTab({
    profileData,
    formChanged,
    addListener,
    deleteListener,
    onUpdate
}) {

    useEffect(() => {
        const listenerRef = addListener(() => {
            formik
                .submitForm()
                .then((response) => { })
                .catch((error) => console.log(error));
        }, () => {
            formik.resetForm();
        });

        return () => listenerRef();
    }, []);

    const formik = useFormik({
        /**
         * set the initial values. Not doing so will not 
         * detect dirty state of the form
         * https://formik.org/docs/api/formik#initialtouched-formiktouchedvalues
         */
        initialValues: { ...profileData },

        onSubmit: async (updatedPatient) => {

            try {
                const patientUpdateResult = await PatientService.update(updatedPatient);
                const {
                    code,
                    message,
                    data
                } = { ...patientUpdateResult };

                if (code === 0) {
                    toast.success(message);
                    formChanged(false);
                    onUpdate(formik.values);
                }
                else
                    toast.warn(message);
            } catch (error) {
                if (isLocalDev) console.log(error);

                toast.error("Something went wrong");
            }

        }
    });

    useEffect(() => {
        formChanged(formik.dirty);
    }, [formik.dirty]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box>
                <Grid container pt={2} columnSpacing={1} rowSpacing={1}>
                    <Grid item xs={12} sm={6}>
                        <GeneralInfoCard formik={formik} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AdditionalInfoCard formik={formik} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <AddressInfoCard formik={formik} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CommentsCard formik={formik} />
                    </Grid>
                </Grid>
                {/*
                TODO: Do this on admin side
                 <ButtonWithIcon sx={{ mt: 2, background: Colors.danger }} onClickHandler={deleteListener} 
                icon={<DeleteForeverIcon />} title={"Delete account"} /> */}
            </Box>
        </form>
    );
}