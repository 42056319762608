import { Box, Button } from '@mui/material';
import React, { useReducer, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppContext } from '../../../../state/appState';

function FileDropZone({onFileSelect}) {
    const { appState } = useAppContext();

    const [dragging, setDragging] = useState(false);
   // const [selectedFiles, setSelectedFiles] = useState([]);

    const handleDragEnter = (e) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDragging(false);
    };

    const validFiles = (files) => {
        const error = [];
        files.forEach((file) => {

            const fileTypeIsAccepted = appState.acceptedFileTypes.indexOf(file.type);

            if (fileTypeIsAccepted === -1) {
                error.push("File is not correct type. Must be one of " + appState.acceptedFileTypes.join(' '));
            }
            const isUnder5Mb = file.size < appState.maxFileUploadSize * 1024 * 1024; // X MB in bytes

            if (!isUnder5Mb) {
                error.push(`File size must be less than or equal to  ${appState.maxFileUploadSize} MB`);
            }
        });
        return error;
    };



    /*files.filter((file) => {
        const isJpeg = file.type === 'image/jpeg';
        const isJpg = file.type === 'image/jpg';
        const isPng = file.type === 'image/png';
        const isPdf = files.type === 'application/pdf';

        const error = [];
        const fileTypeIsAccepted = appState.acceptedFileTypes.indexOf(file.type);

        if (fileTypeIsAccepted) {
            error.push("File is not correct type. Must be one of " + appState.acceptedFileTypes.join(' '));
        }
        const isUnder5Mb = file.size < appState.acceptedFileTypes * 1024 * 1024; // X MB in bytes

        if (!isUnder5Mb) {
            error.push(`File size must be less than or equal to  ${appState.acceptedFileType} MB`);
        }

        return error;
        //return (isJpeg || isJpg || isPng || isPdf) && isUnder5Mb;
    });*/

    const handleDrop = (e) => {
        e.preventDefault();
        setDragging(false);

        const files = Array.from(e.dataTransfer.files);
        const error = validFiles(files);

        if (error.length > 0)

            toast.info(error.join('\n'));
        else {
            //setSelectedFiles([...selectedFiles, ...files]);
            onFileSelect(files);
        }
        // Do something with the valid files, like upload to a server
    };

    const handleFileInputChange = (event) => {
        const files = Array.from(event.target.files);
        const error = validFiles(files);
        if (error.length > 0)
            toast.info(error.join('\n'));
        else {
            //setSelectedFiles([...selectedFiles, ...files]);
            onFileSelect(files);
        }
        //setSelectedFiles([...selectedFiles, ...event.target.files]);
    };

    const handleChooseFileClick = () => {
        document.getElementById('fileInput').click();
    };

    return (
        <Box display={'flex'} flexDirection={'column'}>
            <Box
                sx={{
                    border: '2px dashed #ccc',
                    padding: '20px',
                    borderColor: dragging && 'red',
                    textAlign: 'center',
                    fontSize: '18px',
                    color: '#666'
                }}
                onDragEnter={handleDragEnter}
                onDragOver={(e) => {
                    e.preventDefault();
                }}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <p>Drag and drop JPEG/PNG/PDF files under 5 MB</p>
            </Box>
            <input type="file" id="fileInput" multiple onChange={handleFileInputChange} style={{ display: 'none' }} />
            <Button sx={{ mt: 1 }} variant='outlined' onClick={handleChooseFileClick}>Choose Files</Button>
        </Box>
    );
}

export default FileDropZone;
