import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { useReducer } from "react"
import AppDialogTitle from "../../../ui/appDialogTitle"
import FileDropZone from "./fileDropZone"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { formatFileSize } from "../../../../utils";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';

export default function FileUploadDialog({ open, onClose, onUploadDone }) {
    const [state, updateState] = useReducer((state, params) => {
        return { ...state, ...params }
    }, {
        files: [],
        fileNameEdit: false,
        newName: null,
        selectedFile: null
    });

    const handleFileSelect = (files) => {
        let userSelectedFiles = [];

        files.forEach(f => {
            const oneFile = {
                name: f.name,
                notes: '',
                size: f.size,
                file: f
            };

            userSelectedFiles.push(oneFile);
        });
        updateState({ files: [...state.files, ...userSelectedFiles] });
    }

    const handleUpload = () => {
        onUploadDone(state.files);
        onClose();
    }

    const handleDeleteFile = (file) => {
        let filteredFiles = state.files.filter(f => f.name !== file.name);

        // remove the file
        updateState({ files: filteredFiles });
    }

    const handleFileNameChange = (newValue, file) => {
        updateState({ newName: newValue });
    }

    const onEditFileName = (file) => {
        updateState({ selectedFile: file, newName: file.name });
    }

    const onFileNameEditDone = () => {
        let existingFiles = state.files;
        const index = existingFiles.findIndex(f => f.name === state.selectedFile.name);
        if (index !== -1) {
            existingFiles[index].name = state.newName;
        }
        updateState({ selectedFile: null, newName: null });
    }

    const handleNotesEdit = (value, file) => {
        let existingFiles = state.files;
        const index = existingFiles.findIndex(f => f.name === file.name);
        if (index !== -1) {
            existingFiles[index].notes = value;
        }
        updateState({ files: existingFiles });
    }

    return (
        <Dialog open={open} fullWidth maxWidth={'md'}>
            <AppDialogTitle title={'Upload file'} onClose={onClose} />
            <DialogContent>
                <FileDropZone onFileSelect={handleFileSelect} />
                {
                    state.files.length > 0 &&

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Notes</TableCell>
                                    <TableCell>Size</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    state.files.map((file, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {
                                                    state.selectedFile && state.selectedFile.name === file.name ?
                                                        <Box display="flex">
                                                            <TextField
                                                                onChange={(event) => handleFileNameChange(event.target.value, file)}
                                                                value={state.newName}
                                                                size='small'
                                                                fullWidth />
                                                            <IconButton onClick={onFileNameEditDone}>
                                                                <CheckCircleIcon color="success" />
                                                            </IconButton>
                                                        </Box>
                                                        : <Box display="flex" alignItems={"center"}>
                                                            <Typography>{file.name}</Typography>
                                                            <IconButton onClick={() => onEditFileName(file)}>
                                                                <EditIcon />
                                                            </IconButton>
                                                        </Box>
                                                }
                                            </TableCell>
                                            <TableCell><TextField size="small" fullWidth value={file.notes} onChange={(event) => handleNotesEdit(event.target.value, file)}></TextField></TableCell>
                                            <TableCell>{formatFileSize(file.size)}</TableCell>
                                            <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                                                <IconButton onClick={(event) => handleDeleteFile(file)}>
                                                    <DeleteForeverIcon color="danger" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </DialogContent>
            <DialogActions>
                <Button sx={{ mt: 4 }} variant='outlined' onClick={handleUpload}>Upload</Button>
            </DialogActions>
        </Dialog>
    )
}