import { Menu, MenuItem, Switch, Typography } from "@mui/material"
import { toTitleCase } from "../../../utils";

export const CopyScheduleMenu = ({weekdays, anchorEl, onClose, handleDaySelect, days, copyFromDay }) => {

    return (
        <Menu
            id="copy-schedule-to-menu"
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={onClose}
        >
            {
                weekdays.map(weekday => {
                    return <MenuItem
                    disabled={copyFromDay === weekday.day}
                        key={weekday.day}><Switch onClick={(event) => handleDaySelect(event, weekday.day)}
                            checked={weekday.open} sx={{ mr: 2 }} /><Typography variant="caption" sx={{ fontSize: '1.2rem' }}>{toTitleCase(weekday.day)}</Typography></MenuItem>
                })
            }
        </Menu>
    )
}