import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { data } from './data';
import { toTitleCase } from '../../utils';

export default function LocationList({ locationList, location, setLocation }) {

  const handleChange = (event) => {
    const loc = event.target.value;
    setLocation(loc);
  };

  return (
    <Box >
      <FormControl sx={{ m: 1, width: 300 }} size="small">
        <InputLabel id="locationlist-select-label">Location</InputLabel>
        <Select
          labelId="locationlist-select-label"
          id="locationlist-select"
          value={location}
          label="Location"
          onChange={handleChange}
        >
          {
            locationList.map(loc => <MenuItem key={loc.id} value={loc.id}>{toTitleCase(loc.alias)}</MenuItem>)
          }

        </Select>
      </FormControl>
    </Box>
  );
}