import { alpha, Box } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useMouseMovement from "../../hooks/useMouseMovement";
import { useAppContext } from "../../state/appState";
import { Colors } from "../../styles/theme";
import { timeFromString } from "../../utils";
import validLookup from "../../utils/validLookup";
import { getHeight } from "../calendar/helpers";
import DraggableSlot from "./draggable";
import DroppableSlot from "./droppable";
import { getRowPosition } from "./utils";

export const slotBackground = (status, amount) => {
    switch (status) {
        case validLookup.validSlotStatus.break:
            return `repeating-linear-gradient( 45deg, ${alpha(Colors.muted, 1.0)}, ${alpha(Colors.muted, 1.0)} 6px, #e7e7e7 5px, #ededed 20px )`;
        case validLookup.validSlotStatus.available:
            return alpha(Colors.primary, amount || 0.25)
        // case validLookup.validAppointmentStatus.paid:
        //     return alpha(Colors.paid, amount || 0.25)
        default:
            return Colors.primary
    }
}

export default function Slot({ collides,
    activeSlot,
    draggedSlot,
    practitionerid,
    id, start, end, status, name, onClick, offset }) {

    const ref = useRef(null);
    const { appState } = useAppContext();
    const [attributes, setAttributes] = useState({
        top: 0,
        height: 0
    });


    const rowPos = useMemo(() => getRowPosition(appState.businessStartHour, start) * 2,
        [start, appState.businessStartHour]);
    const slotHeight = getHeight(start, end) / 30;

    useEffect(() => {
        if (start && end) {
            setAttributes({ ...attributes, top: rowPos, height: slotHeight });
        }

    }, [start, end]);

    return (
        <>
            {
                {
                    'available':
                        <DroppableSlot
                            id={id}
                            practitionerid={practitionerid}
                            rect={attributes}
                            offset={offset}
                            status={status}
                            name={name}
                            onClick={onClick}
                        />,
                    'break':
                        <DroppableSlot
                            id={id}
                            practitionerid={practitionerid}
                            rect={attributes}
                            offset={offset}
                            status={status}
                            name={name}
                            onClick={onClick}
                        />,
                    'booked':
                        <DraggableSlot
                            id={id}
                            collides={collides}
                            activeSlot={activeSlot}
                            practitionerid={practitionerid}
                            rect={attributes}
                            start={start}
                            end={end}
                            offset={offset}
                            status={status}
                            name={name}
                            onClick={onClick}
                            isDragging={draggedSlot && (id === draggedSlot.id)}
                        />,
                        'arrived':
                        <DraggableSlot
                            id={id}
                            collides={collides}
                            activeSlot={activeSlot}
                            practitionerid={practitionerid}
                            rect={attributes}
                            start={start}
                            end={end}
                            offset={offset}
                            status={status}
                            name={name}
                            onClick={onClick}
                            isDragging={draggedSlot && (id === draggedSlot.id)}
                        />,
                        'noshow':
                        <DraggableSlot
                            id={id}
                            collides={collides}
                            activeSlot={activeSlot}
                            practitionerid={practitionerid}
                            rect={attributes}
                            start={start}
                            end={end}
                            offset={offset}
                            status={status}
                            name={name}
                            onClick={onClick}
                            isDragging={draggedSlot && (id === draggedSlot.id)}
                        />,
                        'paid':
                        <DraggableSlot
                            id={id}
                            collides={collides}
                            activeSlot={activeSlot}
                            practitionerid={practitionerid}
                            rect={attributes}
                            start={start}
                            end={end}
                            offset={offset}
                            status={status}
                            name={name}
                            onClick={onClick}
                            isDragging={draggedSlot && (id === draggedSlot.id)}
                        />,
                        'completed':
                        <DraggableSlot
                            id={id}
                            collides={collides}
                            activeSlot={activeSlot}
                            practitionerid={practitionerid}
                            rect={attributes}
                            start={start}
                            end={end}
                            offset={offset}
                            status={status}
                            name={name}
                            onClick={onClick}
                            isDragging={draggedSlot && (id === draggedSlot.id)}
                        />
                }[status]
            }
        </>

    )
}

/**
 * 09:00 -------------------------------
 *       -------------------------------
 *       -------------------------------
 * 10:00 -------------------------------
 * 
 * 
 * grid size: 15 = 60 / 4
 * slot height / 60
 * rowPos * 1
 * transY / 1
 * 
 * 09:00 -------------------------------
 *       -------------------------------
 * 09:30 -------------------------------
 *       -------------------------------
 * 10:00 -------------------------------
 * 
 *  * grid size: 30 = 60 / 4
 * slot height / 30
 * rowPos * 2
 * transY / 2
 */