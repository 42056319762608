import { DaysContainer } from "../../../styles/calendar";
import { DayBox, DayNumber, DayName } from "../../../styles/calendar";
import {
  addDays,
  addWeeks,
  getDate,
  getDay,
  isToday,
  startOfWeek,
} from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { getDayString } from "../../../utils";
import { Typography } from "@mui/material";
import { useCalendarContext } from "../../../state/calendar";

export default function Header(props) {
  return <DaysContainer days={props.days}>{props.render(props)}</DaysContainer>;
}

export const DayHeader = ({ activeDay }) => {
  // var now = new Date();
  // const [date, setDate] = useState(now);
  const {state} = useCalendarContext();

  // useEffect(() => {
  //   console.log(activeDay);
  //   setDate(activeDay);
  // }, [activeDay]);

  return (
    <DayBox>
      <DayNumber>{getDate(state.activeDate)}</DayNumber>
      <DayName>{getDayString(getDay(state.activeDate))}</DayName>
    </DayBox>
  );
};

export const WeekHeader = ({ activeWeek }) => {
  const [daysOfWeek, setDaysOfWeek] = useState();
  const {state} = useCalendarContext();
  var start = useMemo(() => startOfWeek(state.activeDate),[state.activeDate]) 

  useEffect(() => {
    
    setDaysOfWeek(
      [0, 1, 2, 3, 4, 5, 6].map((day, index) => {
        //var now = new Date();
        
        //start = addWeeks(start, activeWeek);
        const date = addDays(start, day);
        var dayOfMonth = getDate(date);

        return (
          <li key={index}>
            <DayBox
              isToday={isToday(date)}
            >
              <DayNumber>
                <Typography variant="h6">{dayOfMonth}</Typography>
              </DayNumber>
              <DayName>
                <Typography variant="inherit">{getDayString(index)}</Typography>
              </DayName>
            </DayBox>
          </li>
        );
      })
    );
  }, [state.activeDate]);

  return (
    <>
      {daysOfWeek}
    </>
  );
};
