import request, { get, put } from "./httpService";

const PATIENT_ENDPOINT = '/patient';

const add = (data) => {
    return request({
        method: "POST",
        url: `${PATIENT_ENDPOINT}/new`,
        data: data,
    });
};

const linkfamily = (primaryid, data) => {
    return request({
        method: "POST",
        url: `${PATIENT_ENDPOINT}/${primaryid}/linkfamily`,
        data: data,
    });
};

const unlinkFamilyMember = (primaryid, data) => {
    return request({
        method: "POST",
        url: `${PATIENT_ENDPOINT}/${primaryid}/unlinkfamily`,
        data: data,
    });
};

const search = (param) => {
    return request({
        method: 'GET',
        url: `${PATIENT_ENDPOINT}/search`,
        params: param
    })
};

const getById = (id,signal) => {
    return get({
        url: `${PATIENT_ENDPOINT}/${id}`,
        signal: signal
    })
}

const update = (patient) => {
    return put({
        url: `${PATIENT_ENDPOINT}/${patient.id}`,
        data: patient
    });
}

export const PatientService = {
    add,
    search,
    getById,
    update,
    linkfamily,
    unlinkFamilyMember,
};