import { Route, Routes } from "react-router-dom";
import Admin from "./components/admin";
import Dashboard from "./components/dashboard";
import { withAppbar } from "./components/hoc/withAppbar";
import Layout from "./components/Layout";
import PatientRecord from "./components/patientrecord";
import Appointments from "./components/appointments";
import Schedule from "./components/schedule";
import Order from "./components/order";

const routes = [
  {
    path: "dashboard",
    component: Dashboard,
  },
  {
    path: "appointments",
    component: Appointments,
  },
  {
    path: "schedule",
    component: Schedule,
  },
  {
    path: 'patientrecord',
    component: PatientRecord
  },
  {
    path: 'order',
    component: Order
  },
  {
    path: 'admin',
    component: Admin
  }
];

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={<route.component />}
          />
        ))}
      </Route>
    </Routes>
  );
}

// <Routes>
//   {routes.map((route, index) => (
//     <Route
//       key={index}
//       path={route.path}
//       element={<route.component />}
//       //    render={(props) => withAppbar(route.component, props)}
//     />
//   ))}
// </Routes>;
