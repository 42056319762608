export const glassLensModel = {
    practitioner: '',
    manufacturer: '',
    model: '',
    odSphere: '',
    odCylinder: '',
    odAxis: '',
    odNearAdd: '',
    odDpd: '',
    odNpd: '',
    odHPrism: '',
    odVPrism: '',
    osSphere: '',
    osCylinder: '',
    osAxis: '',
    osNearAdd: '',
    osDpd: '',
    osNpd: '',
    osHPrism: '',
    osVPrism: '',
    comment: '',
};