export const patientModel = {
    firstName: '',
    middleName: '',
    lastName: '',
    sex: '',
    phone1: '',
    phone1Desc: '',
    phone2: '',
    phone2Desc: '',
    phone3: '',
    phone3Desc: '',
    addrHouse: '',
    addrStreet: '',
    addrUnit: '',
    addrCity: '',
    addrProvince: '',
    addrPostal: '',
    email: '',
    healthcard: '',
    birthdate: '',
    communicationType: 'none',
    marketingEmailOptin: false,
    comment: '',
    familyId: null,
};