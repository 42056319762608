import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Typography,
  Divider,
  Grid,
  Menu,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import Styles from "../../../styles";
import {
  dateFromString,
  getDateStringNonISO,
  weekdays,
} from "../../../utils";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { addDays, addMonths, addWeeks, addYears, endOfWeek } from "date-fns";
import { Colors } from "../../../styles/theme";
import { useUIContext } from "../../../state/ui";
import { DayLabel } from "./dayLabel";
import { DayToggleSwitch } from "./dayToggleSwitch";
import { StartTimeControl } from "./startTimeControl";
import { EndTimeControl } from "./endTimeControl";
import { CopySchedule } from "./copySchedule";
import { CopyScheduleMenu } from "./copyScheduleMenu";
import useEffectOnUpdates from "../../../hooks/useEffectOnUpdates";
import DatePickerComponent from "../../ui/datePickerComponent";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import validLookup from "../../../utils/validLookup";
import { SelectLocation, SelectPractioner } from "../../common";

export default function AddScheduleDialog({ open, onClose, onSave }) {
  const [timeslotsForDayOfWeek, updateTimeslotsForDayOfWeek] = useState([]);

  const [recurring, setRecurring] = useState(false);
  const [start, setStart] = useState(getDateStringNonISO(new Date(), "yyyy-MM-dd"));
  const [repeat, setRepeat] = useState("norepeat");
  const [repeatCount, setRepeatCount] = useState(1);
  const [recurCount, setRecurCount] = useState(1);
  const [endsAfter, setEndsAfter] = useState("week");
  const [showEndsOn, setShowEndsOn] = useState(false);
  const [endsOn, setEndsOn] = useState(getDateStringNonISO(
    endOfWeek(new Date()),
    "yyyy-MM-dd"));
  const { setToast } = useUIContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [daysToCopy, setDaysToCopy] = useState([]);
  const [copyFromDay, setCopyFromDay] = useState(null);
  const [location, setLocation] = useState('');
  const [practitioner, setPractitioner] = useState('');

  // const [LocationList, location, setLocation] = useList(
  //   LocationService.getLocations,
  //   {
  //     paramList: ["addrCity"],
  //     fullWidth: true,
  //   }
  // );
  // const [PractitionerList, practitioner, setPractitioner] = useList(
  //   EmployeeService.getPractitioners,
  //   {
  //     paramList: ["firstName", "lastName"],
  //     fullWidth: true,
  //   }
  // );

  const prepareSlotsForEachDay = useCallback(() => {
    const t = [];
    weekdays.forEach(day => {
      t.push({
        day: day,
        open: false,
        slots: [{
          starttime: "",
          endtime: "",
          notes: '',
          type: validLookup.validSlotStatus.available,
        }]
      });
    });

    updateTimeslotsForDayOfWeek([...timeslotsForDayOfWeek, ...t]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    prepareSlotsForEachDay();
    return () => updateTimeslotsForDayOfWeek([]);
  }, []);

  const handleSave = () => {

    // pick only open ones
    // filter returns something like this: [ ['day1', {...}], ['day2', {...}] ]
    // the reduce turns it into this: {'day1': {...}, 'day2': {...}}
    const openSlots = {};
    // Object.entries(timeslotsForDayOfWeek)
    //   .filter(k => k[1].open) // filter open slots
    //   .reduce(
    //     (p, c) => p = { ...p, [c[0]]: c[1] }, {}
    //   );

    timeslotsForDayOfWeek.forEach(ts => {
      if (ts.open) {
        openSlots[`${ts.day}`] = ts.slots;
      }
    });

    const data = {
      slots: openSlots,
      start: start,
      end: endsOn,
      repeat: repeat,
      location: location.id,
      practitioner: practitioner,
    };

    if (!location || !practitioner) {
      setToast({ open: true, message: "Location or Practitioner missing" });
      return;
    }

    onSave(data);
    //onClose();
  };

  const handleDaySwitch = (weekday) => {

    const i = timeslotsForDayOfWeek.findIndex(ts => ts.day === weekday.day);
    if (i !== -1) {
      const ts = timeslotsForDayOfWeek;
      ts[i].open = !ts[i].open;
      updateTimeslotsForDayOfWeek([...ts]);
    }

  };

  const handleStartTimeChange = (weekday, event, at) => {
    const dayIndex = timeslotsForDayOfWeek.findIndex(dow => dow.day === weekday.day);

    if (dayIndex !== -1) {
      const days = timeslotsForDayOfWeek;
      days[dayIndex].slots[at].starttime = event.target.value;
      updateTimeslotsForDayOfWeek([...days]);
    }
  };

  const handleEndTimeChange = (weekday, event, at) => {
    const dayIndex = timeslotsForDayOfWeek.findIndex(dow => dow.day === weekday.day);

    if (dayIndex !== -1) {
      const days = timeslotsForDayOfWeek;

      days[dayIndex].slots.at(at).endtime = event.target.value;
      updateTimeslotsForDayOfWeek([...days]);
    }
  };

  const handleSlotTypeChange = (weekday, event, at) => {
    const dayIndex = timeslotsForDayOfWeek.findIndex(dow => dow.day === weekday.day);

    if (dayIndex !== -1) {
      const days = timeslotsForDayOfWeek;

      days[dayIndex].slots.at(at).type = event.target.value;
      updateTimeslotsForDayOfWeek([...days]);
    }
  };

  const handleRepeatSelection = (event) => {
    // switch (event.target.value) {
    //   case "daily":
    //     setEndsAfter("day");
    //     break;
    //   case "weekly":
    //     setEndsAfter("week");
    //     break;
    //   case "monthly":
    //     setEndsAfter("month");
    //     break;
    //   case "yearly":
    //     setEndsAfter("year");
    //     break;
    //   default:
    //     break;
    // }
    setRepeat(event.target.value);
  };

  /**
   * the start of occurrence
   * @param {*} event
   */
  const handleStartChange = (value) => {
    setStart(value);
  };

  const handleEndsOnChange = (value) => {
    setEndsOn(value);
  };

  const handleRepeatCount = (event) => {
    setRepeatCount(event.target.value);
  };

  const handleRecurCount = (event) => {
    setRecurCount(event.target.value);
  }

  useEffectOnUpdates(() => {

    switch (repeat) {
      case "norepeat":
        setShowEndsOn(false);
        setEndsOn(getDateStringNonISO(
          endOfWeek(new Date()),
          "yyyy-MM-dd"));
        break;
      case "daily":
        setEndsAfter("day");
        break;
      case "weekly":
        setEndsAfter("week");
        setEndsOn(getDateStringNonISO(
          endOfWeek(new Date(start)),
          "yyyy-MM-dd"));
        setShowEndsOn(true);
        break;
      case "biweekly":
        setEndsAfter("week");
        setEndsOn(getDateStringNonISO(
          endOfWeek(addWeeks(new Date(start), 2)),
          "yyyy-MM-dd"));
        setShowEndsOn(true);
        break;
      case "monthly":
        setEndsAfter("month");
        break;
      case "yearly":
        setEndsAfter("year");
        break;
      default:
        break;
    }
    // if (start && repeat && repeatCount > 0) {      
    //   let d = null;
    //   switch (repeat) {
    //     case "daily":
    //       d = addDays(dateFromString(start, "yyyy-MM-dd"), repeatCount);
    //       break;
    //     case "weekly":
    //       d = addWeeks(dateFromString(start, "yyyy-MM-dd"), repeatCount);          
    //       break;
    //     case "biweekly":
    //       d = addWeeks(dateFromString(start, "yyyy-MM-dd"), repeatCount);          
    //       break;
    //     case "monthly":
    //       d = addMonths(dateFromString(start, "yyyy-MM-dd"), repeatCount);
    //       break;
    //     case "yearly":
    //       d = addYears(dateFromString(start, "yyyy-MM-dd"), repeatCount);
    //       break;
    //     default:
    //       break;
    //   }

    //   if( d ) {
    //     setEndsOn(getDateStringNonISO(d, "yyyy-MM-dd"));
    //   }
    // }
  }, [start, repeat, repeatCount]);

  const handleCopySchedule = (event, day) => {
    setAnchorEl(event.currentTarget);
    setCopyFromDay(day);
  }

  const handleDayToCopySelect = (event, day) => {
    const set = daysToCopy;
    const sourceDay = copyFromDay;
    const targetDay = day;
    // source
    const sourceIndex = timeslotsForDayOfWeek.findIndex(dow => dow.day === sourceDay);
    // target
    const targetIndex = timeslotsForDayOfWeek.findIndex(dow => dow.day === targetDay);
    const days = timeslotsForDayOfWeek;
    const index = set.indexOf(targetDay);

    if (index === -1) {
      set.push(targetDay);

      if (sourceIndex !== -1) {
        // we can't just simply copy from source to target. We need to create 
        // a new array. Copying will cause a reference to original data
        days[targetIndex].slots = [...days[sourceIndex].slots]
        days[targetIndex].open = true;
      }
    }
    else {
      set.splice(index, 1);
      days[targetIndex].open = false;
    }

    updateTimeslotsForDayOfWeek([...days]);
    setDaysToCopy([...set]);
  }

  const handleAddSlot = (event, weekday) => {
    const dayIndex = timeslotsForDayOfWeek.findIndex(dow => dow.day === weekday.day);
    if (dayIndex !== -1) {
      const days = timeslotsForDayOfWeek;
      days[dayIndex].slots.push({
        starttime: "",
        endtime: "",
        notes: '',
        type: validLookup.validSlotStatus.available,
      });

      updateTimeslotsForDayOfWeek([...days]);
    }
  }

  const handleRemoveSlot = (event, weekday, at) => {
    const dayIndex = timeslotsForDayOfWeek.findIndex(dow => dow.day === weekday.day);
    if (dayIndex !== -1) {
      const days = timeslotsForDayOfWeek;

      if (days[dayIndex].slots.length > 1) {
        days[dayIndex].slots.splice(at, 1);
        updateTimeslotsForDayOfWeek([...days]);
      }
    }
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth={"xl"}>
      <DialogTitle sx={{ background: Colors.primary }}>
        <Typography flexGrow={1} sx={{ color: Colors.white, fontSize: '1.25rem' }}>
          Manage Schedule
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            marginTop: 1,
            padding: 1,
          }}
        >

          <Grid
            container
            sx={{
              border: `1px solid ${Colors.dove_gray}`,
              marginTop: `${Styles.margins.margin}px`,
              borderRadius: 2,
            }}
          >
            <Grid item xs={12} sm={6}>
              <Box sx={{ p: 2, width: "100%" }}>
                {timeslotsForDayOfWeek.map(weekday => {
                  return (
                    <Grid
                      container
                      key={weekday.day}
                      sx={{ p: 1 }}
                      alignItems={"center"}
                    >
                      <Grid item xs={12} sx={{ p: 2 }}>
                        <Box display="flex">
                          <DayLabel label={weekday.day} checked={weekday.open} />
                          <DayToggleSwitch wd={weekday.day} handleDaySwitch={() => handleDaySwitch(weekday)} checked={weekday.open} />
                          <CopySchedule disabled={!weekday.open} wd={weekday.day} handleCopySchedule={(event) => handleCopySchedule(event, weekday.day)} />
                        </Box>
                        <>                        {
                          weekday.slots.map((slot, index) => (
                            <Grid key={index} container alignItems="center" sx={{ mt: 2 }} display={`${weekday.open ? 'flex' : 'none'}`}>

                              <Grid item xs={5}>
                                <Box display={"flex"} alignItems="center">
                                  <StartTimeControl handleStartTimeChange={(event) => handleStartTimeChange(weekday, event, index)}
                                    wd={weekday}
                                    starttime={slot.starttime}
                                    disabled={weekday.open} />
                                  <Typography>to</Typography>
                                  <EndTimeControl handleEndTimeChange={(event) => handleEndTimeChange(weekday, event, index)}
                                    wd={weekday}
                                    endtime={slot.endtime}
                                    disabled={weekday.open} />
                                </Box>
                              </Grid>

                              <Grid item xs={4}>
                                <Box display={"flex"}>
                                  <Select fullWidth value={slot.type} size="small"
                                    onChange={(event) => handleSlotTypeChange(weekday, event, index)}>
                                    <MenuItem value={"available"}>Available</MenuItem>
                                    <MenuItem value={"break"}>Break</MenuItem>
                                    <MenuItem value={"vacation"}>Vacation</MenuItem>
                                  </Select>
                                </Box>
                              </Grid>

                              <Grid display="flex" justifyContent={'flex-end'} item>
                                <Tooltip title="Remove slot">
                                  <IconButton onClick={(event) => handleRemoveSlot(event, weekday, index)}>
                                    <RemoveCircleOutlineIcon color="danger" />
                                  </IconButton>
                                </Tooltip>
                                {/* <Tooltip title="Add notes">
                                  <IconButton>
                                    <TextSnippetIcon />
                                  </IconButton>
                                </Tooltip> */}

                              </Grid>

                            </Grid>
                          ))
                        }
                          {weekday.open && <Button onClick={(event) => handleAddSlot(event, weekday)}>Add slot</Button>
                          }
                        </>

                        <Divider sx={{ mt: 2 }} />

                      </Grid>
                    </Grid>
                  );
                })}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Grid item xs={12} display={"flex"}>
                <Divider orientation="vertical" flexItem />
                <Grid rowSpacing={4} container sx={{ p: 2, width: "100%" }}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        mb: 1,
                        fontWeight: "bold",
                        color: Colors.dim_grey,
                      }}
                    >
                      Start
                    </Typography>
                    <FormControl variant="filled" fullWidth>
                      <DatePickerComponent onDateSelectionDone={handleStartChange} name="date-start" id="schedule-date-start" />
                    </FormControl>
                    {/* <TextField
                      onChange={handleStartChange}
                      value={start}
                      variant="standard"
                      type="date"
                      size="small"
                      fullWidth
                    /> */}
                  </Grid>

                  {/* How often to repeat */}
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        mb: 1,
                        fontWeight: "bold",
                        color: Colors.dim_grey,
                      }}
                    >
                      How often to repeat?
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        variant="standard"
                        labelId="schedule-repeat-select-label"
                        id="schedule-repeat-select"
                        onChange={handleRepeatSelection}
                        value={repeat}
                      >
                        <MenuItem value={""}></MenuItem>
                        <MenuItem value={"norepeat"}>No repeat</MenuItem>
                        <MenuItem value={"weekly"}>Weekly</MenuItem>
                        <MenuItem value={"biweekly"}>Bi-Weekly</MenuItem>
                        {/* <MenuItem value={"monthly"}>Monthly</MenuItem> */}
                        {/* <MenuItem value={"yearly"}>Yearly</MenuItem> */}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Recur every */}
                  {/* <Grid item xs={12}>
                    <Typography
                      sx={{
                        mb: 1,
                        fontWeight: "bold",
                        color: Colors.dim_grey,
                      }}
                    >
                      Recur every
                    </Typography>
                    <Box display="flex" alignItems={"center"}>
                      <TextField
                        variant="filled"
                        type="number"
                        size="small"
                        value={recurCount}
                        onChange={handleRecurCount}
                      />
                      <Typography sx={{ ml: 2 }}>
                        {endsAfter}
                        {recurCount > 1 ? "s" : ""}
                      </Typography>
                    </Box>                    
                    </Grid> */}

                  {/* End after */}
                  {/* <Grid item xs={12}>
                    <Typography
                      sx={{
                        mb: 1,
                        fontWeight: "bold",
                        color: Colors.dim_grey,
                      }}
                    >
                      End after
                    </Typography>
                    <Box display="flex" alignItems={"center"}>
                      <TextField
                        variant="filled"
                        type="number"
                        size="small"
                        value={repeatCount}
                        onChange={handleRepeatCount}
                      />
                      <Typography sx={{ ml: 2 }}>
                        {endsAfter}
                        {repeatCount > 1 ? "s" : ""}
                      </Typography>
                    </Box>
                  </Grid> */}

                  {/* Ends on */}
                  {
                    showEndsOn &&
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          mb: 1,
                          fontWeight: "bold",
                          color: Colors.dim_grey,
                        }}
                      >
                        Ends on
                      </Typography>
                      <DatePickerComponent
                        value={endsOn}
                        onDateSelectionDone={handleEndsOnChange} name="date-end" id="schedule-date-end" />
                      {/*endsOn && <Typography variant="h6">{getDateStringNonISO(endsOn, "MMM, d yyyy")}</Typography>*/}
                    </Grid>
                  }

                  <Grid item xs={12} display="flex">

                    <SelectLocation setLocation={setLocation} location={location.id || ''} sx={{mr: 1}} />
                    <SelectPractioner
                      setPractitioner={setPractitioner} practitioner={practitioner || ''} />
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>Save</Button>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>

      <CopyScheduleMenu
        weekdays={timeslotsForDayOfWeek}
        anchorEl={anchorEl} onClose={() => setAnchorEl(null)}
        handleDaySelect={handleDayToCopySelect}
        copyFromDay={copyFromDay}
        days={daysToCopy} />
    </Dialog>
  );
}
