import { Popper, Box, Typography, Stack, Button } from "@mui/material";
import { useCallback, useReducer, useRef, useState } from "react";
import { Colors } from "../styles/theme";


export default function useConfirmationPopper() {
    const [state, updateState] = useReducer((state, params) => {
        return { ...state, ...params };
    }, {
        open: false,
        anchorEl: null,
        listener: null
    });

    const showPopper = useCallback((anchorEl,l) => {
        updateState({ open: true, listener: l, anchorEl: anchorEl });
    }, []);

    const closePopper = () => {
        updateState({ open: false, listener: null, anchorEl: null });
    }

    const handleYes = () => {
        closePopper();
        state.listener();
    }

    const PopperComponent = useCallback(({ ...props }) => {
        //if (!state.open) return null;
        const { ignoreBackdropClick } = props;

        const onClose = (event, reason) => {
            if (reason === 'backdropClick' && ignoreBackdropClick) return;

            closePopper();
        }

        //if (state.anchorEl) {
        return (
            
            <Popper id={state.open ? 'delete-item-popper' : undefined} sx={{zIndex: 1400}}
                open={state.open}
                anchorEl={state.anchorEl}>
                <Box boxShadow={4} sx={{ borderRadius: 1, p: 1, bgcolor: Colors.white }}>
                    <Typography>Delete this item? </Typography>
                    <Stack direction={"row"}>
                        <Button color="danger" onClick={handleYes}>Yes</Button>
                        <Button color="success" onClick={closePopper}>No</Button>
                    </Stack>

                </Box>
            </Popper>
        )
        //}
    }, [state.open]);

    return [PopperComponent, showPopper]
}

