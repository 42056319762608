import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { data } from './data';
import { capitalize } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



export default function PractitionerListMultiSelect({updatePractitioner, practitionerList, selectedPractitioners}) {

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // setPractitioner(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value,
    // );
    updatePractitioner( typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }} size="small">
        <InputLabel id="practitioner-checkbox-label">Practitioner</InputLabel>
        <Select
          labelId="practitioner-checkbox-label"
          id="practitioner-multiple-checkbox"
          multiple
          value={selectedPractitioners}
          onChange={handleChange}
          input={<OutlinedInput label="Practitioner" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {practitionerList.map((p) => (
            <MenuItem key={p.id} value={p.fullName}>
              <Checkbox checked={selectedPractitioners.indexOf(p.fullName) > -1} />
              <ListItemText primary={capitalize(p.fullName)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
