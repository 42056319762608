import { ButtonWithIcon } from "../../ui";
import AddIcon from '@mui/icons-material/Add';
import { capitalize, IconButton,alpha, Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Colors } from "../../../styles/theme";
import { useEffect, useRef, useState } from "react";
import { isLocalDev } from "../../../utils/environment";
import { AppointmentService } from "../../../service/appointmentService";
import { toast } from "react-toastify";
import { UI_ACTION_TYPE, useUIContext } from "../../../state/ui";
import { niceDate, niceTime } from "../../../utils";
import useDialogModal from "../../../hooks/useDialogModal";
import useAbortController from "../../../hooks/useAbortController";
import { withPatient } from "../../hoc/withPatient";
import BookAppointmentDialog from "../../schedule/bookAppointmentDialog";

export default function AppointmentTab({ patientId }) {
    const { dispatch } = useUIContext();
    const [patientAppointments, setPatientAppointments] = useState([]);
    const [BookAppointmentDialogModal, showBookAppointmentDialog] =
        useDialogModal(withPatient(BookAppointmentDialog, patientId));
    let [signal, abortRequest] = useAbortController();

    useEffect(() => {
        loadAppointments();

        return () => {
            abortRequest();
        }
    }, [patientId]);

    // load the latest appointments
    const loadAppointments = async () => {
        try {
            dispatch({
                loading: true,
                type: UI_ACTION_TYPE.LOADING,
                loadingtext: "Loading appointments...",
            });
            const appointmentResponse = await AppointmentService.getByPatientId(patientId, signal);
            dispatch({ loading: false, type: UI_ACTION_TYPE.LOADING });
            const { code, data, message } = { ...appointmentResponse };

            if (code === 0) {
                setPatientAppointments(data);
            }
        } catch (error) {
            dispatch({ loading: false, type: UI_ACTION_TYPE.LOADING });
            if (error !== 'canceled')
                toast.error("Failed to load appointments");

            if (isLocalDev) {
                console.log(error);
            }
        }
    };

    const handleBookAppointment = () => {
        showBookAppointmentDialog();
    }

    const handleAppointmentDelete = (appointment) => {
        console.log("delete", appointment);
    }

    const handleOnBook = () => {
        loadAppointments();
    }

    return (
        <Box display="flex" flexDirection="column">
            <ButtonWithIcon
                icon={<AddIcon />}
                title="Book Appointment"
                variant="contained" size="small" sx={{ alignSelf: 'end', mb: 1 }} onClickHandler={handleBookAppointment} />

            {
                patientAppointments.length > 0 ?
                    <>
                        <Typography>Upcomming Appointments</Typography>
                        <TableContainer>
                            <Table size="small" >
                                <TableHead sx={{ background: alpha(Colors.primary, 0.5) }}>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Time</TableCell>
                                        <TableCell>Duration</TableCell>
                                        <TableCell>Practitioner</TableCell>
                                        <TableCell>Location</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        patientAppointments.map(appointment => (
                                            <TableRow key={appointment.id}>
                                                <TableCell>{niceDate(appointment.startDate)}</TableCell>
                                                <TableCell>{niceTime(appointment.startDate)}</TableCell>
                                                <TableCell>{appointment.duration} min</TableCell>
                                                <TableCell>{capitalize(appointment.Employee.fullName)}</TableCell>
                                                <TableCell>{capitalize(appointment.Location.alias)}</TableCell>
                                                <TableCell sx={{textTransform: 'uppercase'}} >
                                                    <Stack flexDirection={'row'} alignItems="center">
                                                    <Box
                                                        sx={{
                                                            width: '10px',
                                                            height: '10px',
                                                            background: Colors[appointment.status],
                                                            borderRadius: '50%',
                                                            marginRight: '2px'
                                                        }}
                                                    >
                                                    </Box>
                                                    <Typography variant="caption">{appointment.status}</Typography>
                                                    </Stack>
 
                                                </TableCell>
                                                <TableCell >

                                                    <IconButton onClick={() => handleAppointmentDelete(appointment)}>
                                                        <Tooltip title="Cancel" placement="left">
                                                            <DeleteForeverIcon color="danger" />
                                                        </Tooltip>
                                                    </IconButton>

                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <BookAppointmentDialogModal onBooked={handleOnBook} />
                    </>

                    :
                    <Typography
                        variant="h4"
                        textAlign={"center"}
                        sx={{ mt: "10%", color: Colors.muted }}
                    >
                        No appointments booked
                    </Typography>
            }
        </Box>
    )
}