import { Typography, Box } from "@mui/material";
import { Colors } from "../../styles/theme";
import { DayHeader } from "../calendar/header";
import { CalendarHeader, PractitionerEvents, ResourceContainer } from "./styles";
import { getTimeString, toTitleCase } from "../../utils";
import Slot  from "./slot";

function Resource({ name, time }) {

    return (
        <Box display="flex" flexDirection={"column"} sx={{background: Colors.muted, padding: '2px'}}>
            <Typography sx={{ fontSize: '1.25rem' }} fontWeight="bold">{toTitleCase(name)}</Typography>
            <Typography variant="caption">{time}</Typography>
        </Box>
    )
}

export default function DayView({ activeSlot,draggedSlot, activePractitioners, onSlotClicked }) {

    return (
        <>
            <CalendarHeader>
                <DayHeader />
                {
                    activePractitioners.length > 0 &&
                    <ResourceContainer
                        columns={activePractitioners.length}
                        gridTemplateAreas={activePractitioners.map(ap => 'ap-' + ap.id).join(' ')}>
                        {
                            activePractitioners.map(r => <Resource
                                key={r.id}
                                name={r.fullName}
                            />)
                        }
                        {
                            activePractitioners.map(ap =>
                                ap.slots.map(s =>
                                    <PractitionerEvents top={40} key={s.id} gridIndex={`ap-${ap.id}`}>
                                        <Slot  
                                        activeSlot={activeSlot}
                                        draggedSlot={draggedSlot}
                                        practitionerid={ap.id}
                                        id={s.id}
                                        onClick={() => onSlotClicked(s)}
                                        start={getTimeString(s.startDate)}
                                        end={getTimeString(s.endDate)}
                                        name={s.name} 
                                        status={s.status} />
                                    </PractitionerEvents>

                                )

                            )
                        }

                    </ResourceContainer>
                }


            </CalendarHeader>

        </>

    )
}

// time={`${niceTime(r.startTime, 'HH:mm', false)} - ${niceTime(r.endTime, 'HH:mm', false)}`}