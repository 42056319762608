
import { useEffect, useState } from "react";
import validLookup from "../../../utils/validLookup";
import { getRowPosition } from "../helpers";
import { OneSlot, ResizeHandle } from "../../../styles/calendar";
import { getHeight } from "../helpers";
import useEffectOnUpdates from "../../../hooks/useEffectOnUpdates";


const multiplier = 0.99;


export default function GroupByPractitionersSlots({ slotData, onEventClicked, onAvailableSlotClicked, collidesWith}) {

    const [bookedSlots, setBookedSlots] = useState([]);
    const [availableSlots, setAvailableSlots] = useState([]);

    useEffectOnUpdates(() => {
        // go through booked slots and mark the one for collision
        // Need to make sure it's for the same practitioner
        for(let b=0; b<bookedSlots.length; b++) {
            const bs = bookedSlots.at(b);
            const rowStart = getRowPosition(collidesWith.starttime);

            if( bs.row === rowStart && bs.col === collidesWith.column + 1 ) {
                bs.isColliding = true;
                setBookedSlots([...bookedSlots, {...bookedSlots[b],  ...bs}]);
                break;
            }
        }
        //setTargetForCollision(collidesWith && starttime === collidesWith.starttime);
    },[collidesWith]);

    const processBookedSlots = (col, width, bookedTime,practitionerid) => {

        for (var i = 0; i < bookedTime.length; i++) {
            const slot = bookedTime[i];
            
            const { starttime, endtime } = { ...slot };
            // get row pos
            const row = getRowPosition(starttime);
            
            const availableSlotheight = getHeight(starttime, endtime);

            const oneSlot = {
                id: slot.id,
                width: width,
                height: availableSlotheight,
                left: 0,
                row: row,
                col: col,
                isColliding: false,
                practitionerid: practitionerid,
                status: validLookup.validSlotStatus.booked,
                ...slot
            };
            setBookedSlots((e) => [...e, oneSlot]);
        }
    }

    const processSlots = () => {
        setAvailableSlots([]);
        setBookedSlots([]);

        // process available slots
        for (var i = 0; i < slotData.data.length; i++) {
            const slot = slotData.data[i];
            const availableslot = slot.availabletime;
            const bookedTime = slot.bookedtime;

            // get col pos
            // col pos is same as it's index in the array
            const col = i + 1;

            // get row pos
            const row = getRowPosition(availableslot.startTime);

            const perc = 100 / (slotData.data.length + 1 - col);
            const percW = Math.floor(perc);
            let width = percW * multiplier;
            const availableSlotheight = getHeight(availableslot.startTime, availableslot.endTime);

            const oneSlot = {
                width: width ,
                height: availableSlotheight,
                left: 0,
                row: row,
                col: col,
                duration: availableslot.duration,
                practitionerId: slot.practitionerId,
                status: validLookup.validSlotStatus.available,
                ...availableslot,
            };
            setAvailableSlots((e) => [...e, oneSlot]);

            // process booked slots
            processBookedSlots(col, width, bookedTime, slot.practitionerId);
        }
    }

    useEffect(() => {
        processSlots();
    }, [slotData.tick]); 

    const handleDragStart = (ev, event) => {
        ev.dataTransfer.setData('text/plain', JSON.stringify(event));
        event.start = 'drag';
      }

    const renderAvailableSlots = availableSlots.map((a, i) => {
        return (
            <OneSlot
                hover={false}
                draggable="false"
                onClick={() => onAvailableSlotClicked(a) }
                key={i}
                row={a.row}
                {...a}
                column={a.col} />
        )
    });

    const renderBookedSlots = bookedSlots.map((b, i) => {
        return (
            <OneSlot
                hover={false}
                draggable="true"
                onDragStart={(ev) => handleDragStart(ev, b)}
                onClick={() => onEventClicked(b)}
                key={i}
                {...b}
                isColliding={b.isColliding}
                column={b.col}>
                {b.starttime}{"-"}{b.endtime}
                {/* FIX ME: hover does not work
                <ResizeHandle> 
                    <span></span>
                    <span></span>
                    <span></span>
                </ResizeHandle> */}
            
            </OneSlot>
        )
    });

    return (
        <>
            {renderAvailableSlots}
            {renderBookedSlots}
        </>
    )
}