import { alpha, createTheme } from "@mui/material/styles";
import color from "./constants";
import dimensions from "./constants";

export const Colors = {
  ...color.color,
};

export const Dimensions = {
  ...dimensions.dimensions,
};

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.primary,
    },
    secondary: {
      main: Colors.secondary,
    },
    success: {
      main: Colors.success
    },
    warning: {
      main: Colors.warning
    },
    info: {
      main: Colors.info
    },
    danger: {
      main: Colors.danger
    }
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          color: Colors.danger
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 2,
        }
      }
    },
    MuiPaginationItem: {
      
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: alpha(Colors.primary, 0.5)
          }
        }

      }
    }
  },

});

export default theme;
