import {
    TableContainer, Box,
    Paper, TableBody, TableHead, TableRow, TableCell, Table, IconButton, Button
} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from "react";
import { LocationService } from "../../../service/locationService";

export default function ManageLocation() {
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState([]);

    useEffect(() => {
        LocationService.getLocations().then(result => {
            if (result.code === 0) {
                setLocation(result.data);
            }
        }).catch(error => console.log(error));
    }, []);

    return (
        <Box>
            <Button sx={{ mb: 2 }} variant="contained" startIcon={<AddIcon />}>Add</Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Address</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>Province</TableCell>
                            <TableCell>Postal Code</TableCell>
                            <TableCell>Country</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>Fax</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            location.map(l =>
                                <TableRow key={l.id}>
                                    <TableCell>{l.addrNo}{" "}{l.addrStreet}</TableCell>
                                    <TableCell>{l.addrCity}</TableCell>
                                    <TableCell>{l.addrProvince}</TableCell>
                                    <TableCell>{l.addrPostal}</TableCell>
                                    <TableCell>{l.addrCountry}</TableCell>
                                    <TableCell>{l.addrPhone}</TableCell>
                                    <TableCell>Fax</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>
                                        <IconButton>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>

            </TableContainer>
        </Box>

    )

}