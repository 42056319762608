import { useRef, useState } from "react";


/**
 * These events are fired by calendar whenever view changes and anyone interested in them 
 * can just register and get notified.
 * E.g. week view should show date period as Month Year - Month Year
 * but Day view should only show Month Year
 * and Month view should only show year
 * 
 * @returns 
 */
export function useActiveView() {
    //const listeners = useRef([]);
    const [activeView, setActiveView] = useState('week');

    // const addViewListener = ((listener) => {
    //     listeners.current = listeners.current.concat(listener);
    //     return () => {
    //         listeners.current = listeners.current.filter(l => l != listener);
    //     }
    // });

    const onViewChanged = (view) => {
        setActiveView(view);
        //listeners.current.forEach(listener => listener(view));
    }

    return { activeView, onViewChanged };
}