import { Typography } from "@mui/material";
import { addMinutes } from "date-fns";
import { useEffect, useState } from "react";
import { useAppContext } from "../../state/appState";
import { Timeslot, TimeslotsContainer, TimeslotsHolder } from "../../styles/calendar";
import { niceTime, timeFromString } from "../../utils";

export default function Timebar() {

    const { appState } = useAppContext();
    const [timeslots, setTimeslots] = useState([]);

    useEffect(() => {
        const businessStart = timeFromString(appState.businessStartHour);
        const businessEnd = timeFromString(appState.businessClosingHour);
        const slotInterval = appState.timeSlotInterval;
        for (let interval = businessStart; interval < businessEnd; interval = addMinutes(interval, slotInterval * 2)) {

            setTimeslots(ts => [...ts, niceTime(interval.toISOString())]);
        }
    }, [appState]);

    return <TimeslotsContainer>
        <TimeslotsHolder>
            {timeslots.map((slot, index) => (
                <Timeslot key={index}><Typography sx={{mt: 1, p: 0}} fontSize={"0.85rem"}>{slot}</Typography></Timeslot>
            ))}
        </TimeslotsHolder>
    </TimeslotsContainer>;
}