
import {
  IconButton,
  Toolbar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar } from "../../styles/appbar";
import { useUIContext } from "../../state/ui";
import AccountMenu from "./account-menu";
import BrandName from "./brandname";
import UserMessages from "./user-messages";
import UserNotifications from "./user-notifications";

function Appbar() {
  const { drawerOpen, setDrawerOpen } = useUIContext();

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  return (
    <>
      <AppBar position="fixed" open={drawerOpen} elevation={0}>
        <Toolbar>
          <IconButton
            onClick={handleDrawerOpen}
            sx={{
              color: "#fff",
            }}
            aria-label="open drawer"
            edge="start"
          >
            {!drawerOpen && <MenuIcon />}
          </IconButton>
          <BrandName />
          <UserNotifications />
          <UserMessages />
          <AccountMenu />
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Appbar;
