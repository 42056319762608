import { useEffect, useState } from "react";
import { appSettings } from "../../../data";
import { CalendarProvider, useCalendarContext } from "../../../state/calendar";
import {
  CalendarHeader,
  TimeslotsContainer,
  TimeslotsHolder,
  Timeslot,

  CurrentTimeIndicator,
} from "../../../styles/calendar";
import Header, { WeekHeader } from "../header";
import { getRowPosition } from "../helpers";


const timeslots = [
  "9 am",
  "10 am",
  "11 am",
  "12 pm",
  "1 pm",
  "2 pm",
  "3 pm",
  "4 pm",
  "5 pm",
  "6 pm",
  "7 pm",
  "8 pm",
];

export default function WeekView() {
  // we use -1 to toggle the visibility
  const [timeIndicatorOffset, setTimeIndicatorOffset] = useState(-1);
  const { timeIndicator, activeWeek } = useCalendarContext();
  const { state } = useCalendarContext();

  useEffect(() => {

    const d = new Date();

    // should only do this if within business hours
    const h = d.getHours();
    if( h >= appSettings.businessStartHour && h <= appSettings.businessClosingHour) {
      const rowPos = getRowPosition(`${h}:${d.getMinutes()}`);
      setTimeIndicatorOffset((rowPos - 1) * appSettings.timeSlotInterval + 60);
    }
  },[]);

  return (
    <CalendarProvider>
      <CalendarHeader>
        <Header days={7} render={data => (<WeekHeader activeWeek={state.activeWeek}/>)} />
      </CalendarHeader>
      <TimeslotsContainer>
        <TimeslotsHolder>

        {timeIndicator && <CurrentTimeIndicator topOffset={timeIndicatorOffset}/>}
          {timeslots.map((slot, index) => (
            <Timeslot key={index}>{slot}</Timeslot>
          ))}
        </TimeslotsHolder>
      </TimeslotsContainer>
    </CalendarProvider>
  );
}
