import { ThemeProvider } from "@mui/material/styles";
import Styles from "./styles";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./Routes";
import { UIProvider } from "./state/ui";
import Toast from "./components/ui/toast";
import './styles/react-date-picker.css';
import './App.css';
import AppLoader from "./components/ui/loading";
import { AppProvider } from "./state/appState";

function App() {

  return (
    <AppProvider>
      <ThemeProvider theme={Styles.theme}>
        <Router basename={process.env.REACT_APP_BASENAME}>
          <UIProvider>
            <AppRoutes />
            <Toast />
            <AppLoader />
          </UIProvider>
        </Router>
      </ThemeProvider>
    </AppProvider>

  );
}

export default App;
