
import { styled } from '@mui/material';
import Tab from '@mui/material/Tab';

export const AppTab = styled(Tab, {
    shouldForwardProp: (props) => props !== 'secondaryText'
})(({secondaryText, count}) => ({
    '&:after': {
        content: `"${secondaryText}"`,
        fontSize: '0.95em'
        
    },
    '&:before': {  
        content: `""`,
        width: '12px',
        height: '12px',
        
    }
}));