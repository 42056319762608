import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ReceiptIcon from '@mui/icons-material/Receipt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MessageIcon from '@mui/icons-material/Message';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import FlagIcon from '@mui/icons-material/Flag';

const data = [
    {
        date: 'March 10, 2020',
        action: 'pickup',
        description: 'Order picked up',
        user: 'Marry Jane'
    },
    {
        date: 'March 05, 2020',
        action: 'message',
        description: 'Message sent',
        user: 'Marry Jane'
    },
    {
        date: 'February 28, 2020',
        action: 'order',
        description: 'Glasses order',
        user: 'Garry Jane'
    },
    {
        date: 'February 28, 2020',
        action: 'eyeexam',
        description: 'Eyeexam done',
        user: 'Garry Jane'
    },
    {
        date: 'February 25, 2020',
        action: 'message',
        description: 'Message sent',
        user: 'Garry Jane'
    },
    {
        date: 'February 10, 2020',
        action: 'appointment',
        description: 'Appointment booked',
        user: 'Garry Jane'
    },
    {
        date: 'February 10, 2020',
        action: 'start',
        description: 'Account Created',
        user: 'Garry Jane'
    },
]

export default function PatientTimeline() {
    return (

        <Timeline position="right" sx={{ maxHeight: '600px', overflow: 'scroll' }}>

            {
                data.map(item => (
                    <TimelineItem>
                        <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            variant="body2"
                            color="text.secondary"
                        >
                            {item.date}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot color="primary" variant="outlined">
                                {{
                                    'pickup': <ThumbUpAltIcon />,
                                    'order': <ReceiptIcon />,
                                    'message': <MessageIcon />,
                                    'eyeexam': <VisibilityIcon />,
                                    'appointment': <CalendarTodayIcon />,
                                    'start': <FlagIcon />
                                }[item.action]}
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h6" component="span">
                                {item.description}
                            </Typography>
                            <Typography>                            {{
                                    'pickup': `picked by: ${item.user}`,
                                    'order': `created by: ${item.user}`,
                                    'message': `sent by: ${item.user}`,
                                    'eyeexam': `Optometrist: ${item.user}`,
                                    'appointment': `booked by: ${item.user}`,
                                    'start': `created by: ${item.user}`
                                }[item.action]}</Typography>
                        </TimelineContent>
                    </TimelineItem>
                ))
            }   

        </Timeline>
    );
}