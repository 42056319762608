import { useRef } from "react";


/**
 * These events are fired by calendar and anyone interested in them 
 * can just register and get notified.
 * E.g. Header can go to next week or prev week
 * and events can also be notified to load proper events
 * 
 * @returns 
 */
export function useCalendarEvents() {
    const listeners = useRef([]);

    const addEventListener = (listener) => {
      listeners.current = listeners.current.concat(listener);
      return () => {
        listeners.current = listeners.current.filter((l) => l !== listener);
      };
    };

    const onAdded = (data) => {
        listeners.current.forEach(listener => listener({ type: 'add', data: data }));
    }

    const onEdit = (data) => {
      listeners.current.forEach(listener => listener({ type: 'edit', data: data }));
  }

    const onRemoved = (data) => {
        listeners.current.forEach(listener => listener({ type: 'remove', data: data }));
    }
    const onResourceUpdated = (data) => {
      listeners.current.forEach(listener => listener({type: 'onresourceupdate', data: data}));
  }
    return { addEventListener, onAdded, onEdit, onRemoved,onResourceUpdated };
}