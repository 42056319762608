import { alpha,Box, Button, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { addYears } from "date-fns";
import { useFormik } from "formik";
import { useReducer, useState } from "react";
import { Colors } from "../../../../styles/theme";
import { dateFromString, getDateString, getDateStringNonISO } from "../../../../utils";
import { SelectPractioner } from "../../../common";
import AppDialogTitle from "../../../ui/appDialogTitle";
import DatePickerComponent from "../../../ui/datePickerComponent";
import { contactLensModel } from "../../model/contactLensModel";


export default function AddContactsRxDialog({ onClose, open }) {

    const [state, updateState] = useReducer((state, params) => {
        return { ...state, ...params };
    }, {
        practitioner: '',
        manufacturer: '',
        model: '',
        startDate: new Date(),
        endDate: addYears(Date.now(), 1)
    });

    const formik = useFormik({
        initialValues: { ...contactLensModel },
        onSubmit: (data) => {
            console.log(data);
        }
    });

    const handleStartDataSelect = (date) => {
        const dt = dateFromString(date);
        updateState({
            startDate: date,
            endDate: addYears(dt, 1)
        })
    }

    const handleSave = () => {
        console.log('save');
    }

    return (
        <Dialog onClose={onClose} open={open} fullWidth maxWidth={"xl"} >
            <AppDialogTitle onClose={onClose} title="New Contact Lens Rx" />
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>

                    <Grid container columnGap={1} sx={{ mb: 1 }}>
                        <Grid item sx={{ background: '#f6f6f6', p: 1 }}>
                            <Box display="flex">
                                <Stack>
                                    <SelectPractioner
                                        sx={{ mb: 2 }}
                                        setPractitioner={(practitioner) => formik.getFieldHelpers('practitioner').setValue(practitioner)}
                                        practitioner={formik.getFieldProps('practitioner').value} />

                                    <FormControl sx={{ mb: 2 }}>
                                        <FormLabel>Start Date</FormLabel>
                                        <DatePickerComponent value={state.startDate} onDateSelectionDone={handleStartDataSelect} name="date-start" id="schedule-date-start" />
                                    </FormControl>

                                    <FormControl sx={{ mb: 2 }}>
                                        <FormLabel>Expiry Date</FormLabel>
                                        <DatePickerComponent value={state.endDate} onDateSelectionDone={(date) => updateState({ endDate: date })} name="date-start" id="schedule-date-start" />
                                    </FormControl>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item sx={{ background: '#f6f6f6', p: 1, minWidth: '200px' }}>
                            <Box sx={{ mb: 1 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="manufacturer-select-label">Manufacturer</InputLabel>
                                    <Select
                                        size="small"
                                        labelId="manufacturer-select-label"
                                        label="Manufacturer"
                                        name="manufacturer"
                                        onChange={formik.handleChange}
                                        value={formik.values.manufacturer}
                                        fullWidth>
                                        <MenuItem value="1">Johnson & Johnson vision</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl fullWidth>
                                    <InputLabel id="model-select-label">Model</InputLabel>
                                    <Select size="small"
                                        labelId="model-select-label"
                                        label="Model"
                                        name="model"
                                        onChange={formik.handleChange}
                                        value={formik.values.model} fullWidth>
                                        <MenuItem value="1">1-Day Acuvue Define</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{ mt: 1 }}>
                                <FormControl>
                                    <FormLabel id="contact-lens-rx-type">Type</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="contact-lens-rx-type"
                                        defaultValue="soft"
                                        name="type"
                                        onChange={formik.handleChange}
                                        value={formik.values.type}
                                    >
                                        <FormControlLabel value="soft" control={<Radio />} label="Soft" />
                                        <FormControlLabel value="hard" control={<Radio />} label="Hard" />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Grid>

                    </Grid>


                    <TableContainer>
                        <Table size="small" >
                            <TableHead sx={{ background: alpha(Colors.primary, 0.5) }}>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Sphere</TableCell>
                                    <TableCell>Cylinder</TableCell>
                                    <TableCell>Axis</TableCell>
                                    <TableCell>Add</TableCell>
                                    <TableCell>BC</TableCell>
                                    <TableCell>Diameter</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <TableRow >
                                    <TableCell>OD</TableCell>
                                    <TableCell><TextField
                                        name="odSphere"
                                        id="odSphere"
                                        onChange={formik.handleChange}
                                        value={formik.values.odSphere}
                                        size="small" /></TableCell>
                                    <TableCell><TextField name="odCylinder"
                                        id="odCylinder"
                                        onChange={formik.handleChange}
                                        value={formik.values.odCylinder}
                                        size="small" /></TableCell>
                                    <TableCell><TextField name="odAxis"
                                        id="odAxis"
                                        onChange={formik.handleChange}
                                        value={formik.values.odAxis}
                                        size="small" /></TableCell>
                                    <TableCell><TextField name="odAdd"
                                        id="odAdd"
                                        onChange={formik.handleChange}
                                        value={formik.values.odAdd}
                                        size="small" /></TableCell>
                                    <TableCell><TextField name="odBc"
                                        id="odBc"
                                        onChange={formik.handleChange}
                                        value={formik.values.odBc}
                                        size="small" /></TableCell>
                                    <TableCell><TextField name="odDiameter"
                                        id="odDiameter"
                                        onChange={formik.handleChange}
                                        value={formik.values.odDiameter}
                                        size="small" /></TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell>OS</TableCell>
                                    <TableCell><TextField
                                        name="osSphere"
                                        id="osSphere"
                                        onChange={formik.handleChange}
                                        value={formik.values.osSphere}
                                        size="small" /></TableCell>
                                    <TableCell><TextField
                                        name="osCylinder"
                                        id="osCylinder"
                                        onChange={formik.handleChange}
                                        value={formik.values.osCylinder}
                                        size="small" /></TableCell>
                                    <TableCell><TextField
                                        name="osAxis"
                                        id="osAxis"
                                        onChange={formik.handleChange}
                                        value={formik.values.osAxis}
                                        size="small" /></TableCell>
                                    <TableCell><TextField
                                        name="osAdd"
                                        id="osAdd"
                                        onChange={formik.handleChange}
                                        value={formik.values.osAdd}
                                        size="small" /></TableCell>
                                    <TableCell><TextField
                                        name="osBc"
                                        id="osBc"
                                        onChange={formik.handleChange}
                                        value={formik.values.osBc}
                                        size="small" /></TableCell>
                                    <TableCell><TextField name="osDiameter"
                                        id="osDiameter"
                                        onChange={formik.handleChange}
                                        value={formik.values.osDiameter}
                                        size="small" /></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TextField
                        sx={{ mb: 1 }}
                        name="comment"
                        onChange={formik.handleChange}
                        value={formik.values.comment}
                        multiline rows={4} id="pr-comment" label="Comment" variant="standard" fullWidth />

                </DialogContent >
                <DialogActions>
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                    <Button color="primary" variant="contained" type="submit" autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog >
    )
}