import { Card, CardContent, CardHeader, Divider, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Typography } from "@mui/material";
import { TextField, Grid } from "@mui/material";
import { Colors } from "../../../styles/theme";
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';

export default function GeneralInfoCard({ formik }) {

    const handlePhone = (event) => {
        formik.getFieldHelpers('phone1').setValue(new AsYouType('US').input(event.target.value));
    }

    return (
      <Card elevation={1}>
        <CardHeader title="General" sx={{ color: Colors.muted }} />
        <Divider />
        <CardContent>
          <Grid container columnSpacing={1}>
            <Grid item xs={12} md={4}>
              <TextField
                required
                name="firstName"
                id="firstName"
                onChange={formik.handleChange}
                value={formik.values.firstName}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
                label="First name"
                variant="standard"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="middleName"
                value={formik.values.middleName}
                onChange={formik.handleChange}
                id="pr-middle-name"
                label="Middle name"
                variant="standard"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                required
                name="lastName"
                id="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
                label="Last name"
                variant="standard"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={1} alignItems="end" mt={1}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                name="phone1"
                value={formik.values.phone1}
                onChange={handlePhone}
                error={
                  formik.touched.phone1 &&
                  !isValidPhoneNumber(formik.values.phone1, "US")
                }
                helperText={
                  !isValidPhoneNumber(formik.values.phone1, "US") &&
                  "Invaild phone"
                }
                id="phone1"
                label="Phone1"
                variant="standard"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="pr-phone1desc">Phone type</InputLabel>
                <Select
                  name="phone1Desc"
                  id="pr-phone1Desc"
                  value={formik.values.phone1Desc}
                  onChange={formik.handleChange}
                  label="Phone Type"
                  fullWidth
                  size="small"
                  variant="standard"
                >
                  <MenuItem value={"none"}>None</MenuItem>
                  <MenuItem value={"cell"}>Cell</MenuItem>
                  <MenuItem value={"home"}>Home</MenuItem>
                  <MenuItem value={"fax"}>Fax</MenuItem>
                  <MenuItem value={"work"}>Work</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container columnSpacing={1} alignItems="end">
            <Grid item xs={12} md={6}>
              <TextField
                name="phone2"
                value={formik.values.phone2}
                onChange={formik.handleChange}
                id="pr-phone2"
                label="Phone2"
                variant="standard"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="pr-phone2desc">Phone type</InputLabel>
                <Select
                  name="phone2Desc"
                  id="pr-phone2Desc"
                  value={formik.values.phone2Desc}
                  onChange={formik.handleChange}
                  label="Phone Type"
                  fullWidth
                  size="small"
                  variant="standard"
                >
                  <MenuItem value={"none"}>None</MenuItem>
                  <MenuItem value={"cell"}>Cell</MenuItem>
                  <MenuItem value={"home"}>Home</MenuItem>
                  <MenuItem value={"fax"}>Fax</MenuItem>
                  <MenuItem value={"work"}>Work</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container columnSpacing={1} alignItems="end">
            <Grid item xs={12} md={6}>
              <TextField
                name="phone3"
                value={formik.values.phone3}
                onChange={formik.handleChange}
                id="pr-phone3"
                label="Phone3"
                variant="standard"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="pr-phone3desc">Phone type</InputLabel>
                <Select
                  name="phone3Desc"
                  id="pr-phone3Desc"
                  value={formik.values.phone3Desc}
                  onChange={formik.handleChange}
                  label="Phone Type"
                  fullWidth
                  size="small"
                  variant="standard"
                >
                  <MenuItem value={"none"}>None</MenuItem>
                  <MenuItem value={"cell"}>Cell</MenuItem>
                  <MenuItem value={"home"}>Home</MenuItem>
                  <MenuItem value={"fax"}>Fax</MenuItem>
                  <MenuItem value={"work"}>Work</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <FormControl sx={{ mt: 1 }}>
            <FormLabel id="sex" sx={{display: 'flex'}}>Gender<Typography color={Colors.danger}>*</Typography></FormLabel>
            <RadioGroup
              required
              name="sex"
              error={
                formik.touched.sex && Boolean(formik.errors.sex)
              }
              value={formik.values.sex}
              onChange={formik.handleChange}
              row
            >
              <FormControlLabel
                value="female"
                control={<Radio size="small" />}
                label="Female"
              />
              <FormControlLabel
                value="male"
                control={<Radio size="small" />}
                label="Male"
              />
              <FormControlLabel
                value="other"
                control={<Radio size="small" />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>
        </CardContent>
      </Card>
    );
}