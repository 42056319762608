import { useEffect, useState } from "react"
import useAbortController from "../../hooks/useAbortController";
import { PatientService } from "../../service/patientService";


export const withPatient = (Component, patientId) => {
    return props => {
        const [patient, setPatient] = useState(null);
        const [signal, abortRequest] = useAbortController();

        useEffect( () => {
            (async () => {
                const patientResult = await PatientService.getById(patientId, signal);
                const {code, data, message} = {...patientResult};
                
                if( code === 0 ) {
                    setPatient(data);
                }
            })();

            return () => {
                abortRequest();
            }
        },[]);

        return <Component {...props} patient={patient} />
    }
}