import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useUIContext } from "../../../state/ui";
import Styles from "../../../styles";
import { Typography } from "@mui/material";
import { DrawerHeader } from "../../../styles/drawer";

export default function NotificationDrawer() {
  const { notificationDrawerOpen, setNotificationDrawerOpen } = useUIContext();
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setNotificationDrawerOpen(open);
  };
  const list = () => (
    <Box
      sx={{ width: Styles.dimensions.drawerWidth }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <List>
        {[
          "Call John for confirmation",
          "Fax invoices for insurance",
          "finish lab order # 123",
          "Request for day off :)",
        ].map((text, index) => (
          <ListItem button key={text}>
            <ListItemText
              primary={text}
              primaryTypographyProps={{
                fontSize: 16,
                letterSpacing: 0,
              }}
            />
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );

  return (
    <div>
      <Drawer anchor="right" open={notificationDrawerOpen}>
        <DrawerHeader></DrawerHeader>
        {list()}
      </Drawer>
    </div>
  );
}
