import { Colors } from "../../../styles/theme";
import { Button, Dialog, DialogActions, DialogContent, Grid, capitalize } from "@mui/material";
import GeneralInfoCard from "./generalInfoCard";
import AddressInfoCard from "./addressInfoCard";
import AdditionalInfoCard from "./additionalInfoCard";
import CommentsCard from "./commentsCard";
import { useFormik } from "formik";
import "yup-phone-lite";
import { PatientService } from "../../../service/patientService";
import { UI_ACTION_TYPE, useUIContext } from "../../../state/ui";
import { isLocalDev } from "../../../utils/environment";
import { toast } from "react-toastify";
import AppDialogTitle from "../../ui/appDialogTitle";
import { patientModel } from "../model/profileModel";
import { validationSchema } from "../model/validationSchema";
import SearchPatient from "../searchPatient";

export default function LinkFamilyMemberDialog({
    open,
    onClose,
    onDone,
    linkTo  
}) {

    const { dispatch } = useUIContext();

    // for the phonenumber parsing have to add country code e.g. +1 for US/CAD
    // parsePhoneNumber('+14164070024').format('NATIONAL')
    const formik = useFormik({
        initialValues: {
            ...patientModel
        },
        initialStatus: 'start',
        validationSchema: validationSchema,
        onSubmit: async (formData) => {            

            dispatch({loading: true, type: UI_ACTION_TYPE.LOADING,loadingtext: "Creating record...Please wait" });
            try {
                const patientServiceResponse = await PatientService.linkfamily(linkTo.id, formData);
                const {
                    code,
                    data,
                    message
                } = {...patientServiceResponse};
                dispatch({loading: false, type: UI_ACTION_TYPE.LOADING});
                if( code === 0 ) {
                    toast.success(message);
                    onDone(data);
                    onClose();
                }
                else {
                    toast.error(message);
                }
            } catch (error) {
                if( isLocalDev )
                    console.log(error);

                dispatch({loading: false, type: UI_ACTION_TYPE.LOADING});
                toast.error(error.data.message);

            }
        }
    });

    const handlePatientSelect = (patient) => {
        formik.setValues(patient);
    }

    return (
      <Dialog onClose={onClose} open={open} fullWidth maxWidth={"xl"}>
        <AppDialogTitle title={`Link to ${capitalize(linkTo.fullName)}`} onClose={onClose} />

        <DialogContent sx={{ background: Colors.dove_gray }}>
          <Grid container pt={1} columnSpacing={1} rowSpacing={0}>
            <Grid item xs={6}>
            <Grid container rowSpacing={1}>
              <Grid item xs={12}>
                <SearchPatient filterPatient={linkTo} onDone={handlePatientSelect} />
              </Grid>
            </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                  <GeneralInfoCard formik={formik} />
                </Grid>
                <Grid item xs={12}>
                  <AdditionalInfoCard formik={formik} />
                </Grid>

                <Grid item xs={12}>
                  <AddressInfoCard formik={formik} />
                </Grid>
                <Grid item xs={12}>
                  <CommentsCard formik={formik} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <>
            <Button
              disabled={!formik.isValid || !formik.dirty}
              autoFocus
              type="button"
              onClick={() => formik.submitForm()}
            >
              Create
            </Button>
            <Button onClick={onClose} sx={{ color: Colors.muted }}>
              Cancel
            </Button>
          </>
        </DialogActions>
      </Dialog>
    );
}