import { DayBox, DayNumber } from "../../../styles/calendar";
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";

export const GroupByPractitionersHeader = ({resources}) => {

  const [daysOfWeek, setDaysOfWeek] = useState();

  useEffect(() => {
    setDaysOfWeek(
      resources.map((r, index) => {

        return (
          <li key={index}>
            <DayBox>
              <DayNumber>
                <Typography variant="h6">{r.name}</Typography>
              </DayNumber>
            </DayBox>
          </li>
        );
      })
    );
  }, [resources]);

  return (
    <>
      {daysOfWeek}
    </>
  );
};
