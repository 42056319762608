import {
    Box,
    Button,
    Divider,
    Drawer,
    IconButton,
    Switch,
    Typography,
} from "@mui/material";
import { useEffect, useReducer, useState } from "react";
import Styles from "../../styles";
import { Colors } from "../../styles/theme";
import AppDrawerHeader from "../appointments/dialog/book-appointment-dialog/appDrawerHeader";
import { StartTimeControl } from "../admin/ManageSchedule/startTimeControl";
import { EndTimeControl } from "../admin/ManageSchedule/endTimeControl";
import useDialogModal from "../../hooks/useDialogModal";
import ConfirmationDialog from "../ui/confirmationDialog";
import validLookup from "../../utils/validLookup";
import { useAppContext } from "../../state/appState";
import { dateSetTime, getTimeString, niceDate, niceTime, timeFromString, toTitleCase } from "../../utils";
import { drawerWidth } from "./styles";
import { ZIndex } from "../../styles/constants/enums";
import { DrawerHeader } from "../../styles/drawer";
import { ChevronRightOutlined } from "@mui/icons-material";

export default function EditSchedule({ event, open, onClose, onDelete, onUpdate, location, practitioner }) {
    const { appState } = useAppContext();
    const [state, updateState] = useReducer((state, params) => {
        return { ...state, ...params }
    }, {
        deleteAll: false,
        updateAll: false,
        startTime: '',
        endTime: ''

    });

    const [ConfirmationDialogComp, showConfirmationDialog] = useDialogModal(ConfirmationDialog);

    const handleStartTimeChange = (event) => {

        updateState({
            startTime: event.target.value
        });
    };

    const handleEndTimeChange = (event) => {

        updateState({
            endTime: event.target.value
        });
    };

    useEffect(() => {

        if (event) {
            updateState({
                startTime: getTimeString(event.startDate),
                endTime: getTimeString(event.endDate)
            });
        }

    }, [event.startDate]);

    const handleClose = () => {
        updateState({
            deleteAll: false,
            updateAll: false
        });

        onClose();
    }

    const performAction = (action, payload) => {
        switch (action) {
            case 'update':
                const data = {
                    updateall: state.updateAll, startTime: state.startTime, endTime: state.endTime,
                    prac: practitioner.id
                };
                onUpdate(data);
                //handleClose();
                break;
            case 'delete':
                showConfirmationDialog((action) => {
                    if (action === validLookup.confirmationDialogAction.ok) {
                        onDelete(state.deleteAll);
                        handleClose();
                    }
                });

                break;
            default:
                break;
        }
    }
    return (

        <>
            {event &&
                <Drawer
                    open={open}
                    PaperProps={{
                        elevation: 8
                    }}
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                        zIndex: open && ZIndex.DRAWER + 10
                    }}
                    variant="persistent"
                    anchor="right"
                >
                    <DrawerHeader sx={{
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}>
                        <IconButton
                            sx={{ color: Colors.white }}
                            onClick={onClose}>
                            <ChevronRightOutlined />
                        </IconButton>

                        <Typography fontSize={"2rem"} sx={{ color: Colors.white }}>Edit Schedule</Typography>
                    </DrawerHeader>

                    <Box display={"flex"} flexDirection="column" sx={{ p: 1 }}>
                        <Typography>Staff: {(toTitleCase(practitioner.fullName)) || ''}</Typography>
                        <Typography >Date: {niceDate(event.start_Date, 'yyyy-MM-dd')}</Typography>
                        <Typography>Location: {toTitleCase(location)}</Typography>
                        <StartTimeControl handleStartTimeChange={handleStartTimeChange}
                            wd={1}
                            starttime={state.startTime}
                            disabled={true} />

                        <EndTimeControl handleEndTimeChange={handleEndTimeChange}
                            wd={-1}
                            endtime={state.endTime}
                            disabled={true} />

                        <Box sx={{ mt: 2 }} display={"flex"} alignItems="center">
                            {
                                event.groupId &&
                                <>
                                    <Switch checked={state.updateAll} onClick={() => updateState({ updateAll: !state.updateAll })} />
                                    <Typography
                                        color={state.updateAll ? Colors.black : Colors.muted}
                                        variant="subtitle1"
                                    >
                                        Update all occurrences
                                    </Typography>
                                </>
                            }
                        </Box>
                        <Button variant="contained" color="primary" sx={{ flexGrow: 1 }} onClick={() => performAction('update', {})}>
                            Update
                        </Button>
                        <Divider sx={{ mt: 2, mb: 2 }} />

                        {/*************************  Actions */}
                        <Box display={"flex"} alignItems="center">
                            {
                                event.groupId &&
                                <>
                                    <Switch onClick={() => updateState({ deleteAll: !state.deleteAll })} />
                                    <Typography
                                        color={state.deleteAll ? Colors.black : Colors.muted}
                                        variant="subtitle1"
                                    >
                                        Delete all occurrences
                                    </Typography>
                                </>
                            }
                        </Box>
                        <Button onClick={() => performAction('delete', null)} variant="contained" color="danger" sx={{ flexGrow: 1 }}>
                            Delete
                        </Button>
                    </Box>
                    <ConfirmationDialogComp message={`Are you sure you want to delete ${state.deleteAll ? 'all occurrences of this' : 'selected'
                        } schedule?`} label={{
                            positive: "Yes",
                            negative: "No"
                        }} />
                </Drawer>

            }
        </>


    );
}
