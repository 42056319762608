import { alpha, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Button, Typography, Grid, MenuItem, Select, TextField, InputAdornment, IconButton } from "@mui/material";
import { useEffect, useReducer } from "react";
import useDialogModal from "../../../../hooks/useDialogModal";
import { Colors } from "../../../../styles/theme";
import { niceDate } from "../../../../utils";
import OrderServcesDialog from "./orderServicesDialog";
import ClearIcon from '@mui/icons-material/Clear';

const data = [
    {
        id: -1,
        description: 'Lens Brand/Model/Material/Index',
        amount: 200
    },
    {
        id: -1,
        description: 'Frame Model/Material/Color/Rim Type',
        amount: 100
    }
];

export default function OrderBilling() {

    const [OrderServces, showAddServicesDialog] = useDialogModal(OrderServcesDialog);
    const [state, updateState] = useReducer((state, params) => {
        return { ...state, ...params }
    }, {
        payment: [],
        billingData: data,
        total: undefined,
        balanceDue: undefined
    });

    const handleAddServices = () => {
        showAddServicesDialog();
    }

    const handleAddPayment = () => {
        const payment = {
            amount: '',
            method: '',
            date: niceDate(new Date())
        }

        updateState({
            payment: [...state.payment, payment]
        });
    }

    // we need to update balanceDue
    const handlePaymentMethodRemove = (index) => {
        const existingPaymentMethods = [...state.payment];
        existingPaymentMethods.splice(index, 1)
        updateState({ payment: existingPaymentMethods });
    }

    const handleBillingDataRemove = (index, bill) => {
        if (bill.id > -1) {
            const existingBillingData = [...state.billingData];
            existingBillingData.splice(index, 1)
            updateState({ billingData: existingBillingData });
        }
    }

    // we need to update balanceDue
    const handlePaymentAmount = (amount, index) => {
        const existingPaymentMethods = [...state.payment];
        existingPaymentMethods[index].amount = amount;
        updateState({ payment: existingPaymentMethods });
    }

    const handlePaymentMethodSelect = (method, index) => {
        const existingPaymentMethods = [...state.payment];
        existingPaymentMethods[index].method = method;
        updateState({ payment: existingPaymentMethods });
    }

    useEffect(() => {
        const totalPayment = state.payment.length > 0 ?
            state.payment.reduce((accumulator, currentValue) => accumulator + +currentValue.amount, 0) : 0;

        // subtract from balanceDue
        updateState({ balanceDue: state.total - totalPayment });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.payment, state.total]);

    const onServiceAdd = (services) => {
        const existingBillingData = [...state.billingData];
        services.forEach(s => {
            existingBillingData.push({
                id: s.id,
                description: s.description,
                amount: s.amount
            })
        });
        updateState({ billingData: existingBillingData });

    }

    useEffect(() => {
        updateState({
            total: state.billingData.reduce((acc, cv) => acc + cv.amount, 0)
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.billingData]);

    return (
        <Box>
            <Typography variant="h4" sx={{ mb: 1 }}>Balance due: ${state.balanceDue}</Typography>
            <Grid container columnSpacing={1}>
                <Grid item xs={12} sm={6}>
                    <Button sx={{ mb: 1 }} variant="outlined" onClick={handleAddServices}>Add services</Button>
                    <TableContainer>
                        <Table>
                            <TableHead sx={{ background: alpha(Colors.primary, 0.5) }}>
                                <TableRow>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Price</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    state.billingData.map((bill, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{bill.description}</TableCell>
                                            <TableCell>${bill.amount}</TableCell>
                                            {bill.id > 0 && <TableCell><IconButton onClick={(event) => handleBillingDataRemove(index, bill)}><ClearIcon /></IconButton></TableCell>}
                                        </TableRow>
                                    ))
                                }
                                <TableRow>
                                    <TableCell><Typography variant="h6">Total</Typography></TableCell>
                                    <TableCell><Typography variant="h6">${state.total}</Typography></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button sx={{ mb: 1 }} variant="outlined" onClick={handleAddPayment}>Add payment</Button>
                    <Box>
                        <TableContainer>
                            <Table>
                                <TableHead sx={{ background: alpha(Colors.primary, 0.5) }}>
                                    <TableRow>
                                        <TableCell>Amount</TableCell>
                                        <TableCell>Method</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        state.payment.map((payment, index) => (
                                            <TableRow key={index}>
                                                <TableCell><TextField
                                                    value={state.payment[index].amount}
                                                    onChange={(event) => handlePaymentAmount(event.target.value, index)}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">$</InputAdornment>
                                                    }} size="small" /></TableCell>
                                                <TableCell>
                                                    <Select
                                                        value={payment.method}
                                                        onChange={(event) => handlePaymentMethodSelect(event.target.value, index)}
                                                        fullWidth size="small">
                                                        <MenuItem value={1}>Visa</MenuItem>
                                                        <MenuItem value={2}>Mastercard</MenuItem>
                                                        <MenuItem value={3}>Insurance</MenuItem>
                                                    </Select>
                                                </TableCell>
                                                <TableCell>{payment.date}</TableCell>
                                                <TableCell><IconButton onClick={(event) => handlePaymentMethodRemove(index)}><ClearIcon /></IconButton></TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Grid>
            </Grid>


            <OrderServces onAdd={onServiceAdd} />
        </Box>

    )
}