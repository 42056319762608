import { Box, Switch } from "@mui/material";

export const DayToggleSwitch = ({ handleDaySwitch, checked }) => {
    return (
        <Box display={"flex"} justifyContent="center" alignItems={"center"} sx={{mr: 1}}>
            <Switch
                onChange={handleDaySwitch}
                checked={checked}
            />
        </Box>

    );
}