import { Add, Search, Save, Cancel } from "@mui/icons-material";
import { TextField, Box, Typography, Button, Grid, IconButton, Tooltip } from "@mui/material";
import useDialogModal from "../../hooks/useDialogModal";
import { ButtonWithIcon, NumberInput, TabPanel, a11yProps } from "../ui";
import AddNewPatientDialog from "./dialog/addNewPatientDialog";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useEffect, useMemo, useRef, useState } from "react";
import SearchPatientDialog from "./dialog/searchPatient/searchPatientDialog";
import ProfileTab from "./tabs/profileTab";
import { Colors } from "../../styles/theme";
import { PatientService } from "../../service/patientService";
import validLookup from "../../utils/validLookup";
import ConfirmationDialog from "../ui/confirmationDialog";
import { toast } from "react-toastify";
import { isLocalDev } from "../../utils/environment";
import FamilyMembers from "./familyMembers/familyMembers";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import SyncIcon from '@mui/icons-material/Sync';
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { formatPhoneNumber } from "../../utils";
import CommentTab from "./tabs/commentTab";
import AppointmentTab from "./tabs/appointmentTab";
import PatientSearch from "../patientsearch/patientSearch";
import RxTab from "./tabs/rx/rxTab";
import PatientTimeline from "./timeline";
import OrderTab from "./tabs/order/order";


export default function PatientRecord() {
    const [dialogMessage, setDialogMessage] = useState();
    const [tabIndex, settTabIndex] = useState(0);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [profileDirty, setProfileDirty] = useState(false);
    const [
        ConfirmationDialogComp,
        showConfirmationDialog,
        closeConfirmationDialog,
    ] = useDialogModal(ConfirmationDialog);
    const [AddPatientDialog, showAddPatientDialog] =
        useDialogModal(AddNewPatientDialog);
    const [SearchForPatientDialog, showSearchPatientDialog] =
        useDialogModal(SearchPatientDialog);

    const formEventListeners = useRef(new Map());

    const [patientData, setPatientData] = useState({ patient: null, familyMembers: [] });

    /**
     * we need a way to know when a form is touched so that
     * actions can be taken  e.g. show the save/cancel button
     * and prevent tab change
     */
    const formTouched = useMemo(() => ({
        onTouched: (dirty) => {
            setProfileDirty(dirty);
        },
    }));

    /**
     * The save button is on the parent. The children registers
     * a listener so that they can be notified when save is clicked
     */
    const listeners = useMemo(
        () => ({
            add: (saveListener, cancelListener) => {
                formEventListeners.current.set("save", saveListener);
                formEventListeners.current.set("cancel", cancelListener);
                return () => {
                    formEventListeners.current.clear();
                };
            },
        }),
        []
    );

    /**
     * Load patient data
     */
    useEffect(() => {
        if (selectedPatient && selectedPatient.id > 0) {
            loadPatient(selectedPatient.id);
        }
    }, [selectedPatient]);

    const loadPatient = (id) => {
        try {
            PatientService.getById(id).then((response) => {
                const { code, data, message } = { ...response };
                if (code === 0) {
                    resetPatientData();
                    setPatientData({ patient: data.patient, familyMembers: data.familyMembers });
                }
                else toast.error(message);
            });
        } catch (error) {
            if (isLocalDev) console.log(error);
        }
    }

    /**
     * when a tab is changed
     */
    const handleTabChange = (event, newValue) => {
        // const activeTab = getActiveTab(newValue);

        // switch(activeTab) {
        //     case TAB_ENUM.PROFILE:
        //         console.log('load profile');
        //     break;
        //     case TAB_ENUM.COMMENTS:
        //         CommentService.getCommentsWithLimits(1, {offset: 0, limit: pageSize}).then(response => {
        //             console.log(response);
        //         });
        //     break;
        //     default:
        //         throw new Error();
        // }


        if (profileDirty && tabIndex !== newValue) {
            setDialogMessage("You have unsaved changes, continue?");
            showConfirmationDialog((action) => {
                if (action === validLookup.confirmationDialogAction.ok) {
                    setProfileDirty(false);
                    settTabIndex(newValue);
                    closeConfirmationDialog();
                }
            });
        } else {
            settTabIndex(newValue);
        }
    };

    /**
     *
     * is called after patient search is complete and user has selected
     * a patient row
     */
    const handleSearchDone = (patient) => {

        if (patient) {
            //resetPatientData();
            loadPatient(patient.id);
            //setSelectedPatient(patient);
        }
    };

    /**
     * when a family member is selected   
     */
    const handleFamilyMemberClicked = (familyMember) => {
        loadPatient(familyMember.id);
    }

    const resetPatientData = () => {
        setPatientData({ patient: null, familyMembers: [] });

        // setPatientData({
        //     profile: null,
        //     invoice: null,
        //     appointment: null,
        //     chart: null,
        //     rx: null,
        //     familyMembers: []
        // });
    }

    const onSaveHanlder = () => {
        formEventListeners.current.get("save")();
    };

    const onCancelHanlder = () => {
        formEventListeners.current.get("cancel")();
        setProfileDirty(false);
    };

    const handleAccountDelete = () => {
        setDialogMessage("Are you sure you want to delete the account?");
        showConfirmationDialog((action) => {
            if (action === validLookup.confirmationDialogAction.ok) {
                closeConfirmationDialog();
            }
        });

    }

    const handlePatientSelect = (patient) => {
        if( patient )
            loadPatient(patient.id);
    };

    return (
        <>
            <Box display={"flex"} justifyContent="flex-end">
                <PatientSearch onPatientSelected={handlePatientSelect} reset={true} sx={{ width: '20%' }} />
                {profileDirty && (
                    <>
                        <ButtonWithIcon
                            sx={{ mr: 1, background: Colors.success }}
                            icon={<Save />}
                            title="Save"
                            onClickHandler={() => onSaveHanlder()}
                        />
                        <ButtonWithIcon
                            sx={{ mr: 1, background: Colors.muted }}
                            icon={<Cancel />}
                            title="Cancel"
                            onClickHandler={() => onCancelHanlder()}
                        />
                    </>
                )}

                {/* <ButtonWithIcon
                    sx={{ mr: 1 }}
                    icon={<Add />}
                    title="Add new"
                    onClickHandler={() => showAddPatientDialog()}
                />
                <ButtonWithIcon
                    icon={<Search />}
                    title="Search"
                    onClickHandler={() => showSearchPatientDialog()}
                /> */}
            </Box>
            {patientData.patient && (
                <>
                    <Box display="flex" alignItems="center">
                        <Typography variant="h4" sx={{ color: Colors.dim_grey, textTransform: "capitalize" }}>
                            {patientData.patient.fullName}
                        </Typography>
                        <IconButton onClick={() => loadPatient(patientData.patient.id)}>
                            <Tooltip title="Refresh">
                                <SyncIcon fontSize="small" sx={{ ml: 1, color: Colors.dim_grey }} />
                            </Tooltip>
                        </IconButton>

                    </Box>

                    <Box display="flex" alignItems="center">
                        <CalendarTodayIcon sx={{ fontSize: '0.75rem', mr: 1, color: Colors.dim_grey }} />
                        <Typography variant="caption" sx={{ color: Colors.dim_grey, textTransform: "capitalize" }}>
                            {patientData.patient.birthdate}
                        </Typography>
                        <Typography variant="caption" sx={{ ml: 1, color: Colors.dim_grey, textTransform: "capitalize" }}>
                            ({patientData.patient.age} years)
                        </Typography>
                        <Typography component={"span"} variant="caption" sx={{ color: Colors.dim_grey, textTransform: "capitalize" }}>
                            {patientData.patient.fullAddress}
                        </Typography>
                        <LocalPhoneIcon fontSize="small" sx={{ fontSize: '0.75rem', ml: 1, color: Colors.dim_grey }} />
                        <Typography component={"span"} variant="caption" sx={{ ml: 1, color: Colors.dim_grey }}>
                            {formatPhoneNumber(patientData.patient.phone1)}
                        </Typography>
                    </Box>
                </>
            )}

            {patientData.patient ? (
                <>
                    {patientData.familyMembers ?
                        <Grid container columnSpacing={0.25} sx={{mt: 1}}>
                            <Grid item xs={12} sm={2}>
                                <FamilyMembers
                                    linkTo={patientData.patient}
                                    familyMemberClicked={handleFamilyMemberClicked}
                                    familyMembers={patientData.familyMembers}
                                />
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <Box sx={{ width: "100%" }} flexGrow={1}>
                                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                        <Tabs value={tabIndex} onChange={handleTabChange}>
                                            <Tab label="Profile" {...a11yProps("patientrecord", 0)} />
                                            <Tab label="Orders" {...a11yProps("patientrecord", 1)} />
                                            <Tab label="Appiontments" {...a11yProps("patientrecord", 2)} />
                                            <Tab label="Chart" {...a11yProps("patientrecord", 3)} />
                                            <Tab label="Rx" {...a11yProps("patientrecord", 4)} />
                                            <Tab label="Documents" {...a11yProps("patientrecord", 5)} />
                                            <Tab label="Comments" {...a11yProps("patientrecord", 6)} />
                                            <Tab label="Timeline" {...a11yProps("patientrecord", 7)} />
                                        </Tabs>
                                    </Box>
                                    <TabPanel
                                        idfor="patientrecord"
                                        value={tabIndex}
                                        index={0}
                                        background={Colors.dove_gray}
                                    >
                                        {patientData.patient && (
                                            <ProfileTab
                                                addListener={listeners.add}
                                                deleteListener={handleAccountDelete}
                                                formChanged={formTouched.onTouched}
                                                profileData={patientData.patient}
                                                onUpdate={(updatedData) =>
                                                    setPatientData({ ...patientData, patient: updatedData })
                                                }
                                            />
                                        )}
                                    </TabPanel>
                                    <TabPanel idfor="patientrecord" value={tabIndex} index={1}>
                                        <OrderTab />
                                    </TabPanel>
                                    <TabPanel idfor="patientrecord" value={tabIndex} index={2}>
                                        <AppointmentTab patientId={patientData.patient.id} />
                                    </TabPanel>
                                    <TabPanel idfor="patientrecord" value={tabIndex} index={3}>
                                        <h1>Chart</h1>
                                    </TabPanel>
                                    <TabPanel idfor="patientrecord" value={tabIndex} index={4}>
                                        <RxTab patient={patientData} />
                                    </TabPanel>
                                    <TabPanel idfor="patientrecord" value={tabIndex} index={5}>
                                        <h1>Documents</h1>
                                    </TabPanel>
                                    <TabPanel idfor="patientrecord" value={tabIndex} index={6}>
                                        <CommentTab patientId={patientData.patient.id} comments={patientData.patient.Comments} />
                                    </TabPanel>
                                    <TabPanel idfor="patientrecord" value={tabIndex} index={7}>
                                        <PatientTimeline />
                                    </TabPanel>
                                </Box>
                            </Grid>
                        </Grid> : <Typography variant="h5" color={Colors.muted}>Loading patient data...</Typography>
                    }
                </>
            ) : (
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{ mt: "10%", color: Colors.muted }}
                >
                    Add or search for a patient
                </Typography>
            )}
            <AddPatientDialog onDone={handleSearchDone} />
            <SearchForPatientDialog onDone={handleSearchDone} />
            <ConfirmationDialogComp
                message={dialogMessage}
                label={{
                    positive: "Yes",
                    negative: "No",
                }}
            />
        </>
    );
}
