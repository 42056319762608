import { createContext, useContext, useEffect, useReducer, useState } from "react";
import { EmployeeService } from "../service/employeeService";
import { LocationService } from "../service/locationService";

export const APP_ACTION_TYPE = {
    REFRESH: 'refresh'
}

const initialState = {
    locations: [],
    practitioners: [],
    calendarTimeslotHeight: 120,
    businessStartHour: '9:30 am',
    businessClosingHour: '9:00 pm',
    timeSlotInterval: 15,  
    workWeek: 7,
    timeZone: 'America/Toronto',
    maxFileUploadSize: 5,
    acceptedFileTypes: ['image/jpeg','image/jpg','image/png','application/pdf']
};

function reducer(prevstate, nextstate) {
    return { ...prevstate, ...nextstate };
}

export const AppContext = createContext({});
export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
    const [appState, updateAppState] = useReducer(reducer, initialState);

    //const [locations, setLocations] = useState([]);

    // load locations
    useEffect(() => {

        (async () => {
            const locationResult = await LocationService.getLocations();
            const practitionerResult = await EmployeeService.getPractitioners();

            if (locationResult.code === 0) {
                //setLocations(locationResult.data);
                updateAppState({
                    locations: locationResult.data
                });
            }

            if (practitionerResult.code === 0) {
                //setLocations(locationResult.data);
                updateAppState({
                    practitioners: practitionerResult.data
                });
            }

        })();
    }, []);

    const value = {
        appState,
        updateAppState
    };

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    );
};
