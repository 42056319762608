import { Dialog, DialogContent, Box } from "@mui/material";
import { addMinutes, differenceInMinutes, format } from "date-fns";
import { useReducer, useRef, useState } from "react";
import { toast } from "react-toastify";
import useDialogModal from "../../hooks/useDialogModal";
import { AppointmentService } from "../../service/appointmentService";
import { ScheduleService } from "../../service/scheduleService";
import { CalendarProvider } from "../../state/calendar";
import { ZIndex } from "../../styles/constants/enums";
import { dateFromString, dateSetTime, niceTime, timeFromString } from "../../utils";
import { isLocalDev } from "../../utils/environment";
import validLookup from "../../utils/validLookup";
import AppDialogTitle from "../ui/appDialogTitle";
import ConfirmationDialog from "../ui/confirmationDialog";
import Calendar from "./calendar";
import AppointmentDrawer from "./appointmentDrawer";
import BookAppointmentDrawer from "./bookAppointmentDrawer";
import useEffectOnUpdates from "../../hooks/useEffectOnUpdates";

export default function BookAppointmentDialog({ open, onClose, patient }) {
    const toastRef = useRef();
    const [location, setLocation] = useState('');
    const [practitionerState, setPractitionerState] = useState({
        selectedPractitioners: [],
        list: [],
        active: [],
        tick: new Date().getTime()
    });
    const [move, setMove] = useState(false);
    const [collides, setCollides] = useState(null);
    const [state, updateState] = useReducer((state, params) => {
        return { ...state, ...params }
    }, {
        lock: [],
        activeDay: null,
        activeWeek: null,
        activeSlot: null,
        draggedSlot: null,
        isEditSchedule: false,
        activeAction: null,
        activeView: 'day',
        timebarTime: null,
        bookAppointmentDrawerOpen: false,
        appointmentDrawer: false,
        patient: null
    });
    const [
        ConfirmationDialogComp,
        showConfirmationDialog,
        closeConfirmationDialog,
    ] = useDialogModal(ConfirmationDialog);

    useEffectOnUpdates(() => {
        updateState({patient: patient});
    },[patient]);

    useEffectOnUpdates(() => {
        if (location) {
            getAppointments();
        }

    }, [state.activeView, location, state.activeDay, state.activeWeek]);

    const handleWeekChanged = (activeWeek) => {
        updateState({ activeWeek: activeWeek });
    }

    const handleDayChanged = (activeDay) => {
        updateState({ activeDay: activeDay });
    }

    function getAppointments() {
        //setCollides(null);
        const params = {};

        // filter by location
        if (location) {
            params['location'] = location.id;
        }
        // first filter by practioer
        // if (practitioner) {
        //     params['practitioner'] = practitioner;
        // }
        if (state.activeView === 'week') {
            if (!state.activeWeek) return;

            params['start'] = format(state.activeWeek.start, 'yyyy-MM-dd');
            params['end'] = format(state.activeWeek.end, 'yyyy-MM-dd');
        }

        if (state.activeView === 'day') {
            params['start'] = format(state.activeDay, 'yyyy-MM-dd');
            params['end'] = format(state.activeDay, 'yyyy-MM-dd');;
        }

        ScheduleService.getScheduleByLocation({ ...params })
            .then((result) => {

                const { code, data, message } = { ...result };
                let names = data.map(l => l.fullName);

                if (code === 0) {

                    if (state.lock.length > 0) {

                        const list = [];
                        // choose only the acitve 
                        data.forEach(d => {
                            const idx = state.lock.findIndex(ap => ap.id === d.id);
                            if (idx !== -1) {
                                list.push(d);
                            }
                        });
                        names = list.map(l => l.fullName);

                        setPractitionerState({ selectedPractitioners: names, list: data, active: list, tick: new Date().getTime() });
                    } else setPractitionerState({ selectedPractitioners: names, list: data, active: data, tick: new Date().getTime() });
                }
            })
            .catch((error) => console.log(error));
    }

    const isCollidingWithAnotherBookedslot = (slot, s, e) => {
        const start = s;//dateFromString(s, 'yyyy-MM-dd HH:mm:ss');
        const end = e;//dateFromString(e, 'yyyy-MM-dd HH:mm:ss');

        let collidesWith = null;
        practitionerState.active.every(p => {
            const slots = p.slots;
            const booked = slots.filter(bs => bs.ScheduleId
                && bs.id !== state.activeSlot.id
                && bs.dayOfWeek === slot.dayOfWeek
                && bs.practitionerId === slot.EmployeeId);

            booked.every(s => {

                const bStart = dateFromString(s.startDate, 'yyyy-MM-dd HH:mm:ss');
                const bEnd = dateFromString(s.endDate, 'yyyy-MM-dd HH:mm:ss');

                //if ((bStart >= start && bStart < end) || (bEnd > start && bEnd <= end)) {
                if (bStart > start && differenceInMinutes(bStart, start) < state.activeSlot.duration) {
                    collidesWith = s;

                    return false;
                }
                //}
                return true;
            });

            if (collidesWith) return false;
            return true;
        });

        return collidesWith;
    }

    const handleSlotClicked = async (slot, atTime) => {

        // check if move is active?
        if (move) {
            // we need to take start_Date since startDate is when the schedule starts
            const newStart = dateSetTime(slot.start_Date, atTime);

            const newEnd = addMinutes(newStart, state.activeSlot.duration);

            const collidesWith = isCollidingWithAnotherBookedslot(slot, newStart, newEnd);

            if (collidesWith) {
                setCollides(collidesWith);
            } else {

                showConfirmationDialog(async (action) => {
                    if (action !== validLookup.confirmationDialogAction.ok) {
                        getAppointments();
                    } else {
                        const data = {
                            id: state.activeSlot.id,
                            startDate: newStart,
                            endDate: newEnd,
                            practitionerId: slot.EmployeeId,
                            locationId: location.id,
                            dayOfWeek: slot.dayOfWeek,
                            scheduleId: slot.id
                        }

                        // commit changes
                        const updateAppointmentResult = await AppointmentService.update(data);
                        const { code, message } = updateAppointmentResult;

                        if (code === 0) {
                            toast.success(message);
                            getAppointments();
                            setMove(false);
                            toast.dismiss(toastRef.current);
                            updateState({ activeSlot: null, appointmentDrawer: false });
                        } else {
                            toast.warn(message);
                        }
                        //console.log(updateAppointmentResult);
                    }
                    closeConfirmationDialog();
                }, {
                    title: 'Move this appointmet?',
                    message: "Please confirm if you would like to move this appointment?",
                });
            }
            //console.log(activeSlot, timebarTime, slot);
        } else {
            if (slot.status === validLookup.validSlotStatus.available) {
                if (state.isEditSchedule && slot.status === validLookup.validSlotStatus.available) {
                    updateState({ activeAction: 'edit', activeSlot: slot });
                } else if (!state.appointmentDrawer) {
                    updateState({ bookAppointmentDrawerOpen: true, activeSlot: slot, timebarTime: atTime });
                }
            } else if (Object.keys(validLookup.validAppointmentStatus).indexOf(slot.status) !== -1) {
                updateState({ appointmentDrawer: true, activeSlot: slot });
            }
        }
    }

    const handleAppointmentBook = (param) => {

        const payload = {
            startdate: dateSetTime(state.activeSlot.start_Date, param.startTime),
            enddate: dateSetTime(state.activeSlot.start_Date, param.endTime),
            scheduleId: state.activeSlot.id,
            location: 1,//location.id,
            employee: 5,
            dayOfWeek: state.activeSlot.dayOfWeek,
            ...param
        };

        AppointmentService.bookAppointment(payload).then(response => {

            const { code, data, message } = response;
            if (code === 0) {
                getAppointments();
                toast.success("Appointment booked");
                updateState({ activeSlot: null, bookAppointmentDrawerOpen: false });

            } else {
                toast.warning(message);
            }
        }).catch(error => {
            toast.error(error.message);
            if (isLocalDev)
                console.log(error);
        });
    }

    const handleDrawerClose = () => {
        setCollides(null);
        setMove(false);
        toast.dismiss(toastRef.current);
        updateState({ activeSlot: null, activeAction: null, isEditSchedule: false, bookAppointmentDrawerOpen: false, appointmentDrawer: false });
    }

    const handleSlotUpdate = async (param) => {
        param['id'] = state.activeSlot.id;

        const updateAppointmentResult = await AppointmentService.update(param);
        const { code, message } = updateAppointmentResult;

        if (code === 0) {
            toast.success(message);
            getAppointments();
            handleDrawerClose();
        } else {
            toast.warning('Failed to update appointment');
        }
    }

    return (
        <CalendarProvider>
            <Dialog onClose={onClose} open={open} fullScreen >
                <AppDialogTitle onClose={onClose} title="Book appointment" />
                <DialogContent>
                    <Box display={"flex"}>
                        <Calendar
                            open={state.appointmentDrawer || state.bookAppointmentDrawerOpen}
                            handleWeekChanged={handleWeekChanged}
                            handleDayChanged={handleDayChanged}
                            handleSlotClicked={handleSlotClicked}
                            slotDragged={() => { }}
                            location={location}
                            setLocation={setLocation}
                            state={state}
                            updateState={updateState}
                            practitionerState={practitionerState}
                            setPractitionerState={setPractitionerState}
                        />
                        <Box sx={{ zIndex: state.activeSlot ? ZIndex.DRAWER + 10 : -1 }}>
                            <AppointmentDrawer
                                drawerOpen={state.appointmentDrawer}
                                handleSlotMove={() => { }}
                                handleSlotUpdate={handleSlotUpdate}
                                handleSlotCancel={() => { }}
                                handleDrawerClose={handleDrawerClose}
                                activeSlot={state.activeSlot} />

                            <BookAppointmentDrawer
                            patient={patient && patient.patient}
                                onBook={handleAppointmentBook}
                                drawerOpen={state.bookAppointmentDrawerOpen}
                                handleDrawerClose={handleDrawerClose}
                                activeSlot={state.activeSlot}
                                startTime={state.timebarTime}
                                practitioner={(state.activeSlot && practitionerState.active.find(p => p.id === state.activeSlot.EmployeeId)) || ''}
                                location={location.alias || ''}
                                endTime={state.timebarTime && niceTime(addMinutes(timeFromString(state.timebarTime), 15).toISOString())} />

                        </Box>
                    </Box>
                </DialogContent>
                <ConfirmationDialogComp
                    label={{
                        positive: "Confirm",
                        negative: "Cancel",
                    }} />
            </Dialog>
        </CalendarProvider>

    )
}