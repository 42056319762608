import { useEffect, useState } from "react";
import {
  addWeeks,
  differenceInHours,
  differenceInMinutes,
  addDays,
  startOfWeek,
  format,
  addMinutes,
} from "date-fns";
import { useCalendarContext } from "../../../state/calendar";
import { SlotWrapper, SlotGroupWrapper } from "../../../styles/calendar";
import { appSettings } from "../../../data";
import { DayColumnWrapper } from "../../../styles/calendar";
import { getRowPosition } from "../helpers";

export const Slot = (props) => {
  const [draggedOver, setDraggedOver] = useState(false);
  const [forDate, setForDate] = useState(null);
  const { onAdded, onRemoved, activeWeek } = useCalendarContext();

  const handleSlotClick = (event) => {

    if (props.disableSlotClick) return;

    var now = new Date();

    var start = startOfWeek(now);
    start = addWeeks(start, activeWeek);

    const date = props.forSetDate ? props.forSetDate : addDays(start, props.index);

    const startDate = new Date(date);
    const endDate = new Date(date);

    startDate.setHours(props.slot.starttime);
    endDate.setHours(props.slot.endtime);

    try {
      const shadowEvent = {
        id: -1,
        date: date.toISOString(),
        start: startDate.toISOString(),
        end: endDate.toISOString(),
        index: props.index,
      };
      setForDate(shadowEvent);
      props.onSlotClicked(shadowEvent);
    } catch(error) {
      let errorlog = error.message;
      
      if( process.env.NODE_ENV === 'development')
        errorlog = error;

      console.log(errorlog);
      props.onSlotClicked({error: error.message});
    }

  };

  const handleDrop = (ev) => {

    ev.preventDefault();

    // retrieve the event
    var event = JSON.parse(ev.dataTransfer.getData('text/plain'));

    // it would be nice to highlight the hour when an event
    // is dropped on a slot
    //console.log(props.slot.start);

    // find out which day the event was dropped on
    let eventStart = new Date();
    let eventEnd = new Date();
    eventStart.setHours(event.starttime.split(':')[0]);
    eventStart.setMinutes(event.starttime.split(':')[1]);
    eventEnd.setHours(event.endtime.split(':')[0]);
    eventEnd.setMinutes(event.endtime.split(':')[1]);
    const eventDuaration = Math.abs(differenceInMinutes(eventStart, eventEnd));

    var now = new Date();
    var startow = startOfWeek(now);
    startow = addWeeks(startow, activeWeek);

    const date = addDays(startow, props.index);
    console.log(props.index);
    const startDate = new Date(date);
    const endDate = new Date(date);
    //console.log(props, event);
    startDate.setHours(props.slot.starttime);
    endDate.setHours(props.slot.endtime);

    const {starttime, endtime, id, status, row} = {...event};
    const from = {
      start: starttime,
      end: endtime,
      id: id,
      status: status,
      row: row,
      col: event.col
    };

    const to = {
      id: event.id,
      date: date,
      start: startDate,
      end: endDate,
      col: props.index,
      row: getRowPosition(format(props.slot.starttime, 'HH:mm')) 
    };

    const start = format(to.start, 'HH:mm');
    const end = format(to.end, 'HH:mm');
    const st = to.start;
    st.setHours(start.split(':')[0]);
    st.setMinutes(0);

    const en = addMinutes(st, eventDuaration);
    //en.setHours(end.split(":")[0]);
    //en.setMinutes(event.end.split(":")[1]);

    to.end = en;
    const shadowEvent = {
      to: {...to},
      from: {...from},
      index: props.index,

    };
    setForDate(shadowEvent);
    setDraggedOver(false);

    props.onDrop && props.onDrop(shadowEvent);
  }

  const handleDragOver = (props, ev) => {
    setDraggedOver(true);
    //console.log("dragOver: dropEffect = " + ev.dataTransfer.dropEffect + " ; effectAllowed = " + ev.dataTransfer.effectAllowed);
    ev.preventDefault();
    // Set the dropEffect to move
    ev.dataTransfer.dropEffect = "move"
  }

  const handleDragLeave = (ev) => {
    ev.preventDefault();
    setDraggedOver(false);
  }

  return (
    <>
      <SlotWrapper draggedover={`${draggedOver}`}
        onDrop={handleDrop}
        onDragOver={(ev) => handleDragOver(props, ev)}
        onDragLeave={handleDragLeave}
        onClick={handleSlotClick}>{props.value}</SlotWrapper>
    </>
  );
};

export const Column = (props) => {
  const render = props.slots.map((d, i) => {
    return (
      <SlotGroupWrapper key={i}>
        <Slot slot={d} 
        forSetDate={props.forSetDate} index={props.index} 
        disableSlotClick={props.disableSlotClick} {...props} />
      </SlotGroupWrapper>
    );
  });
  return <>{render}</>;
};

export const Columns = ({ disableSlotClick, numberOfColumns, forSetDate, onSlotClicked, onDrop }) => {
  const [slots, setSlots] = useState([]);
  useEffect(() => {
    setSlots([]);
    const start = new Date();
    const end = new Date();
    start.setHours(appSettings.businessStartHour);
    end.setHours(appSettings.businessClosingHour);
    const totalMins = differenceInMinutes(end, start);
    const totalHours = differenceInHours(end, start);
    const numberOfSlots = totalHours + 1;
    for (let i = 0; i < numberOfSlots; i++) {
      const start = appSettings.businessStartHour + i;
      const end = start + 1;
      setSlots((s) => [...s, { starttime: start, endtime: end }]);
    }
  }, []);

  const renderDays = numberOfColumns.map((day, index) => {
    return (
      <DayColumnWrapper
        key={index} day={day}>
        <Column
          onDrop={onDrop}
          forSetDate={forSetDate}
          onSlotClicked={onSlotClicked}
          index={day} slots={slots} disableSlotClick={disableSlotClick} />
      </DayColumnWrapper>
    );
  });

  return <>{renderDays}</>;
};
