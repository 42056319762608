import { Grid,  Box, lighten } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "../theme";

export const DayTile = styled(Grid, {
    shouldForwardProp: (props) => 
    props !== 'isBeforeToday' && props !== 'isSelected'
})(({ isBeforeToday, isSelected }) => ({
    backgroundColor: isSelected === 'true' ? Colors.primary :
     lighten(Colors.light, 0.6),
    color: isBeforeToday ? lighten(Colors.black, 0.6) : Colors.black,
    position: 'relative',
    padding: '20px 0px',
    cursor: 'pointer'
}));


export const DayTileContentContainer = styled(Box, {
    shouldForwardProp: (props) => props !== 'isToday'
})(({isToday}) => ({
        color: isToday && Colors.white,
        fontWeight: isToday && 'bold',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        zIndex: 1000,
         '&:after': {
            content: '""',
            display: isToday ? 'block' : 'none',
            position: 'absolute',
            width: '40px',
            height: '40px',
            border: `1px solid ${Colors.primary}`,
            bottom: '20%',
            borderRadius: '50%',
            zIndex: 1
        }
}));