import { useRef } from "react";


export default function useAbortController() {
    const abortController = useRef(new AbortController());

    const abort = () => {
        abortController.current.abort();
    }

    return [abortController.current.signal, abort];
}