import { createContext, useContext, useReducer, useState } from "react";

export const UI_ACTION_TYPE = {
  LOADING: 'loading',
}

export const UIContext = createContext({});
export const useUIContext = () => useContext(UIContext);
const initialState = {
  loading: false,
  loadingtext: null
}

function reducer(state, action) {

  switch(action.type) {
    
    case UI_ACTION_TYPE.LOADING:
      return { ...state, loading: action.loading, loadingtext: action.loadingtext || 'Please wait...'};
    default:
      throw new Error();
  }
}

export const UIProvider = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [messageDrawerOpen, setMessageDrawerOpen] = useState(false);
  const [notificationDrawerOpen, setNotificationDrawerOpen] = useState(false);
  const [toast, setToast] = useState({open: false, message: ''});

  const [state, dispatch ] = useReducer(reducer, initialState);

  const value = {
    toast,
    setToast,
    drawerOpen,
    setDrawerOpen,
    messageDrawerOpen,
    setMessageDrawerOpen,
    notificationDrawerOpen,
    setNotificationDrawerOpen,
    state,
    dispatch
  };

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};
