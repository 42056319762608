import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import { TextField, Grid } from "@mui/material";
import { Colors } from "../../../styles/theme";

export default function AddressInfoCard({ formik }) {


    return (
        <Card elevation={1}>
            <CardHeader title="Address" sx={{ color: Colors.muted }} />
            <Divider />
            <CardContent>

                <Grid container columnSpacing={1} sx={{mt: 3}}>
                    <Grid item xs={12} md={2}>
                        <TextField
                            name="addrHouse"
                            value={formik.values.addrHouse}
                            onChange={formik.handleChange}
                            id="pr-addrHouse" label="House #" variant="standard" fullWidth />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <TextField
                            name="addrStreet"
                            value={formik.values.addrStreet}
                            onChange={formik.handleChange}
                            id="pr-addrStreet" label="Street" variant="standard" fullWidth />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TextField
                            name="addrUnit"
                            value={formik.values.addrUnit}
                            onChange={formik.handleChange}
                            id="pr-addrUnit" label="Unit #" variant="standard" fullWidth />
                    </Grid>
                </Grid>
                <Grid container columnSpacing={1} sx={{mt: 2}}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            name="addrCity"
                            value={formik.values.addrCity}
                            onChange={formik.handleChange}
                            id="pr-addrCity" label="City" variant="standard" fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            name="addrProvince"
                            value={formik.values.addrProvince}
                            onChange={formik.handleChange}
                            id="pr-addrProvince" label="Province" variant="standard" fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            name="addrPostal"
                            value={formik.values.addrPostal}
                            onChange={formik.handleChange}
                            id="pr-addrPostal" label="Postal Code" variant="standard" fullWidth />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}