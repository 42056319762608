import { differenceInMinutes } from "date-fns";
import { timeFromString } from "../../utils";

export function getRowPosition(rowStart, rowEnd) {


    // const h = +starttime.split(":")[0];
    // const m = +starttime.split(":")[1];
    const start = timeFromString(rowStart);
    const end = timeFromString(rowEnd);

    const totalMinutes = differenceInMinutes(end, start);
    //const totalMinutes = Math.abs(appSettings.businessStartHour - h) * 60 + m;

    return totalMinutes;
}