import cssConstants from './constants';
import theme from './theme';
import 'react-calendar/dist/Calendar.css';


const Styles = {
    ...cssConstants,
    theme: theme
}

export default Styles;