import { Button, capitalize, Box,Divider, Drawer, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import { DrawerHeader } from "../../styles/drawer"
import { Colors } from "../../styles/theme"
import { dateSetTime, niceDate, niceTime, timeFromString, toTitleCase } from "../../utils"
import { ChevronRightOutlined } from "@mui/icons-material";
import { drawerWidth } from "./styles"
import { useEffect, useReducer, useState } from "react"
import { useUIContext } from "../../state/ui"

import PatientSearch from "../patientsearch/patientSearch"
import { addMinutes } from "date-fns"
import { useAppContext } from "../../state/appState"

const validEncounter = [
    {
        id: 1,
        duration: 30,
        displayName: 'Contact lens evaluation'
    },
    {
        id: 2,
        duration: 45,
        displayName: 'Comprehensive exam'
    },
    {
        id: 3,
        duration: 15,
        displayName: 'Optical encounter'
    },
];

export default function BookAppointmentDrawer({ drawerOpen,
    handleDrawerClose,
    activeSlot,
    startTime,
    endTime,
    location,
    practitioner,
    patient,
    onBook }) {

    const [selectedPatient, setSelectedPaitient] = useState(null);
    const { dispatch } = useUIContext();
    const { appState } = useAppContext();
    // const [encounter, setEncounter] = useState('');
    // const [notes, setNotes] = useState('');
    // const [duration, setDuration] = useState(appState.timeSlotInterval);
    const [appointmentData, updateAppointmentData] = useReducer((prev, next) => {
        return { ...prev, ...next }
    }, {
        encounter: '', notes: '', duration: appState.timeSlotInterval
    });
    
    useEffect(() => {
        setSelectedPaitient(patient);
        // clear all fields
        updateAppointmentData({ notes: '', encounter: '' });
    }, [drawerOpen]);

    const handleBook = () => {
        const bookAppointmentParam = {
            customerId: selectedPatient.id,
            encounter: appointmentData.encounter,
            startTime: startTime,
            endTime: niceTime(addMinutes(timeFromString(startTime), appointmentData.duration).toISOString()),
            practitioner: practitioner.id,
            notes: appointmentData.notes
        }

        onBook(bookAppointmentParam);
    }

    const handlePatientSelect = (patient) => {
        setSelectedPaitient(patient);
    }

    const handleNotesChange = (event) => {
        updateAppointmentData({ notes: event.target.value });
    }

    const handleEncounterSelect = (event) => {
        const encounterId = event.target.value;

        // adjust end time based on the encounter
        const selectedEncounter = validEncounter.find(ve => ve.id === encounterId);
        updateAppointmentData({ encounter: encounterId, duration: selectedEncounter.duration });
    }
    return (
        <Drawer
            ModalProps={{
                keepMounted: false
            }}
            PaperProps={{
                elevation: 8
            }}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant="persistent"
            anchor="right"
            open={drawerOpen}
        >
            <DrawerHeader sx={{
                display: 'flex',
                justifyContent: 'flex-start'
            }}>
                <IconButton
                    sx={{ color: Colors.white }}
                    onClick={handleDrawerClose}>
                    <ChevronRightOutlined />
                </IconButton>

                <Typography fontSize={"2rem"} sx={{ color: Colors.white }}>New Appointment</Typography>
            </DrawerHeader>
            <Box sx={{ p: 1, mt: 2 }}>
                {!patient && <PatientSearch onPatientSelected={handlePatientSelect} reset={drawerOpen} />}

                {selectedPatient && <Box sx={{ mt: 2 }}>
                    <Typography fontSize={'1.25rem'} fontWeight='bold'>{toTitleCase(selectedPatient.fullName)}</Typography>
                    <Typography>{selectedPatient.email}</Typography>
                    <Typography>{selectedPatient.phone1}</Typography>
                </Box>}
                <Divider sx={{ mt: 1, mb: 1 }} />
                <Box sx={{ mt: 2, mb: 2 }}>

                    <Typography fontSize={'1.25rem'} sx={{ mb: 2 }}>Appointment Details</Typography>
                    <FormControl sx={{ mb: 1 }} size="small" fullWidth>
                        <InputLabel id="encounter-select-label">Encounter</InputLabel>
                        <Select
                            labelId="encounter-select-label"
                            id="encounter-select"
                            value={appointmentData.encounter}
                            label="Encounter"
                            onChange={handleEncounterSelect}
                        >
                            {
                                validEncounter.map(ve => <MenuItem key={ve.id} value={ve.id}>{ve.displayName}</MenuItem>)
                            }

                        </Select>
                    </FormControl>

                    <Typography>Staff: {(practitioner && toTitleCase(practitioner.fullName)) || ''}</Typography>
                    <Typography >Date: {activeSlot && niceDate(dateSetTime(activeSlot.start_Date || activeSlot.startDate, startTime))}</Typography>
                    <Typography>Duration: {appointmentData.duration} min</Typography>
                    <Typography>Time: {startTime} - {startTime && niceTime(addMinutes(timeFromString(startTime), appointmentData.duration).toISOString())}</Typography>
                    <Typography>Location: {capitalize(location)}</Typography>
                </Box>
                <TextField multiline
                    value={appointmentData.notes}
                    onChange={handleNotesChange}
                    rows={5} fullWidth variant="standard" label="Notes" />
                {<Button disabled={!selectedPatient} sx={{ mt: 2, width: '100%' }} variant="contained" onClick={handleBook}>Book</Button>}
            </Box>
        </Drawer>
    )
}