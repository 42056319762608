import { Typography } from "@mui/material";
import {
  addDays,
  addWeeks,
  endOfWeek,
  getMonth,
  startOfWeek,
} from "date-fns";
import { useEffect, useState } from "react";
import { useCalendarContext } from "../../../state/calendar";
import { getMonthString } from "../../../utils";

export default function Period({ onWeekChanged, onDayChanged }) {
  const now = new Date();
  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  const { state } = useCalendarContext();

  useEffect(() => {
    if (state.activeView === "week") {
      //const start = startOfWeek(addWeeks(now, state.activeWeek));
      //const end = endOfWeek(addWeeks(now, state.activeWeek));
      const start = startOfWeek(state.activeDate);
      const end = endOfWeek(state.activeDate);
      const startMonth = getMonth(start);
      const endMonth = getMonth(end);

      onWeekChanged({
        start: start,
        end: end
      });

      setStart(`${getMonthString(startMonth)} ${start.getFullYear()}`);
      setEnd(`${getMonthString(endMonth)} ${end.getFullYear()}`);
    } else if (state.activeView === "day") {
      //const start = addDays(now, state.activeDay);
      const start = state.activeDate;
      setStart(`${getMonthString(getMonth(start))} ${start.getFullYear()}`);
      onDayChanged && onDayChanged(start);
    }

  }, [state]);

  return <Typography fontSize={'1.50rem'} variant="subtitle2">{
    {
      "week":`${start}${end === start ? "" : " - " + end}`,
      "day":`${start}`
    }[state.activeView]
  }</Typography>;
}
