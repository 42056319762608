import { Typography } from "@mui/material";
import { Colors } from "../../../styles/theme";


export const DayLabel = ({ label,checked }) => {
    return (
        <Typography 
        sx={{ 
            textTransform: "capitalize", 
            width: "100%", 
            color: checked ? Colors.primary : Colors.muted, 
            fontWeight: checked ? 'bold' : 'normal', 
            fontSize: '1.25rem' }}>{`${label}`} </Typography>
    );
}