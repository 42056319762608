import { useRef, useState } from "react";


/**
 * These events are fired by calendar whenever view changes and anyone interested in them 
 * can just register and get notified.
 * E.g. week view should show date period as Month Year - Month Year
 * but Day view should only show Month Year
 * and Month view should only show year
 * 
 * @returns 
 */
export function useActiveWeek() {
    const [activeWeek, setActiveWeek] = useState(0);

    return [ activeWeek, setActiveWeek ];
}