import { Box, TableContainer, Table, TableBody, TableHead, alpha, TableRow, TableCell, TextField, Button, InputAdornment, IconButton, MenuItem, Select } from "@mui/material";
import { Colors } from "../../styles/theme";
import ClearIcon from '@mui/icons-material/Clear';
import { useReducer } from "react";
import ButtonWithMenu from "../common/buttonWithMenu";

export default function Order() {

    const [state, updateState] = useReducer((state, params) => {
        return { ...state, ...params }
    }, {
        orderNumber: '',
        advancedSearch: false
    });

    const handleAddGlassesOrder = () => {

    }
    const handleAddContactsOrder = () => {

    }
    const handleAddFrameOrder = () => {

    }

    return (
        <Box>
            <Box sx={{ mb: 2 }} display="flex" justifyContent={'space-between'}>
                {
                    state.advancedSearch ? <Box>
                        <TextField id="patient-firstname" label="First Name" variant="outlined" size="small" />
                        <TextField id="patient-lastname" label="Last Name" variant="outlined" size="small" />
                        <TextField id="orderdate" label="Order Date" variant="outlined" size="small" />
                        <Select value={1} size="small">
                            <MenuItem value={1}>Approved</MenuItem>
                            <MenuItem value={2}>Closed</MenuItem>
                        </Select>
                        <Button sx={{ ml: 2 }} variant="contained">Search</Button>
                        <Button sx={{ ml: 2 }} onClick={() => updateState({ advancedSearch: false })}>Close Advanced Search</Button>
                    </Box> : <Box>
                        <TextField id="order-number" label="Order #" variant="outlined" size="small"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton onClick={() => updateState({ orderNumber: '' })}>
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>,
                            }} />
                        <Button sx={{ ml: 2 }} variant="contained">Search</Button>
                        <Button sx={{ ml: 2 }} onClick={() => updateState({ advancedSearch: true })}>Advanced Search</Button>
                    </Box>
                }
                <Box>
                    <ButtonWithMenu
                        title={'Add'}
                        menuItems={[{
                            name: 'Glass Order',
                            handler: handleAddGlassesOrder
                        },
                        {
                            name: 'Contact Order',
                            handler: handleAddContactsOrder
                        },
                        {
                            name: 'Frame Only',
                            handler: handleAddFrameOrder
                        }
                        ]} />
                </Box>

            </Box>
            <TableContainer>
                <Table>
                    <TableHead sx={{ background: alpha(Colors.primary, 0.5) }}>
                        <TableRow>
                            <TableCell>Order #</TableCell>
                            <TableCell>Patient</TableCell>
                            <TableCell>Order Date</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Due Date</TableCell>
                            <TableCell>Date Notified</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}