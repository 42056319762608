import { Colors } from "../../../styles/theme";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton } from "@mui/material";

export const CopySchedule = ({disabled, handleCopySchedule }) => {

    return (
        <IconButton disabled={disabled} onClick={handleCopySchedule}>
            <ContentCopyIcon sx={{ color: disabled ? Colors.muted : Colors.primary}} />
        </IconButton>
        
    );
}