import { timeslots } from "../../../data";
import { CalendarContainer, CalendarHeader, DayColumnContainer, Timeslot, TimeslotsContainer, TimeslotsHolder } from "../../../styles/calendar";
import { ResourceEventsContainer } from "../../../styles/groupByResource";
import { Columns } from "../column";
import Header from "../header";

import { GroupByPractitionersHeader } from "./GroupByPractitionersHeader";
import GroupByPractitionersSlots from "./GroupByPractitionersSlots";

export default function GroupByPractitioners({ resources, forDate, onAvailableSlotClicked, onDrop, onEventClicked, collidesWith }) {

    return (

        <CalendarContainer>
            <CalendarHeader>
                <Header days={resources.data.length} resources={resources.data} render={data => (<GroupByPractitionersHeader resources={data.resources} />)} />
            </CalendarHeader>
            <TimeslotsContainer>
                <TimeslotsHolder>
                    {
                        timeslots.map((slot, index) => {
                            return (
                                <Timeslot key={index}>{slot}</Timeslot>
                            )
                        })
                    }
                </TimeslotsHolder>
            </TimeslotsContainer>

            <ResourceEventsContainer numberOfResources={resources.data.length}>
                <DayColumnContainer
                    columns={resources.data.length}>
                    <Columns
                        onDrop={onDrop}
                        forSetDate={forDate}
                        onSlotClicked={() => {}}
                        numberOfColumns={Array.from({ length: resources.data.length  }, (v, i) => i)} />
                </DayColumnContainer>
                <GroupByPractitionersSlots 
                collidesWith={collidesWith}
                slotData={resources} 
                onAvailableSlotClicked={onAvailableSlotClicked}
                onEventClicked={onEventClicked}/>
            </ResourceEventsContainer>


        </CalendarContainer>

    )
}