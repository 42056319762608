import { Badge, IconButton } from "@mui/material";
import { MessageOutlined } from "@mui/icons-material";
import MessagesDrawer from "./messagesdrawer";
import { useUIContext } from "../../../state/ui";

function UserMessages() {
  const { messageDrawerOpen, setMessageDrawerOpen } = useUIContext();
  return (
    <IconButton
      size="large"
      aria-label="messages for current user"
      sx={{
        color: "#fff",
      }}
      onClick={() => setMessageDrawerOpen(!messageDrawerOpen)}
    >
      <Badge badgeContent={4} color="warning">
        <MessageOutlined />
        <MessagesDrawer />
      </Badge>
    </IconButton>
  );
}

export default UserMessages;
