import request from "./httpService";



const bookAppointment = (data) => {
    return request({
        method: "POST",
        url: `/appointment/book`,
        data: data,
    });
};

const updateStatus = (data) => {
    return request({
        method: "POST",
        url: `/appointment/status/update`,
        data: data,
    });
};

const get = (param) => {
    return request({
        method: "GET",
        url: '/appointment/byparam',
        params: param
    });
}

const getByDay = (param) => {
    return request({
        method: "GET",
        url: '/appointment/byday',
        params: param
    });
}

const getById = (id) => {
    return request({
        method: "GET",
        url: `/appointment/${id}`,
    });
};

const getByPatientId = (id, signal) => {
    return request({
        method: "GET",
        url: `/appointment/patient/${id}`,
        signal: signal
    });
};

const update = (data) => {
    return request({
        method: "POST",
        url: `/appointment/update`,
        data: data,
    });
};

export const AppointmentService = {
    bookAppointment,
    get,
    getByDay,
    getById,
    getByPatientId,
    updateStatus,
    update,
};