import { Box } from "@mui/material";
import { GotoDate, NextPrev, TodayDate, ViewSelector } from "../calendar/actions";
import Period from "../calendar/period";
import ButtonWithMenu from "../common/buttonWithMenu";
import DayView from "./dayView";
import { CalendarContainer, CalenderHolder } from "./styles";
import Timebar from "./timebar";
import TimeIndicatorBar from "./timeIndicatorBar";
import WeekView from "./weekView";
import { SelectLocation } from "../common";
import PractitionerListMultiSelect from "./practitionerList";
import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import useMouseMovement from "../../hooks/useMouseMovement";
import { dateFromString, niceTime, timeFromString } from "../../utils";
import { addMinutes, format } from "date-fns";
import { createSnapModifier } from "@dnd-kit/modifiers";
import { DndContext, MouseSensor, useSensor, useSensors } from "@dnd-kit/core";
import { useAppContext } from "../../state/appState";
import validLookup from "../../utils/validLookup";
import useDialogModal from "../../hooks/useDialogModal";
import ConfirmationDialog from "../ui/confirmationDialog";

export default function Calendar({ open,
    handleWeekChanged,
    handleDayChanged,
    handleAddSchedule,
    handleEditSchedule,
    handleSlotClicked,
    slotDragged,
    location,
    setLocation,
    state,
    updateState,
    practitionerState,
    setPractitionerState }) {

    const { appState } = useAppContext();
    const calendarContainerRef = useRef(null);
    const timebarRef = useRef(null);
    //const [activeView, setActiveView] = useState('day');
    const [timebarTime, setTimebarTime] = useState();
    const [activateTimebar, setActivateTimebar] = useState(false);
    const [collides, setCollides] = useState(null);
    const [{ x, y }, setTimeBarCoordinates] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const mouseMove = useMouseMovement(window);

    /** DnD kit */
    /** 60 / 2 = 30 (grid size) */
    const [gridSize, setGridSize] = useState(30);
    const snapToGridModifier = useMemo(() => createSnapModifier(gridSize), [gridSize]);
    const mouseSensor = useSensor(MouseSensor, {
        activationConstraint: {
            distance: { y: 15 },
        }
    });
    const sensors = useSensors(mouseSensor);

    const [
        ConfirmationDialogComp,
        showConfirmationDialog,
        closeConfirmationDialog,
    ] = useDialogModal(ConfirmationDialog);

    useEffect(() => {
        const abortController = new AbortController();

        if (calendarContainerRef) {
            calendarContainerRef.current.addEventListener('mouseenter', (event) => {
                setActivateTimebar(true);
            }, { signal: abortController.signal });

            calendarContainerRef.current.addEventListener('mouseleave', (event) => {
                setActivateTimebar(false);
            }, { signal: abortController.signal });
        }

        return () => {
            abortController.abort();
        }

    }, [calendarContainerRef]);

    useEffect(() => {
        if (activateTimebar && !isDragging) {
            if (mouseMove && calendarContainerRef) {

                // the offset calendar container has from the top edge
                const { top, left } = calendarContainerRef.current.getBoundingClientRect();

                // this is to account for the practitioners slot height
                const offset = practitionerState.active.length > 0 ? 40 : 0;

                const transY = Math.ceil((mouseMove.y - (top + 75 + offset)) / gridSize) * gridSize;

                if (transY >= 0) {
                    setTimeBarCoordinates({ x: mouseMove.x - left, y: transY });

                    const d = timeFromString(appState.businessStartHour);
                    const time = addMinutes(d, transY / 2).toISOString();

                    setTimebarTime(niceTime(time));
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activateTimebar, mouseMove]);

    const updatePractitioner = (list) => {
        let pl = practitionerState.list;
        const ap = [];
        pl.forEach(p => {
            const idx = list.indexOf(p.fullName);
            if (idx > -1) {
                ap.push(p);
            }
        });
        setPractitionerState({ ...practitionerState, selectedPractitioners: list, active: ap, tick: new Date().getTime() });
        updateState({ lock: ap });
    };

    function handleDragStart(event) {
        const { active, delta } = event;
        //console.log("start: ", event);
        updateState({ draggedSlot: active });
        setIsDragging(true);
        setTimeBarCoordinates({ x: 0, y: active.data.current.top });
        setTimebarTime(active.data.current.start);
    }

    function handleDragEnd(event) {
        setIsDragging(false);
        updateState({ draggedSlot: null });

        const { over, active, delta } = event;
        const toPractitioner = over.data.current.practitionerid;
        const fromPractitioner = active.data.current.practitionerid;

        // the available slot this booked slot was dropped over
        if (over) {
            const activeSlots = practitionerState.active;

            const toIdx = activeSlots.findIndex(p => p.id === toPractitioner);
            const fromIdx = activeSlots.findIndex(p => p.id === fromPractitioner);

            if (toIdx !== -1 && fromIdx !== -1) {
                const srcslot = activeSlots[fromIdx].slots.filter(s => s.id === active.id).pop();
                const dstslot = activeSlots[toIdx].slots.filter(s => s.id === over.id).pop();

                let srcstart = dateFromString(srcslot.startDate, 'yyyy-MM-dd HH:mm:ss');
                const dstDate = dateFromString(dstslot.startDate, 'yyyy-MM-dd HH:mm:ss');
                srcstart.setFullYear(dstDate.getFullYear());
                srcstart.setMonth(dstDate.getMonth());
                srcstart.setDate(dstDate.getDate());

                const duration = srcslot.duration;
                const newStart = addMinutes(srcstart, delta.y / 2);
                const newEnd = addMinutes(newStart, duration);
                srcslot.startDate = format(newStart, 'yyyy-MM-dd HH:mm:ss');
                srcslot.endDate = format(newEnd, 'yyyy-MM-dd HH:mm:ss');

                srcslot.dayOfWeek = dstslot.dayOfWeek;

                if (dstslot) {
                    // add to destination
                    activeSlots[toIdx].slots.push(srcslot);

                    // remove from source
                    const toremove = activeSlots[fromIdx].slots.findIndex(s => s.id === active.id);
                    activeSlots[fromIdx].slots.splice(toremove, 1);

                    setPractitionerState({ ...practitionerState, active: activeSlots, tick: new Date().getTime() });

                }

                showConfirmationDialog(async (action) => {

                    if (action !== validLookup.confirmationDialogAction.ok) {
                        slotDragged(action, null);
                        //getAppointments();
                    } else {
                        const data = {
                            id: srcslot.id,
                            startDate: srcslot.startDate,
                            endDate: srcslot.endDate,
                            practitionerId: toPractitioner,
                            locationId: location.id,
                            dayOfWeek: srcslot.dayOfWeek
                        }
                        slotDragged(action, data);
                    }
                    closeConfirmationDialog();
                }, {
                    title: 'Move this appointmet?',
                    message: "Please confirm if you would like to move this appointment?",
                });
            }
        }

    }

    const handleDragMove = (event) => {
        const { active, delta } = event;
        setTimeBarCoordinates(({ x, y }) => { return { x: x, y: active.data.current.top + delta.y } });
        const time = addMinutes(timeFromString(active.data.current.start), delta.y / 2).toISOString();
        setTimebarTime(niceTime(time));
    }

    /**
     * called to cancel a drop.
     * in case a user drops on a break or another booked slot etc
     * @param {*} param0 
     * @returns 
     */
    const cancelDrop = async ({ active, over }) => {

        if (!over) {
            setIsDragging(false);
            updateState({ draggedSlot: null });
            return true;
        }

        return false;
    };

    const onSlotClicked = (slot) => {
        handleSlotClicked(slot, timebarTime);
    }
    return (

        <DndContext
            sensors={sensors}
            modifiers={[snapToGridModifier]}
            onDragMove={handleDragMove}
            cancelDrop={cancelDrop}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}>
            <CalenderHolder drawerOpen={open} >
                <Box display={"flex"}>
                    <SelectLocation setLocation={setLocation} location={location.id || ''} sx={{ m: 1, width: 300 }} />
                    <PractitionerListMultiSelect
                        updatePractitioner={updatePractitioner}
                        practitionerList={practitionerState.list}
                        selectedPractitioners={practitionerState.selectedPractitioners} />
                </Box>

                <Box display={"flex"} alignItems="baseline" sx={{ mt: 2 }}>
                    <NextPrev />
                    <Box sx={{ padding: "0px 8px" }}>
                        <Period onWeekChanged={handleWeekChanged} onDayChanged={handleDayChanged} />
                    </Box>
                    <Box display="flex" justifyContent="right" flexGrow={1} sx={{ mt: 2 }}>
                        <ViewSelector viewSelected={(newView) => updateState({ activeView: newView })} />
                        <TodayDate />
                        <GotoDate />
                        {
                            handleAddSchedule &&
                            <ButtonWithMenu
                                title={'Schedule'}
                                menuItems={[{
                                    name: 'Add Schedule',
                                    handler: handleAddSchedule
                                },
                                {
                                    name: 'Edit Schedule',
                                    handler: handleEditSchedule
                                }
                                ]} />
                        }

                    </Box>
                </Box>

                <CalendarContainer ref={calendarContainerRef}>
                    {activateTimebar && <TimeIndicatorBar coords={{ y: y }} timebarRef={timebarRef} timebarTime={timebarTime} />}

                    <Timebar />
                    {
                        {
                            'day': <DayView
                                collides={collides}
                                activeSlot={state.activeSlot}
                                draggedSlot={state.draggedSlot}
                                tick={practitionerState.tick}
                                activePractitioners={practitionerState.active} onSlotClicked={onSlotClicked} />,
                            'week': <WeekView
                                collides={collides}
                                activeSlot={state.activeSlot}
                                draggedSlot={state.draggedSlot}
                                tick={practitionerState.tick}
                                activePractitioners={practitionerState.active} onSlotClicked={onSlotClicked} />
                        }[state.activeView]

                    }
                </CalendarContainer>

            </CalenderHolder>
            <ConfirmationDialogComp
                label={{
                    positive: "Confirm",
                    negative: "Cancel",
                }} />
        </DndContext>
    )
}