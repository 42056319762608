import {
  capitalize,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  Box
} from "@mui/material";
import { ButtonWithIcon } from "../../ui";
import { Add } from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import { Colors } from "../../../styles/theme";
import useDialogModal from "../../../hooks/useDialogModal";
import SearchPatientDialog from "../dialog/searchPatient/searchPatientDialog";
import LinkFamilyMemberDialog from "../dialog/linkFamilyMember";
import LinkOffIcon from '@mui/icons-material/LinkOff';
import ConfirmationDialog from "../../ui/confirmationDialog";
import validLookup from "../../../utils/validLookup";
import { PatientService } from "../../../service/patientService";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

export default function FamilyMembers({
  linkTo,
  familyMembers,
  familyMemberClicked
}) {

  const [familyMembersList, setFamilyMembersList] = useState([]);

  const [SearchForPatientDialog, showSearchPatientDialog] =
    useDialogModal(SearchPatientDialog);
  const [
    ConfirmationDialogComp,
    showConfirmationDialog,
    closeConfirmationDialog,
  ] = useDialogModal(ConfirmationDialog);

  const [LinkFamilyMember, showLinkFamilyMemberDialog] =
    useDialogModal(LinkFamilyMemberDialog);

    useEffect(() => {
      setFamilyMembersList(familyMembers);
    },[familyMembers]);

  const linkFamilyMember = () => {
    showLinkFamilyMemberDialog();
    //showAddPatientDialog();
  };

  const handleLinkFamilyMemberDone = (result) => {
    setFamilyMembersList( fm => [...fm, result] );
  }

  const handleSearchDone = (newMember) => {
    console.log(newMember.id, newMember.familyId);
    //setFamilyMembers( fm => [...fm, newMember]);
  }

  const handleFamilyMemberClick = (familyMember) => {
    familyMemberClicked(familyMember);
  }

  const handleUnlinkFamilyMember = (fm) => {
    showConfirmationDialog(async (action) => {
      if (action === validLookup.confirmationDialogAction.ok) {
        const unlinkResult = await PatientService.unlinkFamilyMember(fm.id);
        const { code, data, message } = { ...unlinkResult };

        if (code === 0) {
          setFamilyMembersList( fm => fm.filter(f => f.id !== data.id));
          closeConfirmationDialog();
        } else {
          toast.info(message);
        }
      }
    });

  }

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems="center"
      sx={{
        height: "100%",
        background: Colors.dove_gray,
      }}
    >
      <Typography
        sx={{
          p: 1,
          fontSize: "1.25rem",
          color: Colors.white,
          background: Colors.dark,
          width: "100%",
          textAlign: "center",
        }}
      >
        Family members
      </Typography>
      <List>
        {familyMembersList.map((fm) => (
          <ListItem disablePadding key={fm.id}>
            <ListItemButton onClick={() => handleFamilyMemberClick(fm)}>
              <ListItemText
                primary={
                  <Typography sx={{ fontSize: "1.125rem" }}>
                    {capitalize(fm.fullName)}
                  </Typography>
                }
              />
            </ListItemButton>
            <Tooltip title="Remove from family">
              <IconButton sx={{ color: Colors.danger }} onClick={() => handleUnlinkFamilyMember(fm)}><LinkOffIcon /></IconButton>
            </Tooltip>
          </ListItem>
        ))}

        <Divider component="li" />
      </List>
      <ButtonWithIcon
        sx={{ mr: 1 }}
        icon={<Add />}
        title="Link family member"
        onClickHandler={() => linkFamilyMember()}
      />
      <SearchForPatientDialog onDone={handleSearchDone} />
      <LinkFamilyMember linkTo={linkTo} onDone={handleLinkFamilyMemberDone} />
      <ConfirmationDialogComp
        message={"Remove this person from family?"}
        label={{
          positive: "Yes",
          negative: "No",
        }}
      />
    </Box>
  );
}
