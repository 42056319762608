import {
  Typography,
} from "@mui/material";
import { useUIContext } from "../../../state/ui";

function BrandName() {
  const { drawerOpen, setDrawerOpen } = useUIContext();

  return (
    <>
      <Typography
        variant="h6"
        noWrap
        component="div"
        sx={{ flexGrow: 1, color: "#fff", opacity: drawerOpen && 0 }}
      >
        One Vision
      </Typography>
    </>
  );
}

export default BrandName;
