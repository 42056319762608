import { PropTypes } from "prop-types";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { DayTile, DayTileContentContainer } from "../../../styles/minicalendar";

export default function MiniCalColumn({ column, handleDayClicked,dayOfAppointment,year, disablePastDates }) {

  return (
    <Grid container item flexDirection={"column"} xs={1.6} rowGap={0.12}>
      {column.map((d) => {
        return (
          <Grid container item flexDirection={"column"} key={d.day}>
            <DayTile
              key={d.day}
              isSelected={
                dayOfAppointment &&
                (!d.isPast || !disablePastDates) &&
                (
                  +dayOfAppointment.day === +d.day &&
                  +dayOfAppointment.month - 1 === +d.month &&
                  +dayOfAppointment.year === year
                ).toString()
              }
              onClick={() => handleDayClicked(d)}
              isBeforeToday={disablePastDates && d.isPast}
              item
            >
              <DayTileContentContainer isToday={d.isToday}>
                <Typography
                  sx={{
                    fontSize: "1.125rem",
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {d.day}
                </Typography>
              </DayTileContentContainer>
            </DayTile>
          </Grid>
        );
      })}
    </Grid>
  );
}

MiniCalColumn.prototypes = {
  column: PropTypes.array,
};
