
import { alpha, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { Colors } from "../../../../styles/theme";

export default function GlassesRxTable({ formik }) {
    return (
        <form onSubmit={formik.handleSubmit}>
            <TableContainer>
                <Table size="small" >
                    <TableHead sx={{ background: alpha(Colors.primary, 0.5) }}>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Sphere</TableCell>
                            <TableCell>Cylinder</TableCell>
                            <TableCell>Axis</TableCell>
                            <TableCell>Add</TableCell>
                            <TableCell>D PD</TableCell>
                            <TableCell>N PD</TableCell>
                            <TableCell>Prism H</TableCell>
                            <TableCell>Prism V</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        <TableRow >
                            <TableCell>OD</TableCell>
                            <TableCell><TextField
                                name="odSphere"
                                id="odSphere"
                                onChange={formik.handleChange}
                                value={formik.values.odSphere}
                                size="small" /></TableCell>
                            <TableCell><TextField name="odCylinder"
                                id="odCylinder"
                                onChange={formik.handleChange}
                                value={formik.values.odCylinder}
                                size="small" /></TableCell>
                            <TableCell><TextField name="odAxis"
                                id="odAxis"
                                onChange={formik.handleChange}
                                value={formik.values.odAxis}
                                size="small" /></TableCell>
                            <TableCell><TextField name="odNearAdd"
                                id="odNearAdd"
                                onChange={formik.handleChange}
                                value={formik.values.odNearAdd}
                                size="small" /></TableCell>
                            <TableCell><TextField name="odDpd"
                                id="odDpd"
                                onChange={formik.handleChange}
                                value={formik.values.odDpd}
                                size="small" /></TableCell>
                            <TableCell><TextField name="odNpd"
                                id="odNpd"
                                onChange={formik.handleChange}
                                value={formik.values.odNpd}
                                size="small" /></TableCell>
                            <TableCell><TextField name="odHPrism"
                                id="odHPrism"
                                onChange={formik.handleChange}
                                value={formik.values.odHPrism}
                                size="small" /></TableCell>
                            <TableCell><TextField name="odVPrism"
                                id="odVPrism"
                                onChange={formik.handleChange}
                                value={formik.values.odVPrism}
                                size="small" /></TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell>OS</TableCell>
                            <TableCell><TextField
                                name="osSphere"
                                id="osSphere"
                                onChange={formik.handleChange}
                                value={formik.values.osSphere}
                                size="small" /></TableCell>
                            <TableCell><TextField
                                name="osCylinder"
                                id="osCylinder"
                                onChange={formik.handleChange}
                                value={formik.values.osCylinder}
                                size="small" /></TableCell>
                            <TableCell><TextField
                                name="osAxis"
                                id="osAxis"
                                onChange={formik.handleChange}
                                value={formik.values.osAxis}
                                size="small" /></TableCell>
                            <TableCell><TextField name="osNearAdd"
                                id="osNearAdd"
                                onChange={formik.handleChange}
                                value={formik.values.osNearAdd}
                                size="small" /></TableCell>
                            <TableCell><TextField name="odDpd"
                                id="odDpd"
                                onChange={formik.handleChange}
                                value={formik.values.osDpd}
                                size="small" /></TableCell>
                            <TableCell><TextField name="odNpd"
                                id="odNpd"
                                onChange={formik.handleChange}
                                value={formik.values.osNpd}
                                size="small" /></TableCell>
                            <TableCell><TextField name="osHPrism"
                                id="osHPrism"
                                onChange={formik.handleChange}
                                value={formik.values.osHPrism}
                                size="small" /></TableCell>
                            <TableCell><TextField name="osVPrism"
                                id="osVPrism"
                                onChange={formik.handleChange}
                                value={formik.values.osVPrism}
                                size="small" /></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </form>

    )
}