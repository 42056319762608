import { useEffect, useRef } from "react";


export default function useEffectOnUpdates(callback, deps = []) {
    const ref = useRef(false);

    useEffect(() => {

        if( !ref.current ) {
            ref.current = true;
            return;
        }

        callback()
    },deps);
}