import { Button, Box } from "@mui/material";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import useList from "../../hooks/useList";
import Calendar from "../calendar";
import useDialogModal from "../../hooks/useDialogModal";
import BookAppointmentDialog from "./dialog/book-appointment-dialog";
import { AppointmentService } from "../../service/appointmentService";
import { buildAddress, dateFromString, dateSetTime, DATE_FORMAT, formateDate, getDateString, getTimeString, timeDiff, toTitleCase } from "../../utils";
import { LocationService } from "../../service/locationService";
import { EmployeeService } from "../../service/employeeService";
import { FieldSet, FieldSetLabel } from "../../styles/ui";
import useEffectOnUpdates from "../../hooks/useEffectOnUpdates";
import { useUIContext } from "../../state/ui"
import { isLocalDev } from "../../utils/environment";
import { endOfWeek, format, startOfWeek } from "date-fns";
import AppointmentDetailsDialog from "./dialog/appointment-details-dialog";
import PractitionerList from "../schedule/practitionerList";
import LocationList from "../schedule/locationList";
import { ScheduleService } from "../../service/scheduleService";

export default function Appointments() {
    const [BookAppointmentDialogModal, showBookAppointmentDialog] =
        useDialogModal(BookAppointmentDialog);
    const [AppointmentDialog, showAddAppointmentDialog] =
        useDialogModal(AppointmentDetailsDialog);


    const [dialogForEvent, setDialogForEvent] = useState(null);
    const [bookedEvents, setBookedEvents] = useState([]);
    const [backgroundEvents, setBackgroundEvents] = useState([]);
    const [activeWeek, setActiveWeek] = useState();
    const [activeDay, setActiveDay] = useState();
    // day/week/month etc
    const [activeView, setActiveView] = useState('day');
    const { setToast } = useUIContext();
    const [eventDetails, setEventDetails] = useState({
        id: -1,
        practitioner: '',
        location: '',
        service: 'Eye exam',
        notes: '',
        status: '',
        duration: '',
        start: '',
        end: ''
    });
    const [practitioner, setPractitioner] = useState([]);
    const [practitionerList, setPractitionerList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [location, setLocation] = useState('');
    const [activePractitioners, setActivePractitioners] = useState([]);
    // const [LocationList, location, setLocation] = useList(
    //     LocationService.getLocations,
    //     {
    //         paramList: ['addrCity'],
    //         sx: { mr: 2 }
    //     }
    // );

    // const [PractitionerList, practitioner, setPractitioner] = useList(
    //     EmployeeService.getPractitioners,
    //     {
    //         paramList: ['firstName', 'lastName'],
    //     }
    // );

    useEffectOnUpdates(() => {

        /**
         * get events from server
         */


        if( location )
            getAppointments();
    }, [location, practitioner, activeWeek, activeDay])

    /**
     * Load practitioners and locations
     */
    useEffect(() => {
        (async () => {
            const practitioners = await EmployeeService.getPractitioners();
            const { code, data, message } = { ...practitioners };
            if (code === 0) {
                setPractitionerList(data);
            }

        })();
        (async () => {
            const locations = await LocationService.getLocations();
            const { code, data, message } = { ...locations };

            if (code === 0) {
                setLocationList(data);
            }

        })();
    }, []);
    // useEffectOnUpdates(() => {

    //     const idx = events.findIndex((e) => e.id === eventDetails.id);
    //     const updatedEvent = { ...events[idx], ...eventDetails };

    //     const evts = [...events];
    //     evts[idx] = updatedEvent;
    //     setEvents(evts);
    // }, [eventDetails]);

    const handleReset = () => {
        setPractitioner('');
        setLocation('');

        //setReset(true);
    }

    const handleEventAdd = (event) => {
        setBookedEvents((a) => [...a, event]);
    };

    const handleEventEdit = (event) => {
        // first find the event
        const idx = bookedEvents.findIndex((e) => e.id === event.id);
        const temp = [...bookedEvents];
        temp[idx] = event;
        setBookedEvents(temp);
    };

    const handleEventRemoved = (event) => {
        setBookedEvents((e) => bookedEvents.filter((e) => e.id !== event.data.id));
    };

    /**
     * called when in weekview
     * @param {*} week 
     */
    const handleWeekChanged = (week) => {
        setActiveWeek(week);
    };

    /**
     * called when in day view
     * @param {*} day 
     */
    const handleDayChanged = (day) => {
        setActiveDay(day);
    }

    const handleEventClicked = (event) => {
        // show event dialog
        const dt = dateFromString(event.date);
        const shadowEvent = {
            id: event.id,
            date: dt,
        };

        AppointmentService.getById(event.id).then(result => {
            if (result.data) {
                const data = result.data;
                const { Employee, Location } = data;
                setEventDetails({
                    ...eventDetails,
                    id: data.id,
                    location: buildAddress(Location),
                    practitioner: toTitleCase(`${Employee.firstName} ${Employee.lastName}`),
                    notes: data.notes,
                    status: data.status,
                    duration: data.duration,
                    start: getTimeString(data.startDate),
                    end: getTimeString(data.endDate),
                    dateofappointment: formateDate(data.startDate, DATE_FORMAT, 'yyyy-MM-dd'),
                    locationid: Location.id,
                    practitionerId: Employee.id
                });
                setDialogForEvent(shadowEvent);
                showAddAppointmentDialog();
            }
        }).catch(error => {
            if (isLocalDev) {
                console.log(error);
            }
        });
    }

    const handleOnUpdate = () => {
        // const params = {};

        // // filter by location
        // if (location) {
        //     params['location'] = location;
        // }
        // // first filter by practioer
        // if (practitioner) {
        //     params['practitioner'] = practitioner;
        // }

        // if( activeView === 'day' ) {
        //     params['start'] = activeDay;
        //     params['end'] = activeDay;
        // }

        getAppointments();
    }

    function getAppointments() {
        const params = {};
        // filter by location
        if (location) {
            params['location'] = location;
        }
        // first filter by practioer
        if (practitioner) {
            params['practitioner'] = practitioner;
        }

        params['start'] = format(activeWeek.start, 'yyyy-MM-dd');
        params['end'] = format(activeWeek.end, 'yyyy-MM-dd');

        if (activeView === 'day') {
            params['start'] = format(activeDay, 'yyyy-MM-dd');
            params['end'] = format(activeDay, 'yyyy-MM-dd');;
        }

        ScheduleService.getScheduleByLocation({ ...params })
            .then((result) => {
                console.log(params);
                const {code, data, message} = {...result};
                if (code === 0) {
                    const eventData = [];
                    const bgEventData = [];
                    const { booked, available } = { ...result.data };
                    console.log(data);
                    setActivePractitioners(data);

                    // booked.map(event => {
                    //     const date = getDateString(event.startDate);
                    //     const starttime = getTimeString(event.startDate);
                    //     const endtime = getTimeString(event.endDate);
                    //     const id = event.id;

                    //     eventData.push({
                    //         date: date,
                    //         starttime: starttime,
                    //         endtime: endtime,
                    //         id: id,
                    //         practitionerid: event.practitionerid,
                    //         locationid: event.locationid,
                    //         status: event.status
                    //     });
                    // });

                    // // available slots
                    // available.map(event => {
                    //     const date = getDateString(event.start);
                    //     const starttime = event.startTime;
                    //     const endtime = event.endTime;
                    //     const id = event.id;
                    //     bgEventData.push({
                    //         date: date,
                    //         starttime: starttime,
                    //         endtime: endtime,
                    //         id: id,
                    //         status: event.status,
                    //         dayofweek: event.dayOfWeek,
                    //     });
                    // });

                    // setBookedEvents(eventData);
                    // setBackgroundEvents(bgEventData);
                }

            })
            .catch((error) => console.log(error));
    }

    function getAppointments_() {
        const params = {};

        // filter by location
        if (location) {
            params['location'] = location;
        }
        // first filter by practioer
        if (practitioner) {
            params['practitioner'] = practitioner;
        }

        params['start'] = format(activeWeek.start, 'yyyy-MM-dd');
        params['end'] = format(activeWeek.end, 'yyyy-MM-dd');

        if (activeView === 'day') {
            params['start'] = format(activeDay, 'yyyy-MM-dd');
            params['end'] = format(activeDay, 'yyyy-MM-dd');;
        }

        AppointmentService.get({ ...params })
            .then((result) => {

                if (result.code === 0) {
                    const eventData = [];
                    const bgEventData = [];
                    const { booked, available } = { ...result.data };

                    booked.map(event => {
                        const date = getDateString(event.startDate);
                        const starttime = getTimeString(event.startDate);
                        const endtime = getTimeString(event.endDate);
                        const id = event.id;

                        eventData.push({
                            date: date,
                            starttime: starttime,
                            endtime: endtime,
                            id: id,
                            practitionerid: event.practitionerid,
                            locationid: event.locationid,
                            status: event.status
                        });
                    });

                    // available slots
                    available.map(event => {
                        const date = getDateString(event.start);
                        const starttime = event.startTime;
                        const endtime = event.endTime;
                        const id = event.id;
                        bgEventData.push({
                            date: date,
                            starttime: starttime,
                            endtime: endtime,
                            id: id,
                            status: event.status,
                            dayofweek: event.dayOfWeek,
                        });
                    });

                    setBookedEvents(eventData);
                    setBackgroundEvents(bgEventData);
                }

            })
            .catch((error) => console.log(error));
    }

    const handleViewChanged = (view) => {
        setActiveView(view);
    }

    const handleGoToDate = (date) => {

        if (activeView === 'day') {
            handleDayChanged(date);
        } else if (activeView === 'week') {
            const forWeek = {
                start: startOfWeek(new Date(date)),
                end: endOfWeek(new Date(date))
            }
            handleWeekChanged(forWeek);
        } else {
            throw new Error("Invaid onBook call");
        }
    }

    const handleOnBook = () => {
        getAppointments();
    }
    return (
        <>

            <FieldSet>
                <FieldSetLabel>Filter</FieldSetLabel>
                <Box display={"flex"}>
                    {/* <LocationList label={"Location"} /> */}
                    <LocationList 
                    locationList={locationList}
                    location={location} setLocation={setLocation}/>
                    <PractitionerList
                        practitionerList={practitionerList}
                        practitioner={practitioner} setPractitioner={setPractitioner} />
                </Box>

                {/*<PractitionerList sx={{ ml: 2 }} label={"Practitioner"} /> */}
                <Button onClick={handleReset}>Reset</Button>
            </FieldSet>
            <Box display={"flex"} justifyContent="flex-end">
                <Button
                    sx={{ mb: 2, mt: 2 }}
                    variant="contained"
                    onClick={showBookAppointmentDialog}
                    startIcon={<AddIcon />}
                >
                    Book Appointment
                </Button>
            </Box>

            <Calendar
                events={bookedEvents}
                backgroundEvents={backgroundEvents}
                onEventAdded={handleEventAdd}
                onEventRemoved={handleEventRemoved}
                onEventEdit={handleEventEdit}
                disableSlotClick={true}
                onWeekChanged={handleWeekChanged}
                onDayChanged={handleDayChanged}
                onEventClicked={handleEventClicked}
                onGotoDate={handleGoToDate}
                onViewChanged={handleViewChanged}
                activeView={activeView}
                activePractitioners={activePractitioners}
            />
            <BookAppointmentDialogModal onBooked={handleOnBook} />
            <AppointmentDialog
                onUpdated={handleOnUpdate}
                selectedEvent={eventDetails}
                setSelectedEvent={setEventDetails} />

        </>
    );


}
