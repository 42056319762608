

import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Box,
    Typography,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Divider,
    Slider
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Colors } from '../../../styles/theme';
import { useEffect, useRef, useState } from "react";
import { getTimeRange, getTimeStringNonIso, timeDiff, timeFromString } from "../../../utils";
import { appSettings } from "../../../data";
import useEffectOnUpdates from "../../../hooks/useEffectOnUpdates";
import { addMinutes } from "date-fns";
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

export const durationMarks = [
    {
        value: 15,
        label: "15 min",
    },
    {
        value: 30,
        label: "30 min",
    },
    {
        value: 45,
        label: "45 min",
    },
    {
        value: 60,
        label: "60 min",
    },
];

function PaperComponent(props) {
    const nodeRef = useRef(null);
    return (
      <Draggable
        nodeRef={nodeRef}
        handle="#choose-time-dialog-title"
      >
        <Paper id="choose-time-dialog-title" ref={nodeRef} {...props} />
      </Draggable>
    );
  }

export default function ChooseTimeDialog({ open,
    onClose,
    startAt,
    endAt,
    onTimeChange,
    params,
    onSave,
    onNotSet,
    onDelete,
    bookedSlots,
    availableStart,
    availableEnd,
    canSave,
    duration,
    hideDelete=false }) {

    // const [duration, setDuration] = useState(durationMarks.at(0).value);
    const [time, setTime] = useState({
        start: startAt,
        end: endAt
    });

    useEffectOnUpdates(() => {
        onTimeChange(time, params);
    }, [time]);

    useEffectOnUpdates(() => {
        let end = timeFromString(time.start);
        end = addMinutes(end, duration);
        setTime({ ...time, end: getTimeStringNonIso(end) });
    },[duration]);
    
    const handleStartTimeChange = (event) => {
        
        let end = timeFromString(event.target.value);
        end = addMinutes(end, duration);
        setTime({ start: event.target.value, end: getTimeStringNonIso(end) });
    }

    const handleEndTimeChange = (event) => {
        setTime({ ...time, end: event.target.value });
    }

    const handleSave = () => {
        onSave(time, params);
        onClose();
    }

    const handleClose = () => {
        //onDelete(params);
        onClose();
    }
    const handleDelete = () => {
        onDelete(params);
        onClose();
    }
    return (
        <Dialog onClose={onClose} open={open} fullWidth maxWidth={'xs'} PaperComponent={PaperComponent}>
            <DialogTitle sx={{ background: Colors.primary, mb: 4, color: Colors.white, cursor: 'move' }}
            aria-labelledby="choose-time-dialog-title">
                <Box display={"flex"} alignItems="center" justifyContent={'space-between'}>
                    Choose time
                    <IconButton onClick={handleClose} sx={{ color: Colors.white }}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ width: '100%', height: '100%' }} display="flex" flexDirection={"column"}>
                    <FormControl fullWidth={true} size="small" sx={{
                        minWidth: "200px",
                        mt: 2
                    }}>
                        <InputLabel id="starttime-select-label">Start</InputLabel>
                        <Select
                            labelId="starttime-select-label"
                            id="starttime-select"
                            value={time.start}
                            label="starttime"
                            onChange={handleStartTimeChange}
                        >
                            {getTimeRange(appSettings.businessStartHour,
                                appSettings.businessClosingHour,
                                duration,
                                availableStart,
                                availableEnd,
                                bookedSlots).map((t, i) => (
                                    <MenuItem key={i} value={t.time}>{t.display}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    {/* <FormControl fullWidth={true} size="small" sx={{
                        minWidth: "200px",
                        mt: 2
                    }}>
                        <InputLabel id="endtime-select-label">End</InputLabel>
                        <Select
                            labelId="endtime-select-label"
                            id="endtime-select"
                            value={time.end}
                            label="endtime"
                            onChange={handleEndTimeChange}
                        >
                            {getTimeRange(appSettings.businessStartHour, 
                            appSettings.businessClosingHour, 
                            appSettings.timeSlotInterval, 
                            availableStart, 
                            availableEnd, 
                            bookedSlots, true).map((t, i) => (
                                <MenuItem key={i} value={t}>{t}</MenuItem>
                            ))}
                        </Select>
                    </FormControl> */}
                    {duration && <Box sx={{mt: 2}}>
                        <Typography gutterBottom>Duration: {duration} mins</Typography>
                        {/* <Slider
                            onChange={(event) => setDuration(event.target.value)}
                            defaultValue={15}
                            valueLabelDisplay="auto"
                            step={null}
                            value={duration}
                            marks={durationMarks}
                        /> */}
                    </Box>}
                </Box>
                
            </DialogContent>

            <Divider />
            <DialogActions>
                
                {canSave && <Button onClick={handleSave} >Save</Button>}
                {!hideDelete && <Button onClick={handleDelete} color="danger">Delete</Button>}
            </DialogActions>
        </Dialog>
    );
}



