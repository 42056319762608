
export const ZIndex = {
  BELOW: 100,
  NORMAL: 200,
  TOP : 300,
  OVERLAY : 400,
  APPBAR: 1100,
  DRAWER: 1200,
  DIALOG: 1300,
  MAX : 999900,
  
}

export const LayoutWidth = {
  SMALL : 600,
  REGULAR : 1200,
}
